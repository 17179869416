import React, { Component, Fragment, ReactElement } from 'react';
import { Redirect } from 'react-router';
import Loader from '../telematicsPolicyManagment/Loader';
import AccessBanner from './AccessBanner';


export default class Logout extends Component {

    state = {
        redirectToBase: false        
    }

    constructor(props: any) {
        super(props);
        if (!sessionStorage.getItem("telematics_hot_reload")) {
            sessionStorage.setItem("telematics_hot_reload", "Pending")
            window.location.reload(true);
        }
        else {
            sessionStorage.removeItem("telematics_hot_reload");              
        }
    }

    componentDidMount(): void {
        setTimeout(this.timeoutCallBack, 10000);
    }

    timeoutCallBack = () => {
        this.setState({ redirectToBase: true });
    }

    render(): ReactElement {
        if (!this.state.redirectToBase) {
            return (<AccessBanner title="logged out" details="Successfully!" />);
        }
        else {
            return (<Redirect to="/" />);
        }
    }
}