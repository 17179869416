import React, { TableHTMLAttributes, FunctionComponent } from 'react'
import { StateData } from '../../models/StateHistory'

type props = TableHTMLAttributes<HTMLTableElement> & {
    title: string;
    stateData: StateData[];
    closeModal: () => any;
    containerClass?: string;
    tableClass?: string;
}

export const StateHistoryTable: FunctionComponent<props> = ({
    title,
    stateData,
    closeModal,
    containerClass,
    tableClass,
}) => {
    return (
        <div className={`modal modal--show display-block ${containerClass}`}>
            <div className="modal-container">
                <h2 className="modal-headline">{title}</h2>
                <button className="btn-close icon-close" type="button" aria-label="Close modal" onClick={() => closeModal()}></button>
                <div className="scroll-table">
                    <table className={`table table--non-sortable table--editable-non-sortable ${tableClass}`}>
                        <thead>
                            <tr>
                                <th className="text-align-left">Id</th>
                                <th className="text-align-left">Voluntary Status</th>
                                <th className="text-align-left">Effective Date</th>
                                <th className="text-align-left">Changes Made By Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stateData != null && stateData.map((data, i) => (
                                <tr key={i}>
                                    <td>{data.id}</td>
                                    <td>{data.voluntaryStatus}</td>
                                    <td>{data.effectiveDate}</td>
                                    <td>{data.changesMadeById}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}