import React, { Fragment, ReactElement } from 'react';
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import { PrimaryNavigation } from "../common/PrimaryNavigation";
import DanlawVehicle from '../../models/DanlawVehicle';

interface DashboardVehicleProps {
    danlawVehicle: DanlawVehicle;
    vehiclesMap: any;
    vehicles: DanlawVehicle[];
}

interface DashboardVehicleState {
    vehiclesMap: any;
}

export enum VehicleChipStyles {
    Success = 'success',
    Warning = 'warning',
    Alert = 'alert',
    Neutral = 'neutral',
}

export enum VehicleChipMessages {
    Success = 'Success',
    Moving = 'Moving',
    Idling = 'Idling',
    BeingTowed = 'Being Towed',
    Parked = 'Parked',
    NotConnected = 'Not Connected',
}

export enum VehicleChipStatus { 
    TripStart= 'Moving',
    InTrip= 'Moving',
    Idling= 'Idling',
    BeingTowed= 'BeingTowed',
    IgnitionOff= 'Parked',
    TripEnd= 'Parked',
    Disconnected= 'NotConnected',
}

export const VehicleChipStylesDict: {message: string, style: string}[] = [
    {message: 'Moving', style: 'success'},
    {message: 'Idling', style: 'warning'},
    {message: 'BeingTowed', style: 'alert'},
    {message: 'NotConnected', style: 'alert'},
    {message: 'Parked', style: 'neutral'},
];

export const VehicleChipMessagesDict: {status: string, message: string}[] = [
    {status: 'Success', message: 'Success'},
    {status: 'Moving', message: 'Moving'},
    {status: 'Idling', message: 'Idling'},
    {status: 'BeingTowed', message: 'Being Towed'},
    {status: 'Parked', message: 'Parked'},
    {status: 'NotConnected', message: 'Not Connected'},
];


export const VehicleChipStatusDict: {message: string, status: string}[] = [
    {message: 'TripStart',  status: 'Moving'},
    {message: 'InTrip',  status: 'Moving'},
    {message: 'Idling',  status: 'Idling'},
    {message: 'BeingTowed',  status: 'BeingTowed'},
    {message: 'IgnitionOff',  status: 'Parked'},
    {message: 'TripEnd',  status: 'Parked'},
    {message: 'Disconnect',  status: 'NotConnected'},
];

export class DashboardVehicle extends React.Component<DashboardVehicleProps, DashboardVehicleState> {
    constructor(props: DashboardVehicleProps) {
        super(props);
        this.onContainerClick = this.onContainerClick.bind(this);
        this.state = {
            vehiclesMap: this.props.vehiclesMap
        };
    }

    componentDidMount(): void {
        
    }

    onContainerClick() {
        const { vehiclesMap, vehicles } = this.props;

        let markers = vehiclesMap.getMarkers();
        markers.forEach((marker: any) => {
            if (marker._element.id == "svgIcon-" + this.props.danlawVehicle.id) {
                marker._element.children[0].style.transform = "scale(1.2)";
                marker._element.children[1].style.transform = "scale(1.2)";
                vehiclesMap.flyTo({center: marker._lngLat, curve: 0.5, duration: 1000});
                // Fire a click event on the marker to open the popup.
                document.getElementById("svgIcon-" + this.props.danlawVehicle.id)?.dispatchEvent(new MouseEvent("click"));
            }
        });
        let selectedVehicle = document.getElementById("vehicle-info" + this.props.danlawVehicle.id) as HTMLElement;
        selectedVehicle.style.background = "#F0F4FB";
        selectedVehicle.style.border = "1px solid #005CCC";

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let vehicle = document.getElementById("vehicle-info" + vehicleFromList.id) as HTMLElement;
            
            if (vehicle.id != selectedVehicle.id) {
                vehicle.style.removeProperty("background");
                vehicle.style.removeProperty("border");
            }
        });
    }
    
    render(): ReactElement {
        const { danlawVehicle } = this.props;
        
        let vehicleChipClass = "vehicle-chip-label chips chips-" + danlawVehicle.chipStatus;
        let vehicleLocationCityState = (danlawVehicle?.nearestAddress?.addressLine1 ? (danlawVehicle.nearestAddress.addressLine1 + ", ") : "No address found") +  (danlawVehicle?.nearestAddress?.city ? (danlawVehicle.nearestAddress.city + ", ") : "") + (danlawVehicle?.nearestAddress?.state ? danlawVehicle.nearestAddress.state : "") ;

        return (
            <>
                <div className="flexible-container vehicle-information-container" id={"vehicle-info" + danlawVehicle.id} onClick={this.onContainerClick}>
                    <div className={vehicleChipClass}>{danlawVehicle.chipMessage}</div>
                    <p className="text-left vehicle-info-vehicle"><b>{danlawVehicle.vehicleType}</b></p>
                    <p className="text-left vehicle-info-address"><span data-qm-encrypt="true">{vehicleLocationCityState}</span></p>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    popups: state.dshs.popups,
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators,
)(DashboardVehicle as any);