import React from 'react';
import Button, { ButtonStyles } from './Button';
import DanlawVehicle from '../../models/DanlawVehicle';
import { zoomOnVehicle } from '../customerDashboard/CustomerDashboard';

interface MapPopupProps {
    chipMessage: string;
    chipStatus: string;
    id: string;
    vehicle: DanlawVehicle;
    singleVehicleMap?: any;
    vehiclesMap?: any;
    vehicles: DanlawVehicle[];
    vehicleInformationOnClick: any;
}

export default ({chipMessage, chipStatus, id, vehicle, singleVehicleMap, vehiclesMap, vehicles, vehicleInformationOnClick}: MapPopupProps) => {
    let vehicleChipClass = "vehicle-chip-label chips chips-" + chipStatus;
    let vehicleLocationAddress = vehicle?.nearestAddress?.addressLine1 ?? "No address found";
    let vehicleLocationCityState = (vehicle?.nearestAddress?.city ? (vehicle.nearestAddress.city + ", ") : "") + (vehicle?.nearestAddress?.state ?? "") + " " + (vehicle?.nearestAddress?.zipCode ?? "");
    let lastConnectionTime = vehicle?.deviceInfo?.lastConnectionTime ? new Date(Date.parse(vehicle.deviceInfo.lastConnectionTime)).toLocaleString() : "No time found";
    return (
        <>
            <div className="flexible-container popup-container" id={id}>
                <div className={vehicleChipClass}>{chipMessage}</div>
                <h4 className="popup-vehicletype">{vehicle.vehicleType}</h4>
                <div className='popup-text-block'>
                    <p className="popup-text">Near:</p>
                    <p className="popup-text popup-vehicle-location-address"><b><span data-qm-encrypt="true">{vehicleLocationAddress}</span></b></p>
                    <p className="popup-text popup-vehicle-location-city-state"><b>{vehicleLocationCityState}</b></p>
                </div>
                <div className='popup-text-block'>
                    <p className="popup-text">As of:</p>
                    <p className="popup-text"><b>{lastConnectionTime}</b></p>
                </div>
                <div className='row'>
                    <Button title="Vehicle Details" btnStyle={ButtonStyles.RightGreenButton} onClick={() => vehicleInformationOnClick(vehicle, singleVehicleMap, vehicles)} extraClassNames='map-popup-button map-popup-button-details'/>
                    <Button title="Zoom In" btnStyle={ButtonStyles.RightGreenButton} onClick={() => zoomOnVehicle(vehicle, vehiclesMap)} extraClassNames='map-popup-button map-popup-button-zoom' id={"zoom-button" + vehicle.id}/>
                </ div>
            </div>
        </>
    )
}