import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import TelematicsApi from '../api/telematicsApi';
import { ActionWithPayload, API_ERROR, FINISHED_LOADING, IS_LOADING, KnownAction, DELETECMTDATA_SUCCESS, DELETECMTDATA_FAILURE } from './Actions';


export const actionCreators = {
    sendDeleteRequest: (removalDate: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.deleteCmtData(removalDate)
            .then(result => {
                if (result.message === "Successful run for job") {
                    dispatch({
                        type: DELETECMTDATA_SUCCESS,
                        payload: result,
                    });
                }
                else {
                    dispatch({
                        type: DELETECMTDATA_FAILURE,
                        payload: result,
                    });
                }
                dispatch({
                    type: FINISHED_LOADING
                });
            })
            .catch(err => {
                dispatch({
                    type: API_ERROR,
                    payload: "Error sending delete request. Please Try Again",
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
};

const unloadedState: any = {
    isLoading: false,
    error: null,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case DELETECMTDATA_SUCCESS:
                return {
                    ...state,
                    returnMessage: action.payload.message,
                    successfulRun: true,
                    error: null,
                    isLoading: false,
                };
            case DELETECMTDATA_FAILURE:
                return {
                    ...state,
                    returnMessage: "",
                    successfulRun: false,
                    error: action.payload.message,
                    isLoading: false,
                };
            case API_ERROR:
                return {
                    ...state,
                    returnMessage: "",
                    successfulRun: false,
                    error: action.payload.message,
                    isLoading: false
                };
            default:
                return state;
        }
    };
