import React, { ReactElement, PureComponent, ChangeEvent } from 'react';
import Input, { InputSize } from '../common/Input';
import ButtonSwitch from '../common/ButtonSwitch';
import Button, { ButtonStyles } from '../common/Button';
import { PolicyInfo, UpdatePolicyInfoRequest } from '../../models/PolicyInfo';
import InfoModal from '../common/InfoModal';
import { EnrollPolicyRequest } from '../../models/EnrollPolicyRequest';
import { excludeSpecialCharacters, formatPhoneNumber, getCurrentEnvironment } from '../../helpers/textHelpers';
import { PromptModal } from '../common/PromptModal';
import TwoColumnList from '../common/TwoColumnList';

interface DashcamPolicyInformationProps {
    error: string;
    policy: PolicyInfo;
    onSubmitPolicyUnenrollment: (userComments: string) => void;
    onSubmitPolicyReEnrollment: (enrollPolicyRequest: EnrollPolicyRequest) => void;
    updatePolicyInformation: (updatePolicyInfoRequest: UpdatePolicyInfoRequest) => void;
    userComments?: string;
}

interface DashcamPolicyInformationState {
    currentPolicy: PolicyInfo;
    isEnrolled: boolean;
    isSmsEnabled: boolean;
    policyChanged: boolean;
    validationErrors: {
        addressLine1: string,
        phoneNumber: string,
        city: string,
        state: string,
        zipCode: string,
        license: string,
    };
    showInfoModal: boolean,
    showErrorModal: boolean,
    showSubmitPromptModal: boolean,
    submitted: boolean,
    revertedChanges: boolean,
    cancelledChanges: boolean,
    formMessage: string,
    formMessageColor: string,
    userComments: string;
}

export default class DashcamPolicyInformation
    extends PureComponent<DashcamPolicyInformationProps, DashcamPolicyInformationState> {
    constructor(props: DashcamPolicyInformationProps) {
        super(props);
        this.state = {
            isEnrolled: this.props.policy.isEnrolled,
            isSmsEnabled: this.props.policy.isSmsEnabled,
            currentPolicy: this.props.policy,
            policyChanged: false,
            validationErrors: {} as any,
            showInfoModal: false,
            showErrorModal: false,
            showSubmitPromptModal: false,
            submitted: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: "",
        };
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentPolicy: {
                ...this.state.currentPolicy,
                [e.target.id]: excludeSpecialCharacters(e.target.value)
            },
            policyChanged: true
        })
    }

    updateDriverEnrollmentStatus = () => {
        const { policy } = this.props;
        const { isEnrolled } = this.state;
        const updatedDrivers = [];

        if (policy.drivers == undefined) {
            return;
        }

        for (var i = 0; i < policy.drivers?.length; i++) {
            var driver = policy.drivers[i];
            driver.isEnrolled = isEnrolled;
            updatedDrivers.push(driver);
        }

        return updatedDrivers;
    }

    updateVehicleEnrollmentStatus = () => {
        const { policy } = this.props;
        const { isEnrolled } = this.state;
        const updatedVehicles = [];

        if (policy.vehicles == undefined) {
            return;
        }

        for (var i = 0; i < policy.vehicles?.length; i++) {
            var vehicle = policy.vehicles[i];
            vehicle.isEnrolled = isEnrolled;
            updatedVehicles.push(vehicle);
        }

        return updatedVehicles;
    }

    updatePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const { currentPolicy } = this.state;

        this.setState({
            currentPolicy: {
                ...currentPolicy,
                phoneNumber: formatPhoneNumber(e.target.value, currentPolicy.phoneNumber)
            },
            policyChanged: true
        })
    }

    updateZipCode = (e: ChangeEvent<HTMLInputElement>) => {
        const { currentPolicy } = this.state;
        const numberRegex = RegExp(/[0-9]/);
        let input = e.target.value;

        if (!numberRegex.test(input[input.length - 1])) {
            input = input.slice(0, input.length - 1);
        }

        this.setState({
            currentPolicy: {
                ...currentPolicy,
                zipCode: input,
            },
            policyChanged: true
        })
    }

    updatePolicy = () => {
        const { updatePolicyInformation } = this.props;
        const { currentPolicy, userComments, isSmsEnabled } = this.state;

        if (!this.validatePolicy()) {
            return;
        }

        const updatePolicyInfoRequest: UpdatePolicyInfoRequest = {
            policyNumber: currentPolicy.policyNumber,
            phoneNumber: currentPolicy.phoneNumber,
            userComments: userComments,
            isSmsEnabled: isSmsEnabled,
            mailingAddress: {
                addressLine1: currentPolicy.addressLine1,
                addressLine2: currentPolicy.addressLine2,
                city: currentPolicy.city,
                state: currentPolicy.state,
                zipCode: currentPolicy.zipCode,
            }
        };
        updatePolicyInformation(updatePolicyInfoRequest);
        this.setState({ showErrorModal: true })
    }

    validatePolicy = () => {
        const { currentPolicy } = this.state;
        let isValid = true;
        let validationErrors = {} as any;

        if (currentPolicy.addressLine1 === null || currentPolicy.addressLine1.length === 0) {
            validationErrors.addressLine1 = "First name is required.";
            isValid = false;
        }

        if (currentPolicy.phoneNumber === null || currentPolicy.phoneNumber.length === 0) {
            validationErrors.phoneNumber = "Phone number is required.";
            isValid = false;
        }

        if (currentPolicy.city === null || currentPolicy.city.length === 0) {
            validationErrors.city = "City is required.";
            isValid = false;
        }

        if (currentPolicy.state === null || currentPolicy.state.length === 0) {
            validationErrors.state = "State is required.";
            isValid = false;
        }

        if (currentPolicy.zipCode === null || currentPolicy.zipCode.length === 0) {
            validationErrors.zipCode = "Zip code is required.";
            isValid = false;
        }

        this.setState({
            validationErrors
        })

        return isValid;
    }

    closeModal = (): void => {
        this.setState({ showInfoModal: false })
    }

    updateComments = (commentValue: string): void => {
        console.log(commentValue);
        this.setState({ userComments: commentValue }, () => this.submitChanges());
    }

    submitChanges = (): void => {
        const { policy, onSubmitPolicyUnenrollment, onSubmitPolicyReEnrollment } = this.props;
        const { currentPolicy, isEnrolled, policyChanged, userComments, isSmsEnabled } = this.state;

        if (policy.isEnrolled && !isEnrolled) {
            onSubmitPolicyUnenrollment(userComments);
        }

        if (!policy.isEnrolled && isEnrolled) {
            const today = new Date().toJSON().slice(0, 10);
            const enrollPolicyRequest: EnrollPolicyRequest = {
                companyName: currentPolicy.companyName,
                policyHolder: policy.policyHolder,
                policyNumber: currentPolicy.policyNumber,
                quoteNumber: currentPolicy.quoteNumber,
                isVoluntary: policy.isVoluntary,
                phoneNumber: currentPolicy.phoneNumber,
                email: policy.email,
                addressLine1: currentPolicy.addressLine1,
                addressLine2: currentPolicy.addressLine2,
                city: currentPolicy.city,
                state: currentPolicy.state,
                zipCode: currentPolicy.zipCode,
                isEnrolled: isEnrolled,
                isSmsEnabled: isSmsEnabled,
                enrollmentEffectiveDate: today,
                stateIssued: policy.stateIssued,
                lob: policy.lob,
                driverLicense: policy.license,
                ratedState: policy.ratedState,
                drivers: this.updateDriverEnrollmentStatus(),
                vehicles: this.updateVehicleEnrollmentStatus(),
                userComments: userComments,
            };

            onSubmitPolicyReEnrollment(enrollPolicyRequest);
        }

        if ((policy.isSmsEnabled && !isSmsEnabled) || (!policy.isSmsEnabled && isSmsEnabled) || policyChanged) {
            this.updatePolicy();
        }

        this.setState({
            policyChanged: false,
            isEnrolled: policy.isEnrolled,
            showErrorModal: true,
            showSubmitPromptModal: false,
            submitted: true,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    toggleTelematicsEnrollment = (): void => {
        const { isEnrolled } = this.state;

        this.setState({
            isEnrolled: !isEnrolled,
        });

        if (!isEnrolled) {
            this.setState({
                showInfoModal: true,
            });
        }
    };

    componentDidUpdate = (prevProps: any) => {
        const { policy } = this.props;
        if (prevProps.policy.isEnrolled !== policy.isEnrolled) {
            this.setState({
                isEnrolled: policy.isEnrolled,
            });
        }
    }

    render(): ReactElement {
        const {
            policy,
        } = this.props;

        const {
            currentPolicy,
            isEnrolled,
            isSmsEnabled,
            policyChanged,
            showInfoModal,
        } = this.state;

        const renderSubmitButton = (): ReactElement | null => {
            if ((policy.isEnrolled && !isEnrolled) || (!policy.isEnrolled && isEnrolled) ||
                (policy.isSmsEnabled && !isSmsEnabled) || (!policy.isSmsEnabled && isSmsEnabled) || policyChanged) {
                return <Button title="Submit" btnStyle={ButtonStyles.RightGreenButton} onClick={() => this.setState({ showSubmitPromptModal: true })} />;
            }
            return null;
        };

        const renderResetButton = (): ReactElement | null => {
            if ((policy.isEnrolled && !isEnrolled) || (!policy.isEnrolled && isEnrolled) ||
                (policy.isSmsEnabled && !isSmsEnabled) || (!policy.isSmsEnabled && isSmsEnabled) || policyChanged) {
                return (
                    <Button title="Reset"
                        btnStyle={ButtonStyles.RightBlueButton}
                        onClick={() => this.setState({
                            revertedChanges: true,
                            cancelledChanges: false,
                            submitted: false,
                            formMessageColor: "red",
                            formMessage: "Changes have been removed and not applied.",
                            isEnrolled: policy.isEnrolled,
                            isSmsEnabled: policy.isSmsEnabled,
                            currentPolicy: policy})}/>
                )}
            return null;
        };

        const closeErrorModal = (): void => {
            const { policy } = this.props;
            const { formMessage } = this.state;

            if (formMessage !== "Changes have not been committed due to a technical error.") {
                this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
            }

            this.setState({ showErrorModal: false, currentPolicy: policy })
        }

        const voluntaryStatus = policy.isVoluntary ? "Voluntary" : "Involuntary";

        const { showErrorModal, showSubmitPromptModal, cancelledChanges, submitted, revertedChanges, formMessageColor, formMessage } = this.state;
        const { error } = this.props;
        return (
            <div id="policyInformationContent" className="form-group row" style={{ justifyContent: 'flex-end' }}>
                {showInfoModal && !policy.isEnrolled &&
                    <InfoModal
                        title='Re-Enrolling in Telematics Notice'
                        infoText='By re-enrolling in DriveEasy Pro Dashcam, all vehicles and drivers found in the Telematics system will be re-enrolled in DriveEasy Pro Dashcam. You need to remove all drivers and vehicles that are in Telematics but not in Insurity and you need to add all drivers and vehicles that are in Insurity but not in Telematics. The driver and vehicles in Insurity and Telematics system should match.'
                        onClose={this.closeModal}
                    />
                }
                {showErrorModal && error &&
                    <InfoModal
                        title='Technical Error'
                        infoText={error}
                        onClose={closeErrorModal}
                    />
                }
                {showSubmitPromptModal &&
                    <PromptModal
                        title='Update Policy Information'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateComments}
                        no={() => this.setState({
                            showSubmitPromptModal: false,
                            cancelledChanges: true,
                            revertedChanges: false,
                            submitted: false,
                            formMessageColor: "red",
                            formMessage: "Changes have not been saved."
                        })}
                    />
                }
                <div className="container">
                    <div className='row'>
                        <div id="policyInformationLeftColumn" className="col-lg-6">
                            <TwoColumnList
                                id="listId"
                                firstColumn={["Company", "Program Type", "Voluntary Status"]}
                                secondColumn={[policy.companyName, policy.programType, voluntaryStatus]}
                            />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <Input
                                id="addressLine1"
                                label="Address Line 1"
                                value={currentPolicy.addressLine1 || ''}
                                name="AddressLine1TextBox"
                                size={InputSize.DoubleExtraLarge}
                                errorMessage={this.state.validationErrors.addressLine1}
                                onChange={this.onChange}
                            />
                            <Input
                                id="addressLine2"
                                label="Address Line 2"
                                value={currentPolicy.addressLine2 || ''}
                                name="AddressLine2TextBox"
                                size={InputSize.DoubleExtraLarge}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div id="policyInformationLeftColumn" className="col-lg-6">
                            <Input
                                id="phoneNumber"
                                label="Phone Number"
                                value={formatPhoneNumber(currentPolicy.phoneNumber, '') || ''}
                                name="PhoneNumberTextBox"
                                size={InputSize.DoubleExtraLarge}
                                maxLength={14}
                                errorMessage={this.state.validationErrors.phoneNumber}
                                onChange={this.updatePhoneNumber}
                            />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <Input
                                id="city"
                                label="City"
                                value={currentPolicy.city || ''}
                                name="CityTextBox"
                                dataQmAllow={true}
                                size={InputSize.DoubleExtraLarge}
                                errorMessage={this.state.validationErrors.city}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div id="policyInformationLeftColumn" className="col-lg-6">
                            <ButtonSwitch
                                    id="enrolled"
                                    isChecked={isEnrolled}
                                    onClick={() => this.toggleTelematicsEnrollment()}
                                    title="Enrolled in Telematics?"
                                />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <div className="col-lg-6">
                                <Input
                                    id="zipCode"
                                    label="Zip Code"
                                    value={currentPolicy.zipCode || ''}
                                    name="StateTextBox"
                                    size={InputSize.Medium}
                                    dataQmAllow={true}
                                    maxLength={10}
                                    errorMessage={this.state.validationErrors.zipCode}
                                    onChange={this.updateZipCode}
                                />
                            </div>
                            <div className="col-lg-6">
                                <Input
                                    id="state"
                                    label="State"
                                    value={currentPolicy.state || ''}
                                    name="StateTextBox"
                                    dataQmAllow={true}
                                    size={InputSize.Small}
                                    errorMessage={this.state.validationErrors.state}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {(revertedChanges || cancelledChanges || submitted) &&
                    <p className={formMessageColor}>{formMessage}</p>
                }
                <section className="col-lg-12">
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-3 mt-5">
                        {renderResetButton()}
                    </div>
                    <div className="col-lg-3 mt-5">
                        {renderSubmitButton()}
                    </div>
                </section>
            </div>
        );
    }
}