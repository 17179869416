export const setCustomerDashboardPanel = (activeTab : string) => {
    let dashboardContainer = document.getElementById("dashboard-container") || HTMLElement;
    let vehiclesContainer = document.getElementById("vehicles-container") || HTMLElement;
    let devicesContainer = document.getElementById("devices-container") || HTMLElement;
    let singleVehicleContainer = document.getElementById("single-vehicle-container") || HTMLElement;
    const panels = [dashboardContainer, vehiclesContainer, devicesContainer, singleVehicleContainer];
    
    let dashboardButton = document.getElementById("header-dashboard") || HTMLElement;
    let vehiclesButton = document.getElementById("header-vehicles") || HTMLElement;
    let devicesButton = document.getElementById("header-devices") || HTMLElement;
    const buttons = [dashboardButton, vehiclesButton, devicesButton];

    panels.forEach(panel => {
        let htmlPanel = panel as HTMLElement;
        if (htmlPanel.className) {
            if (htmlPanel.className.includes(activeTab + "-container")) {
                htmlPanel.style.display = "block";
            }
            else {
                htmlPanel.style.display = "none";
            }
        }
    });

    buttons.forEach(button => {
        let htmlButton = button as HTMLElement;

        /// Treat single vehicle tab as vehicles tab with the button.
        if (activeTab === "single-vehicle") {
            activeTab = "vehicles";
        }

        if (htmlButton.id) {
            if (htmlButton.id.includes("header-" + activeTab)) {
                htmlButton.style.background = "var(--Light-Mode-Light-Blue, #F0F4FB) !important";
                htmlButton.style.fontWeight = "700";
                htmlButton.blur();
            }
            else {
                htmlButton.style.removeProperty("background");
                htmlButton.style.removeProperty("font-weight");
            }
        }
    });

    window.scrollTo(0, 1);
}