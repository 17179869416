import React from 'react';

export interface AlertProps {
    title: string;
    message: string;
}

export default ({ title, message }: AlertProps) => (   
    
        <div className="alert">
            <div className="alert--retention">
                <span className="icon-tips"></span>
                <ul className="alert-list">
                <li className="alert-content">                   
                    <h3 className="font--bold">{title}</h3>
                    <p>{message}</p>                        
                    </li>
                </ul>
            </div>
        </div>    
);
