import React, { ChangeEvent } from "react";
import DanlawVehicle from "../../models/DanlawVehicle";
import { Vehicle } from "../../models/Vehicle";
import { GdkModal } from '@gdk/modal';
import { replace } from "connected-react-router";
import { ReplaceObdDeviceRequest } from "../../models/ReplaceObdDeviceRequest";
import { PolicyInfo } from "../../models/PolicyInfo";
import * as DashboardStore from "../../store/DashboardStore";
import { connect } from "react-redux";

interface DeviceReplacementModalProps {
    selectedVehicle: DanlawVehicle;
    onClose: () => void;
    policy: PolicyInfo;
    error: string;
}

interface DeviceReplacementModalStates {
    recipientName: string;
    addressLine1: string;
    addressLine2: string;
    cityStateZip: string;
    notes: string;
    saveAsShippingAddress: boolean;
    replacementParts: string[];
    replacementReason: string;
}

const replacementDictionary = [
    ["replacement-obd", "DL974 OBD2 Device"],
    ["replacement-9pin", "OBD to JBUS 9-pin Adapter"],
    ["replacement-6pin", "OBD to JBUS 6-pin Adapter"],
    ["replacement-splitter", "OBD Splitter Cable"],
    ["replacement-adapter", "Aux 12v Adapter"]
]

const reasonDictionary = [
    ["reason-damaged", "Defective or damaged device"],
    ["reason-incompatible", "Incompatible adapter"],
    ["reason-defective", "Defective or damaged adapter"],
    ["reason-other", "Other / general replacement"],
]

class DeviceReplacementModal extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            recipientName: "",
            addressLine1: "",
            addressLine2: "",
            cityStateZip: "",
            notes: "",
            saveAsShippingAddress: false,
            replacementParts: [],
            replacementReason: ""
        }
    }

    componentDidMount() {
        var modal = new GdkModal({
            "content" : "#replacement-modal",
            "autoShow" : false,
            "onOpened": function(){
                document.getElementById("modal-content")?.scrollIntoView();
            },
            "onClosed" : this.onModalOpen.bind(this)
        });

        var successfulModal = new GdkModal({
            "content" : "#replacement-success-modal",
            "autoShow" : false
        });

        var modalCancelButton = document.getElementById("replacement-cancel-modal-button");
        modalCancelButton?.addEventListener('click', function(){
            modal.hide();
        });

        var modalSubmitButton = document.getElementById("replacement-submit-modal-button");
        modalSubmitButton?.addEventListener('click', this.handleModalSubmit.bind(this, modal, successfulModal));
        var successfulModalSubmitButton = document.getElementById('replacement-success-close-modal-button');
        successfulModalSubmitButton?.addEventListener('click', this.closeSuccessfulModal.bind(this, successfulModal));

        var modalButtons = document.getElementsByClassName("open-replacement-modal");
        for (let i = 0; i < modalButtons.length; i++) {
            modalButtons[i].addEventListener('click', function(){
                modal.show();
            });
        }
    }

    onModalOpen() {
        let checkboxInputs = document.getElementsByClassName("checkboxInput");
        let textAreaInput = document.getElementById("replacement-notes") as HTMLTextAreaElement;
        let textInputs = document.querySelectorAll("input[type='text']") as NodeListOf<HTMLInputElement>;

        for (let i = 0; i < checkboxInputs.length; i++) {
            (checkboxInputs[i] as HTMLInputElement).checked = false;
        }
        textInputs.forEach((input: HTMLInputElement) => {
            input.value = "";
        });

        this.setState({
            recipientName: "",
            addressLine1: "",
            addressLine2: "",
            cityStateZip: "",
            notes: "",
            saveAsShippingAddress: false,
            replacementParts: [],
            replacementReason: ""
        });
        textAreaInput.value = "";

        let reasonLabels = document.querySelectorAll("label[id^='reasonLabel-']");
        reasonLabels.forEach((reasonLabel) => {
            reasonLabel.classList.remove("disabled");
        });
    }

    resetModalState() {
        this.setState({
            recipientName: "",
            addressLine1: "",
            addressLine2: "",
            cityStateZip: "",
            notes: "",
            saveAsShippingAddress: false,
            replacementParts: [],
            replacementReason: ""
        });
    }

    async handleModalSubmit(modal: GdkModal, successfulModal: GdkModal) {
        const { replaceObdDevice, policy, updatePolicyInformation, error } = this.props;

        if (this.checkIfFilledOut()) {
            let recipientName = document.getElementById("success-name") as HTMLParagraphElement;
            let address = document.getElementById("success-address") as HTMLParagraphElement;
            let cityStateZip = document.getElementById("success-city-state-zip") as HTMLParagraphElement;
            let parts = document.getElementById("success-parts") as HTMLParagraphElement;
            let reason = document.getElementById("success-reason") as HTMLParagraphElement;
            let shippingAddressUpdated = document.getElementById("replacement-success-address-update") as HTMLElement;
            let shippingAddressUpdatedConfirm = document.getElementById("replacement-success-address-update-confirm") as HTMLElement;
     
            let partsArray = this.state.replacementParts.map((part: string) => {
                return replacementDictionary.find(([key]) => key === part)?.[1] || "";
            });
            let partsString = partsArray.join("<br />");
    
            recipientName.textContent = this.state.recipientName;
            address.innerHTML = this.state.addressLine1 + "<br />" + this.state.addressLine2;
            cityStateZip.textContent = this.state.cityStateZip;
            parts.innerHTML = partsString;
            reason.textContent = reasonDictionary.find(([key]) => key === this.state.replacementReason)?.[1] || "";
    
            if (this.state.saveAsShippingAddress) {
                shippingAddressUpdated.setAttribute("style", "display: block");
                shippingAddressUpdatedConfirm.setAttribute("style", "display: block");
            }
            else {
                shippingAddressUpdated.setAttribute("style", "display: none");
                shippingAddressUpdatedConfirm.setAttribute("style", "display: none");
            }
            document.getElementById("please-fill-out")?.setAttribute("style", "display: none");
    
            modal.hide();
            let vehicle = policy?.vehicles?.find((vehicle: Vehicle) => vehicle.vin === this.props.selectedVehicle.vin);

            let replaceObdDeviceRequest: ReplaceObdDeviceRequest = {
                policyNumber: policy?.policyNumber,
                vehicles: [{
                    mailingAddress: {
                        firstName: (this.state.recipientName.split(" ")[0]).trim(),
                        lastName: (this.state.recipientName.split(" ")[1]).trim(),
                        addressLine1: (this.state.addressLine1).trim(),
                        addressLine2: (this.state.addressLine2).trim(),
                        city: (this.state.cityStateZip.split(",")[0]).trim(),
                        zip: (this.state.cityStateZip.split(",")[2]).trim(),
                        state: (this.state.cityStateZip.split(",")[1]).trim()
                    },
                    vehicleId: vehicle.id,
                    vin: this.props.selectedVehicle.vin,
                    isReturnRequired: true,
                    hardware: {
                        deviceProductCode: "DL974-22D3",
                        adapterProductCodes: [{
                            code: "G601-0021",
                            quantity: 1
                        }]
                }}]
            };

            if (this.state.saveAsShippingAddress) {
                let updatePolicyInfoRequest = {
                    policyNumber: policy?.policyNumber,
                    phoneNumber: policy?.phoneNumber,
                    mailingAddress: {
                        addressLine1: (this.state.addressLine1).trim(),
                        addressLine2: (this.state.addressLine2).trim(),
                        city: (this.state.cityStateZip.split(",")[0]).trim(),
                        zipCode: (this.state.cityStateZip.split(",")[2]).trim(),
                        state: (this.state.cityStateZip.split(",")[1]).trim()
                    },
                    isSmsEnabled: policy?.isSmsEnabled
                }
                updatePolicyInformation(updatePolicyInfoRequest);
            }
            await replaceObdDevice(replaceObdDeviceRequest);
            successfulModal.show();
        }
        else {
            document.getElementById("modal-content")?.scrollIntoView();
            document.getElementById("please-fill-out")?.setAttribute("style", "display: block");
        }
    }

    closeSuccessfulModal(successfulModal: GdkModal) {
        successfulModal.hide();
    }

    checkIfFilledOut() : boolean {
        const { recipientName, addressLine1, cityStateZip, replacementParts, replacementReason } = this.state;
        if (recipientName === "" || addressLine1 === "" || cityStateZip === "" || replacementParts.length === 0 || replacementReason === "") {
            return false;
        }
        return true;
    }

    handleReasonCheckboxClick(previousSibling: HTMLInputElement) {
        let reasonLabels = document.querySelectorAll("label[id^='reasonLabel-']");
        if (!previousSibling.checked) {
            reasonLabels.forEach((reasonLabel) => {
                reasonLabel.classList.remove("disabled");
            });
            this.setState({
                replacementReason: ""
            })
        }
        else {
            let reasonCheckboxes = document.querySelectorAll("input[id^='reason-']");
            reasonCheckboxes.forEach((reasonCheckbox) => {
                if (reasonCheckbox.id !== previousSibling.id) {
                    (reasonCheckbox as HTMLInputElement).checked = false;
                }
            });
            let splitId = previousSibling.id.split("-")[1];
            reasonLabels.forEach((reasonLabel) => {
                if (reasonLabel.id.split("-")[1] !== splitId) {
                    reasonLabel.classList.add("disabled");
                }
            });
            this.setState({
                replacementReason: previousSibling.id
            })
        }
    }

    handleReplacementCheckboxClick(previousSibling: HTMLInputElement) {
        const { replacementParts } = this.state;

        if (!previousSibling.checked) {
            this.setState({
                replacementParts: replacementParts.filter((part: string) => part !== previousSibling.id)
            })
        }
        else {
            replacementParts.push(previousSibling.id);
        }
    }

    onClickCheckbox = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        const previousSibling = target.previousSibling as HTMLInputElement;
        if (previousSibling) {
            if (!target.classList.contains("disabled")) {
                previousSibling.checked = !previousSibling.checked;
                if (previousSibling.id.startsWith("reason-")) {
                    this.handleReasonCheckboxClick(previousSibling);
                }
                else if (previousSibling.id === "replacement-primary-address") {
                    this.setState({
                        saveAsShippingAddress: previousSibling.checked
                    })
                }
                else if (previousSibling.id.startsWith("replacement-")) {
                    this.handleReplacementCheckboxClick(previousSibling);
                }
            }
        }
    }

    onModalFillOut = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case ("recipientName"):
                this.setState({
                    recipientName: e.target.value
                })
                break;
            case ("address1"):
                this.setState({
                    addressLine1: e.target.value
                })
                break;
            case ("address2"):
                this.setState({
                    addressLine2: e.target.value
                })
                break;
            case ("cityStateZip"):
                this.setState({
                    cityStateZip: e.target.value
                })
                break;
        }
    }

    onReplacementSubmit = () => {

    }

    render() {
        const { selectedVehicle, error } = this.props;
        return (
            <>
            <div id="replacement-modal" className="modal">
                <div className="modal-container replacement-modal">
                    <h2 className="modal-headline">Submit Device Replacement</h2>
                    <div className="modal-content">
                        <div id="modal-content">
                            <p className="text-bold modal-major-header" id="please-fill-out">Please fully fill out.</p>
                            <div className="form-field large-text-input">
                                <label className="text">Device ID</label>
                                <input type="text" size={30} data-qm-allow="true" placeholder={selectedVehicle?.deviceInfo?.deviceId} disabled={true}/>
                            </div>
                            <div className="form-field large-text-input">
                                <label className="text">Vehicle</label>
                                <input type="text" size={30} data-qm-allow="true" placeholder={selectedVehicle?.vehicleType} disabled={true}/>
                            </div>
                            <div className="form-field large-text-input">
                                <label className="text">Recipient Name</label>
                                <input type="text" size={30} id="recipientName" onChange={this.onModalFillOut}/>
                            </div>
                            <div className="form-field large-text-input">
                                <label className="text">Address Line 1</label>
                                <input type="text" size={30} id="address1" onChange={this.onModalFillOut}/>
                            </div>
                            <div className="form-field large-text-input">
                                <label className="text">Address Line 2</label>
                                <input type="text" size={30} id="address2" onChange={this.onModalFillOut}/>
                            </div>
                            <div className="form-field large-text-input">
                                <label className="text">City, State, Zip</label>
                                <input type="text" size={30} id="cityStateZip" data-qm-allow="true" onChange={this.onModalFillOut}/>
                            </div>
                            <form>
                                <fieldset className="form-field checkbox-group" role="radiogroup">
                                    <div className="checkbox-wrapper col-1">
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input type="checkbox" className="checkboxInput" id="replacement-primary-address" name="replacement-primary-address" value="Save as primary device shipping address"/>
                                            <label className="checkbox">Save as primary device shipping address</label>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <span className="stroke-separator"></span>
                            <form>
                                <fieldset className="form-field checkbox-group" role="checkbox">
                                    <legend>Send Replacement: </legend>
                                    <div className="checkbox-wrapper col-1">
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="replacement-obd" className="checkboxInput" type="checkbox" name="replacement-obd" value="DL974 OBD2 Device" />
                                            <label id="replacementLabel-obd" className="checkbox"><span>DL974 OBD2 Device</span></label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="replacement-9pin" className="checkboxInput" type="checkbox" name="replacement-9pin" value="OBD to JBUS 9-pin Adapter" />
                                            <label id="replacementLabel-9pin" className="checkbox">OBD to JBUS 9-pin Adapter</label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="replacement-6pin" className="checkboxInput" type="checkbox" name="replacement-6pin" value="OBD to JBUS 6-pin Adapter" />
                                            <label id="replacementLabel-6pin" className="checkbox">OBD to JBUS 6-pin Adapter</label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="replacement-splitter" className="checkboxInput" type="checkbox" name="replacement-splitter" value="OBD Splitter Cable" />
                                            <label id="replacementLabel-splitter" className="checkbox">OBD Splitter Cable</label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="replacement-adapter" className="checkboxInput" type="checkbox" name="replacement-adapter" value="Aux 12v Adapter" />
                                            <label id="replacementLabel-adapter" className="checkbox">Aux 12v Adapter</label>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <form>
                                <fieldset className="form-field checkbox-group" role="radiogroup">
                                    <legend>Reason: </legend>
                                    <div className="checkbox-wrapper col-1">
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="reason-damaged" className="checkboxInput" type="checkbox" name="reason-damaged" value="Defective or damaged device" />
                                            <label id="reasonLabel-damaged" className="checkbox"><span>Defective or damaged device</span></label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="reason-incompatible" className="checkboxInput" type="checkbox" name="reason-incompatible" value="Incompatible adapter" />
                                            <label id="reasonLabel-incompatible" className="checkbox">Incompatible adapter</label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="reason-defective" className="checkboxInput" type="checkbox" name="reason-defective" value="Defective or damaged adapter" />
                                            <label id="reasonLabel-defective" className="checkbox">Defective or damaged adapter</label>
                                        </div>
                                        <div onMouseDown={this.onClickCheckbox}>
                                            <input id="reason-other" className="checkboxInput" type="checkbox" name="reason-other" value="Other / general replacement" />
                                            <label id="reasonLabel-other" className="checkbox">Other / general replacement</label>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div className="form-field">
                                <label className="text">Notes:</label>
                                <textarea id="replacement-notes" data-qm-allow="true"></textarea>
                            </div>
                        </div>
                        <div className="modal--call-to-action-bar">
                            <button type="button" className="btn btn--primary btn--full-mobile btn--pull-right" id="replacement-submit-modal-button">
                                <span>Submit</span>
                            </button>
                            <button type="button" className="btn btn--destructive btn--full-mobile btn--pull-right" id="replacement-cancel-modal-button">
                                <span>Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="replacement-success-modal" className="modal">
                <div className="modal-container replacement-success-modal-content">
                    <h2 className="modal-headline">Submit Device Replacement</h2>
                    <div className="modal-content">
                        {!error && <div>
                            <div className="alert replacement-success-alert">
                                <div className="alert--green-affirmations">
                                    <span className="icon-confirmation"></span>
                                    <ul className="alert-list">
                                        <li className="alert-content">
                                            <div className="h4">A device replacement has been successfully submitted</div>
                                            <p>Advise the customer they’ll receive an email with a new delivery date when the replacement device ships.</p>
                                            <p id="replacement-success-address-update">The primary device shipping address has been updated. </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container replacement-success-info-container">
                                <div className="row replacement-success-info-row">
                                    <div className="flexible-container replacement-success-info-flex-container">
                                        <p className="text-bold modal-major-header">To</p>
                                        <p className="text-bold modal-minor-header">Recipient name</p>
                                        <span data-qm-encrypt="true"><p id="success-name"></p></span>
                                        <span className="stroke-separator"></span>
                                        <p className="text-bold modal-minor-header">Shipping Address</p>
                                        <span data-qm-encrypt="true"><p id="success-address"></p></span>
                                        <p id="success-city-state-zip"></p>
                                        <p id="replacement-success-address-update-confirm"><span className="icon-confirmation modal-address-update-confirmation"></span>Save as primary device shipping address</p>
                                    </div>
                                    <div className="flexible-container replacement-success-info-flex-container">
                                        <p className="text-bold modal-major-header">Details</p>
                                        <p className="text-bold modal-minor-header">Replacement Device and Equipment</p>
                                        <p id="success-parts"></p>
                                        <span className="stroke-separator"></span>
                                        <p className="text-bold modal-minor-header">Reason</p>
                                        <p id="success-reason"></p>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {error && <div>
                            <div className="alert replacement-success-alert">
                                <div className="alert--high-importance">
                                    <span className="icon-alert"></span>
                                    <ul className="alert-list">
                                        <li className="alert-content">
                                            <div className="h4">There was an error submitting your request. Please try again.</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>}
                        <div className="modal--call-to-action-bar">
                            <button type="button" className="btn btn--primary btn--full-mobile btn--pull-right" id="replacement-success-close-modal-button">
                                <span>Continue</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state: any) => ({
    error: state.dshs.error,
    selectedVehicle: state.dshs.selectedVehicle,
    selectedVehicleId: state.dshs.selectedVehicleId,
    policy: state.dshs.policy,
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators
)(DeviceReplacementModal);
