import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {
    API_ERROR,
    IS_LOADING,
    FINISHED_LOADING,
    ActionWithPayload,
    KnownAction,
    RESET_PROPS,
    BULK_POLICY_UPLOAD_SUCCESS,
} from './Actions';
import TelematicsApi from '../api/telematicsApi';

export const actionCreators = {
    resetProps: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: RESET_PROPS,
            payload: null
        });
    },
    policyUpload:
        (policyList: string[], userComments?: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
            dispatch({
                type: IS_LOADING,
                payload: true,
            });
            TelematicsApi.bulkPolicyUpload(policyList, userComments)
                .then(result => {
                    dispatch({
                        type: BULK_POLICY_UPLOAD_SUCCESS,
                        payload: result
                    })
                    dispatch({
                        type: FINISHED_LOADING
                    });
                })
                .catch(() => {
                    dispatch({
                        type: API_ERROR,
                        payload: "Unable to upload policies. Please try again later."
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                });
        }

};

const unloadedState: any = {
    isLoading: false,
    bulkPolicyUploadResponse: null,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case RESET_PROPS:
                return {
                    ...state,
                    error: action.payload
                }
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                };
            case BULK_POLICY_UPLOAD_SUCCESS:
                return {
                    ...state,
                    bulkPolicyUploadResponse: action.payload,
                    error: null
                };
            default:
                return state;
        }
    };