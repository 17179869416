import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {
    BATCH_JOB,
    API_ERROR,
    IS_LOADING,
    FINISHED_LOADING,
    ActionWithPayload,
    KnownAction,
    RESET_ERROR,
} from './Actions';
import TelematicsApi from '../api/telematicsApi';

const standardFollowUp = (promise: Promise<any>, dispatch: any, errorMessage: string) =>
    promise
        .then(() =>
            dispatch({ type: FINISHED_LOADING })
        )
        .then(() => {
            dispatch({
                type: API_ERROR,
                payload: null,
            });
        })
        .then(() => {
            dispatch({
                type: BATCH_JOB,
                payload: null
            })
        })
    .catch(() => {
        dispatch({
            type: API_ERROR,
            payload: errorMessage,
        });
        dispatch({
            type: FINISHED_LOADING,
        });
    });

export const actionCreators = {
    driverEnrollmentIncomplete: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startDriverEnrollmentIncompleteJob(), dispatch, "Error has occurred with the driver enrollment incomplete job");
    },
    vehicleDataNotReceived: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startVehicleDataNotReceivedJob(), dispatch, "Error has occurred with the vehicle data not received job");
    },
    driverHeartbeatNotReceived: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startDriverHeartbeatNotReceivedJob(), dispatch, "Error has occurred with the driver heartbeat not received job");
    },
    tagOnlyTrips: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startTagOnlyTripsJob(), dispatch, "Error has occurred with the tag only trips job");
    },
    vehicleEnrollmentIncomplete: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startVehicleEnrollmentIncomplete(), dispatch, "Error has occurred with the vehicle enrollment incomplete job");
    },
    vendorDriverSummary: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startVendorDriverSummaryJob(), dispatch, "Error has occurred with the vendor driver summary job");
    },
    vendorTagTripSummary: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({ type: IS_LOADING });
        standardFollowUp(TelematicsApi.startVendorTagTripSummaryJob(), dispatch, "Error has occurred with the vendor tag trip summary job");
    },
    resetError: ():
        AppThunkAction<ActionWithPayload> => (dispatch: any): void => {
            dispatch({
                type: RESET_ERROR,
                payload: null
            });
        },
};

const unloadedState: any = {
    isLoading: false,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                };
            case RESET_ERROR:
                return {
                    ...state,
                    error: null,
                };
            default:
                return state;
        }
    };