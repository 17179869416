import React, { ReactElement, ChangeEvent, PureComponent } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import * as UCMStore from '../../store/UCMStore';
import { connect } from 'react-redux';
import { excludeSpecialCharacters, getCurrentEnvironment } from '../../helpers/textHelpers';
import InfoModal from '../common/InfoModal';
import { StateHistoryTable } from '../common/StateHistoryTable';
import { PromptModal } from '../common/PromptModal';

const headers = ['State', 'Involuntary/Voluntary']

export class InvoluntaryTelematics extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ucmStatesChanged: [],
            isDirty: false,
            changesSubmitted: false,
            displayStateHistory: false,
            selectedState: null,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: '',
        };
    }

    componentDidMount() {
        this.props.getStatesVolInvol();
    }

    componentDidUpdate() {
        const { ucmStatesChanged } = this.state;
        const { states } = this.props;
        if (ucmStatesChanged.length == 0 && states) {
            this.setState({
                changesSubmitted: false,
                ucmStatesChanged: states.sort(this.stateSorter),
            })
        }
    }

    reset = () => {
        const { states } = this.props;
        if (states) {
            this.setState({
                ucmStatesChanged: states.sort(this.stateSorter),
                isDirty: false,
                changesSubmitted: false,
                revertedChanges: true,
                cancelledChanges: false,
                formMessageColor: "red",
                formMessage: "Changes have been removed and not applied.",
            })
        }
    }

    onChange = (state: string, e: ChangeEvent<HTMLSelectElement>) => {
        const { ucmStatesChanged } = this.state;
        const changedState = ucmStatesChanged.filter((s: any) => s.stateName == state)[0];
        const filteredStates = ucmStatesChanged.filter((s: any) => s.stateName != state);

        const newStates = [...filteredStates, {
            ...changedState,
            voluntaryStatus: excludeSpecialCharacters(e.target.value),
            isDirty: true
        }];

        newStates.sort(this.stateSorter);

        this.setState({
            ucmStatesChanged: newStates,
            isDirty: true,
            changesSubmitted: false
        });
    }

    stateSorter(first: any, second: any) {
        if (first.stateName > second.stateName) {
            return 1;
        }
        return -1;
    }

    isDirtyStyleObject(state: any): React.CSSProperties | undefined {
        if (state.isDirty) {
            return {
                fontWeight: state.isDirty ? "bold" : "normal",
                fontSize: "16px"
            }
        }
    }

    submitChanges = () => {
        const { ucmStatesChanged } = this.state;
        const { updateVoluntaryStates } = this.props;
        this.setState({
            changesSubmitted: true,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
        updateVoluntaryStates(ucmStatesChanged);
    }

    displayError = () => {
        const { changesSubmitted, displayStateHistory, formMessage, revertedChanges } = this.state;
        const { error } = this.props;

        if (error && formMessage !== "Changes have not been committed due to a technical error." && !revertedChanges && !changesSubmitted) {
            this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
        }

        if (error && (changesSubmitted || displayStateHistory)) {
            return (
                <InfoModal
                    title='Technical Error'
                    infoText={error}
                    onClose={this.closeErrorModal}
                />
            )
        }
    }

    closeErrorModal = (): void => {
        const { states } = this.props;
        if (states) {
            this.setState({
                ucmStatesChanged: states.sort(this.stateSorter),
                isDirty: false,
                changesSubmitted: false,
                displayStateHistory: false,
                selectedState: null
            })
        }
    }

    getStateHistory = (state: any) => {
        this.setState({ displayStateHistory: true, selectedState: state });
        this.props.getStateHistory(state);
    }

    displayStateHistory = () => {
        const { displayStateHistory, selectedState } = this.state;
        const { stateData } = this.props;
        if (displayStateHistory && selectedState && stateData) {
            return (
                <StateHistoryTable
                    title={"Telematics History of " + selectedState}
                    stateData={stateData}
                    closeModal={() => this.setState({ displayStateHistory: false, selectedState: null })}
                />
            )
        }
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue });
    }

    render(): ReactElement {
        const { error } = this.props;
        const { ucmStatesChanged, isDirty, changesSubmitted, showSubmitPromptModal, cancelledChanges, revertedChanges, formMessageColor, formMessage } = this.state;
        return (
            <>
                <div className="container margin-top">
                    {this.displayError()}
                    {this.displayStateHistory()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Update Policy Information'
                            infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                            yes={this.submitChanges}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                revertedChanges: false,
                                submitted: false,
                                formMessageColor: "red",
                                formMessage: "Changes have not been saved."
                            })}
                        />
                    }
                    <table className="table table--non-sortable table--editable-non-sortable">
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th key={header} style={{ textAlign: 'left' }}>{header}</th>
                                ))}
                                <th>
                                    <Button title="Reset" onClick={this.reset} btnStyle={ButtonStyles.BlueCenteredTableButton} />
                                </th>
                                <th>
                                    {isDirty && !changesSubmitted &&
                                        <Button title="Submit Changes" onClick={() => this.setState({ showSubmitPromptModal: true })} btnStyle={ButtonStyles.BlueCenteredTableButton} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ucmStatesChanged && ucmStatesChanged.map((state: any) => (
                                <tr key={state.stateCode}>
                                    <td style={this.isDirtyStyleObject(state)} data-title={state.stateName}>{state.stateName}</td>
                                    <td data-title='Involuntary'>
                                        <select
                                            id={state.stateName}
                                            value={state.voluntaryStatus}
                                            onChange={(e) => this.onChange(state.stateName, e)}
                                        >
                                            <option value="Involuntary">Involuntary</option>
                                            <option value="Voluntary"> Voluntary</option>
                                            <option value="Off">Off</option>
                                        </select>
                                    </td>
                                    <td></td>
                                    { false &&
                                        <td className="hyperlink cursor-pointer" onClick={() => this.getStateHistory(state.stateName)}>History</td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(revertedChanges || cancelledChanges || changesSubmitted || error) &&
                        <p className={formMessageColor}>{formMessage}</p>
                    }
                    {isDirty && !changesSubmitted &&
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button title="Submit Changes" onClick={() => this.setState({ showSubmitPromptModal: true })} btnStyle={ButtonStyles.RightBlueButton} />
                        </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    states: state.ucms.ucmStates,
    stateData: state.ucms.stateHistory,
    error: state.ucms.error
})

export default connect(mapStateToProps, UCMStore.actionCreators)(InvoluntaryTelematics);