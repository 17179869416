import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import TelematicsApi from '../api/telematicsApi';
import { ActionWithPayload, API_ERROR, FINISHED_LOADING, IS_LOADING, KnownAction, UCMSTATES_GET_STATE_HISTORY, UCMSTATES_RETRIEVAL_SUCCESS, UCMSTATES_UPDATE_VOLUNTARY_STATES } from './Actions';
import UCMStates from '../models/UCMState';
import { StateHistory } from '../models/StateHistory';

export const actionCreators = {
    getStatesVolInvol: (): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.getStatesVolInVol()
            .then(result => {
                dispatch({
                    type: UCMSTATES_RETRIEVAL_SUCCESS,
                    payload: result,
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            })
            .catch(err => {
                dispatch({
                    type: API_ERROR,
                    payload: "Error Retreaving (In)voluntry States Please Try Again",
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    getStateHistory: (state: string): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING,
        });
        dispatch({
            type: UCMSTATES_GET_STATE_HISTORY,
            payload: null,
        });
        TelematicsApi.getStateHistory(state)
            .then((result: StateHistory) => {
                dispatch({
                    type: UCMSTATES_GET_STATE_HISTORY,
                    payload: result,
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            })
            .catch(() => {
                dispatch({
                    type: API_ERROR,
                    payload: `Error getting ${state}'s state history`,
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    updateVoluntaryStates: (states: UCMStates): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.updateVoluntaryStates(states)
            .then(result => {
                dispatch({
                    type: UCMSTATES_UPDATE_VOLUNTARY_STATES,
                    payload: result,
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            })
            .catch(e => {
                dispatch({
                    type: API_ERROR,
                    payload: "Changes not saved. Please Try Again",
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
};

const unloadedState: any = {
    ucmStates: null,
    stateHistory: null,
    isLoading: false,
    error: null,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case UCMSTATES_UPDATE_VOLUNTARY_STATES:
                return {
                    ...state,
                    error: null,
                    isLoading: false,
                };
            case UCMSTATES_GET_STATE_HISTORY:
                return {
                    ...state,
                    stateHistory: action.payload?.stateHistory,
                    error: null,
                    isLoading: false,
                };
            case UCMSTATES_RETRIEVAL_SUCCESS:
                return {
                    ...state,
                    ucmStates: action.payload.states,
                    error: null,
                    isLoading: false,
                };
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false
                };
            default:
                return state;
        }
    };
