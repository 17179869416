import React, { PureComponent, ReactElement, Fragment, ChangeEvent } from 'react';
import { Driver } from '../../models/Driver';
import Input, { InputSize } from '../common/Input';
import Button, { ButtonStyles } from '../common/Button';
import { MailingAddress, PolicyInfo, UpdatePolicyInfoRequest } from '../../models/PolicyInfo';
import TwoColumnList from '../common/TwoColumnList';
import {GdkCardSelections} from '@gdk/card-selections'
import { Vehicle } from '../../models/Vehicle';
import PopUpModal from '../common/PopUpModal';
import { GdkButtonSwitch } from '@gdk/button-switch'; 

export interface DashcamPartsReplacementProps {
    error: string;
    policy: PolicyInfo;
    drivers: Driver[];
    updatePolicyInformation: (updatePolicyInfoRequest: UpdatePolicyInfoRequest) => void;
}

interface DashcamPartsReplacementState {
    checkedItems: ReplacementParts;
    showErrorModal: boolean;
    userComments: string;
    drivers: Driver[];
    submitted: boolean,
    mailingAddress: MailingAddress;
    selectedVehicle: Vehicle;
    updatePolicy: boolean;
    formMessage: string,
    formMessageColor: string,
    showSubmitPromptModal: boolean;
    showMailingAddressPromptModal: boolean;
}

interface ReplacementParts{
        air1Kit : boolean;
        air1CameraOnly : boolean;
        j1939PowerCord : boolean;
        obdPowerCord : boolean;
        obdToJbus6Adapter : boolean;
        flatMountPlate : boolean;
        wedgeMountPlate : boolean;
        sdCard : boolean;
}

export default class DashcamPartsReplacement extends PureComponent<
DashcamPartsReplacementProps, DashcamPartsReplacementState> {

    constructor(props: DashcamPartsReplacementProps) {
        super(props);
        this.state = {
            checkedItems: {
                air1Kit : false,
                air1CameraOnly : false,
                j1939PowerCord : false,
                obdPowerCord : false,
                obdToJbus6Adapter : false,
                flatMountPlate : false,
                wedgeMountPlate : false,
                sdCard : false,
            },
            showErrorModal: false,
            drivers: this.props.drivers || [],
            mailingAddress: {} as MailingAddress,
            selectedVehicle: {} as Vehicle,
            updatePolicy: false,
            userComments: '',
            submitted: false,
            formMessage: "",
            formMessageColor: "",
            showSubmitPromptModal: false,
            showMailingAddressPromptModal: false,
        };
    }

    componentDidMount() {
        var cardSelections = new GdkCardSelections({
            "content" : "#card-selections-component",
            "initialActiveCard" : 1,
            "inputType": "checkbox"
        });

        this.setState({
            mailingAddress: {
                addressLine1: this.props.policy.addressLine1 || '',
                addressLine2: this.props.policy.addressLine2 || '',
                city: this.props.policy.city || '',
                zipCode: this.props.policy.zipCode || '',
                state: this.props.policy.state || ''
            }
        });
    }

    componentDidUpdate () {
        const { error } = this.props;

        if (error)
        {
            this.setState({
                formMessageColor: "red",
                formMessage: "Changes have not been committed due to a technical error."
            })
        }
    } 

    onChangeButtonSwitch = (e: React.MouseEvent<HTMLInputElement>) => {
        let checkbox = document.getElementById("your-switch-button-id-button-switch-checkbox") as HTMLInputElement;
        let checkboxValue = checkbox.checked;
    }

    onAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case ("addressLine1"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        addressLine1: e.target.value
                    }
                })
                break;
            case ("addressLine2"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        addressLine2: e.target.value
                    }
                })
                break;
            case ("zipCode"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        zipCode: e.target.value
                    }
                })
                break;
            case ("state"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        state: e.target.value
                    }
                })
                break;
            case ("city"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        city: e.target.value
                    }
                })
                break;
        }
    }

    submitChanges = (): void => {
        const { updatePolicyInformation, policy } = this.props;
        const { mailingAddress, selectedVehicle, updatePolicy } = this.state;

        this.setState({
            showSubmitPromptModal: false,
            selectedVehicle: {
                ... selectedVehicle,
            }
        })

        if (updatePolicy) {
            const updatePolicyInfoRequest: UpdatePolicyInfoRequest = {
                policyNumber: policy.policyNumber,
                phoneNumber: policy.phoneNumber,
                isSmsEnabled: policy.isSmsEnabled,
                mailingAddress: {
                    addressLine1: mailingAddress.addressLine1,
                    addressLine2: mailingAddress.addressLine2,
                    city: mailingAddress.city,
                    state: mailingAddress.state,
                    zipCode: mailingAddress.zipCode,
                }
            };
            updatePolicyInformation(updatePolicyInfoRequest);
        }

        this.setState({
            submitted: true,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    submitMailingAddressChanges = (): void => {
        this.setState({ 
            showMailingAddressPromptModal: false,
        });
    }

    popupModalDidMount = (): void => {
        var buttonSwitch = new GdkButtonSwitch({
            content: "#updatePolicyAddressSwitch",
        });
    }

    popupSubmitModalDidMount = (): void => {
        var cardSelections = new GdkCardSelections({
            "content" : "#returnReasonSelection",
            "initialActiveCard" : 1,
            "inputType": "checkbox"
        });
    }

    editMailingAddressBody(): ReactElement {
        const { policy } = this.props;
        const { mailingAddress } = this.state;

        return (
            <>
                <div className="container">
                    <div className='row'>
                        <div id="policyInformationLeftColumn" className="col-lg-6">
                            <Input
                                id="policyHolder"
                                label="Company Name"
                                value={policy.companyName  || ''}
                                name="CompanyNameTextBox"
                                dataQmAllow={true}
                                size={InputSize.Large}
                                disabled={true}
                            />
                            <Input
                                id="addressLine1"
                                label="Address Line 1"
                                value={mailingAddress.addressLine1 || ''}
                                name="AddressLine1TextBox"
                                size={InputSize.Large}
                                onChange={this.onAddressChange}
                            />
                            <Input
                                id="addressLine2"
                                label="Address Line 2"
                                value={mailingAddress.addressLine2 || ''}
                                name="AddressLine2TextBox"
                                size={InputSize.Large}
                                onChange={this.onAddressChange}
                            />
                            <Input
                                id="city"
                                label="City"
                                value={mailingAddress.city || ''}
                                name="CityTextBox"
                                dataQmAllow={true}
                                size={InputSize.Large}
                                onChange={this.onAddressChange}
                            />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <Input
                                id="zipCode"
                                label="Zip Code"
                                value={mailingAddress.zipCode || ''}
                                name="ZipCodeTextBox"
                                dataQmAllow={true}
                                size={InputSize.Medium}
                                maxLength={10}
                                onChange={this.onAddressChange}
                            />
                            <Input
                                id="state"
                                label="State"
                                value={mailingAddress.state || ''}
                                name="StateTextBox"
                                dataQmAllow={true}
                                size={InputSize.Small}
                                onChange={this.onAddressChange}
                            />
                            <div id="updatePolicyAddressSwitch" className="button-switch-container" onClick={this.onChangeButtonSwitch}>
                                <div className="button-switch-label-wrapper">
                                    <label className="text">Update Policy Address?</label>
                                </div>
                                <input id="your-switch-button-id-button-switch-checkbox" name="updatePolicyAddressCheckbox" type="checkbox"/>
                                <div className="button-switch"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    submitBody(): ReactElement {
        debugger;
        return (
            <form>
                { (this.state.checkedItems.air1Kit || this.state.checkedItems.air1CameraOnly) && 
                        <div>
                        <div className="highlight-messaging">
                            <div>
                                <span className="geico-icon icon-info"></span>
                            </div>
                            <div>
                                <div className="highlight-messaging--header">Please advise the customer that they will be emailed instructions to return their current camera.
                                <br/></div>
                            </div>
                        </div>
                    </div>
                }
                <div id="returnReasonSelection" className="card-selection-wrapper">
                    <div className="card-selections three-column" aria-labelledby="card-selections-label-id">
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections1" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Defective or damaged camera</h5>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections2" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Defective or damaged power cord</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections3" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Defective or damaged adapter</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections4" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Defective or damaged SD card</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections5" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Defective or damaged mount plate</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections6" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Incompatible power cord</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections7" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Incompatible adapter</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections8" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Incompatible mount plate</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections9" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h5>Other / General Replacement</h5>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>                        
                    </div>
                </div>
            </form>
        )
    }

    vehicleDropDown(): ReactElement {
        const { policy } = this.props;

        return (
            <form>
                <div className="form-field">
                    <div className="select-box">
                        <select id="vehicleSelect" name="vehicleSelect" defaultValue="Select Vehicle" onChange={this.onChangeSelectedVehicle} data-qm-allow="true">
                            <option disabled hidden>Select Vehicle</option>
                            {policy.vehicles?.map((vehicle) => this.vehicleDropDownRow(vehicle))}
                        </select>
                    </div>
                </div>
            </form>
        )
    }

    vehicleDropDownRow(vehicle: Vehicle): ReactElement {
        const ymm = `${vehicle.year.length === 5 ? vehicle.year.substring(1) : vehicle.year} ${vehicle.make} ${vehicle.model}`;
        const vin = "****" + vehicle.vin?.slice(-4);
        const optionValue = ymm + " - " + vin;

        return (
            <option>{optionValue}</option>
        )
    }

    onChangeSelectedVehicle = (e: ChangeEvent<HTMLSelectElement>) => {
        let vehicleData =  e.target.value.split(" - ");
        let vin = vehicleData[1].slice(-4);
        let ymm = vehicleData[0].split(" ");
        let vehicle = this.props.policy.vehicles?.find(
            vehicle => vehicle.vin?.endsWith(vin) && 
            vehicle.year == ymm[0] &&
            vehicle.make == ymm[1] &&
            vehicle.model == ymm[2]) 
            || {} as Vehicle;

        this.setState({
            selectedVehicle: vehicle
        });
    }

    onChangeReplacementParts = (e: React.MouseEvent<HTMLFormElement>) => {
        let selectedItems = document.getElementsByClassName("card-selections-selected");
        let removeChecksFromTheseItems: string[] = [];

        const selectedParts:(keyof ReplacementParts)[] = [];

        Array.from(selectedItems).forEach((part) => {
            selectedParts.push(part.id as keyof ReplacementParts);
        });

        this.setState((prevState) => {
            const updatedState:ReplacementParts = {
                air1Kit : false,
                air1CameraOnly : false,
                j1939PowerCord : false,
                obdPowerCord : false,
                obdToJbus6Adapter : false,
                flatMountPlate : false,
                wedgeMountPlate : false,
                sdCard : false,
            };

        selectedParts.forEach((name) => {updatedState[name] = true});

        if(updatedState.j1939PowerCord)
        {
            updatedState.obdPowerCord  = false;
            removeChecksFromTheseItems.push('obdPowerCord');
        }
        else if(updatedState.obdPowerCord)
        {
            updatedState.j1939PowerCord  = false;
            removeChecksFromTheseItems.push('j1939PowerCord');
        }
        if(updatedState.air1Kit)
        {
            updatedState.air1CameraOnly  = false;
            updatedState.j1939PowerCord  = false;
            updatedState.obdPowerCord  = false;
            updatedState.obdToJbus6Adapter   = false;
            updatedState.wedgeMountPlate = false;
            updatedState.flatMountPlate = false;
            updatedState.sdCard  = false;
            removeChecksFromTheseItems.push('air1CameraOnly', 'j1939PowerCord','obdPowerCord','obdToJbus6Adapter','wedgeMountPlate','flatMountPlate','sdCard');
        }
        
        Array.from(selectedItems).forEach(element => {
            Array.from(element.children).forEach((child) => {
                // check if we want to remove checkmark from item
                // and check if child element has checkmark
                if (removeChecksFromTheseItems.includes(element.id) && child.nextElementSibling?.classList.contains("icon-confirmation")) {
                    child.nextElementSibling?.classList.remove("icon-confirmation");
                    child.nextElementSibling?.classList.add("checkbox");
                    child.parentElement?.classList.remove("card-selections-selected");
                }
            })
        }); 

        return {checkedItems: updatedState};
        })
    }

    isSubmitButtonDisabled = (): boolean => {
        const { selectedVehicle, checkedItems } = this.state;
        const selectedReplacement = Object.values(checkedItems).some((part) => part === true);
        return selectedVehicle.year && selectedReplacement ? false : true;
    }

    render(): ReactElement {
        const { policy } = this.props;
        const { checkedItems, showSubmitPromptModal, showMailingAddressPromptModal, mailingAddress, submitted, formMessage, formMessageColor } = this.state;

        let addressMessage = mailingAddress.addressLine2 == null ? mailingAddress.addressLine1 : mailingAddress.addressLine1 + "\n" + mailingAddress.addressLine2;

        const replacementParts =[
            {label:'AIR 1 KIT (Camera, Power Cord, Mount , SD Card, Adaptor)', key:'air1Kit'},
            {label:'AIR 1 (Camera Only)', key:'air1CameraOnly'},
            {label:'J1939 Power Cord (9-pin)', key:'j1939PowerCord'},
            {label:'OBD Power Cord w/type C Connector 12v', key:'obdPowerCord'},
            {label:'OBD to Jbus 6 pin Adapter', key:'obdToJbus6Adapter'},
            {label:'AIR Windshield Mount Plate, Flat', key:'flatMountPlate'},
            {label:'AIR Windshield Mount Plate, Wedge', key:'wedgeMountPlate'},
            {label:'64GB SD CARD', key:'sdCard'},
        ];

        return (
            <Fragment>
                {showMailingAddressPromptModal &&
                    <PopUpModal
                        title='Update Mailing Address'
                        buttonText='Submit'
                        bodyContent={this.editMailingAddressBody()}
                        onClose={() => this.setState({ showMailingAddressPromptModal: false })}
                        onSubmit={() => this.setState({ showMailingAddressPromptModal: false })}
                        componentDidMount={this.popupModalDidMount}
                    />
                }
                {showSubmitPromptModal &&
                    <PopUpModal
                        title='Return Reason'
                        buttonText='Submit'
                        bodyContent={this.submitBody()}
                        onClose={() => this.setState({ showSubmitPromptModal: false })}
                        onSubmit={this.submitChanges}
                        componentDidMount={this.popupSubmitModalDidMount}
                    />
                }
                <div className="container">
                    <div className='row'>
                        <div style={{ whiteSpace: "pre-wrap" }} id="policyInformationLeftColumn" className="col-lg-5">
                        <TwoColumnList
                                firstColumn={["Recipient Name", "Address", "Vehicle"]}
                                secondColumn={[
                                    <span data-qm-encrypt="true">{policy.policyHolder}</span>,
                                    <span data-qm-encrypt="true">{addressMessage + "\n" + policy.city + ", " + policy.state + " " + policy.zipCode}</span>,
                                    this.vehicleDropDown()
                                ]}
                            />
                        <Button btnStyle={ButtonStyles.LeftBlueButton} title="Edit Mailing Address" onClick={() => this.setState({ showMailingAddressPromptModal: true })} />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-7">
                            <span></span>
                            <form onClick={(e) =>this.onChangeReplacementParts(e)}>
                                <div id="card-selections-component" className="card-selection-wrapper" >
                                    <h4>Send Replacement</h4>
                                    <div className="card-selections three-column" aria-labelledby="card-selections-label-id">
                                     {replacementParts.map((part) => {
                                            const isDisabled = checkedItems.air1Kit && ['air1CameraOnly','j1939PowerCord','obdPowerCord','obdToJbus6Adapter','wedgeMountPlate','flatMountPlate','sdCard'].includes(part.key) ||
                                                                (part.key === 'j1939PowerCord' && checkedItems.obdPowerCord) ||
                                                                (part.key === 'obdPowerCord' && checkedItems.j1939PowerCord);
                                            return(
                                            <div className="card-selections-card" id={part.key} style={{opacity:isDisabled?0.5:1,pointerEvents:isDisabled?'none':'auto',cursor:isDisabled?'not-allowed':'pointer'}}>                                                <input type="checkbox" name={part.key } style={{pointerEvents:isDisabled?'none':'auto'}}
                                                checked ={checkedItems[part.key as keyof ReplacementParts]}
                                                />
                                                <span></span>
                                                <div className="card-selections-content centered">
                                                    <h5>{part.label}</h5>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                            </form>
                            <Button btnStyle={ButtonStyles.LeftGreenButton} title="Submit" onClick={() => this.setState({ showSubmitPromptModal: true })} disabled={this.isSubmitButtonDisabled()} />
                        </div>
                    </div>
                </div>
                {submitted &&
                    <p className={formMessageColor}>{formMessage}</p>
                }
            </Fragment>
        );
    }
}
