import React from "react";
import DeviceNotification from "../../models/DeviceNotification";
import { GdkAlert } from "@gdk/alert";

interface DeviceNotificationsDisplayProps {
    divClassName: string;
    spanClassName: string;
    notificationsList: DeviceNotification[];
    id: string;
}

interface DeviceNotificationsDisplayState {
    divClassName: string;
    spanClassName: string;
    notificationsList: DeviceNotification[];
}

class DeviceNotificationsDisplay extends React.Component<DeviceNotificationsDisplayProps, DeviceNotificationsDisplayState> {

    constructor(props: DeviceNotificationsDisplayProps) {
        super(props);
        this.state = {
            divClassName: this.props.divClassName,
            spanClassName: this.props.spanClassName,
            notificationsList: this.props.notificationsList,
        };
    }

    componentDidMount() {
        var alert = new GdkAlert({
            content: "#" + this.props.id
          });  
    }

    render() {
        const divClassName = `alert devices-shipment-table ${this.state.divClassName}`;
        const spanClassName = this.state.spanClassName;
        const notificationsList = this.state.notificationsList;

        return (
            <div id={this.props.id} className={divClassName} >
                <div className={this.state.divClassName}>
                    <span className={spanClassName}></span>
                    {notificationsList && notificationsList.length > 0 && (
                    <ul className="alert-list" >
                        {notificationsList.map((notification: DeviceNotification, index: number) => (
                        <li key={index} className="alert-content">
                        {this.state.divClassName === "alert--green-affirmations" && (
                            <button aria-label="Close alert" className="icon-close"></button>
                        )}
                            <div className="h5">{notification.notificationTitle}</div>
                            <p>{notification.notificationText}</p>
                            <a href={notification.url}>{notification.urlDisplayText}</a>
                        </li>
                        ))}
                    </ul>
                    )}
                </div>
            </div>
        );
};
}

export default DeviceNotificationsDisplay;