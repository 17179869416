import '@gdk/accordion/dist/styles/gdk-accordion.css';
import React, { PureComponent, ReactElement } from 'react';
import { Vehicle } from '../../models/Vehicle';
import { GdkAccordion } from '@gdk/accordion';
import Clip from '../../models/Clip';
import Button, { ButtonStyles } from '../common/Button';

interface DashcamVehicleManagementProps {
    vehicles: Array<Vehicle>;
    headers: Array<string>;
}

interface DashcamVehicleManagementState {
    vehicles: Vehicle[];
}

export default class DashcamVehicleManagement extends PureComponent<DashcamVehicleManagementProps, DashcamVehicleManagementState> {
    constructor(props: DashcamVehicleManagementProps) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles,
        };
    }

    formatDate = (date: string): string => {
        return new Date(date).toLocaleString().split(',', 1)[0];
    }

    openLink = (url: string) => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: url,
          }).click()
    }

    renderClipRow = (clip: Clip): ReactElement => {
        return (
            <tr>
                <td data-title={clip.thumbnailUrl}><img src={clip.thumbnailUrl} height={100} width={100} onClick={() => this.openLink(clip.thumbnailUrl || '')}></img></td>
                <td data-title={clip.eventType}>{clip.eventType}</td>
                <td data-title={clip.mp4Url}><Button title={'View'} btnStyle={ButtonStyles.LeftGreenButton} onClick={() => this.openLink(clip.mp4Url || '')} /></td>
            </tr>);
    }

    renderAccordion = (vehicle: Vehicle): ReactElement => {
        const { headers } = this.props;

        const ymm = `${vehicle.year.length === 5 ? vehicle.year.substring(1) : vehicle.year} ${vehicle.make} ${vehicle.model}`;
        const successOrAlert = vehicle.isEnrolled ? 'chips chips-success' : 'chips chips-alert';
        const enrolled = vehicle.isEnrolled ? 'Enrolled' : 'Not Enrolled';
        const dashcamSuccessOrAlert = vehicle.isDashcamEligible ? 'chips chips-success' : 'chips chips-alert';        
        const isDashcamEligible = vehicle.isDashcamEligible ? "Dashcam Eligibile" : "Dashcam Ineligible";
        return (
            <ul id={"accordion" + vehicle.id} className="accordion">
                <li>
                    <div tabIndex={0} className="accordion-headline">
                        <div className="accordion-headline-content-wrapper">
                            <div className="accordion-left-content-wrapper">
                                <div className="heading h4">{ymm}</div>
                                <p><b>VIN: </b> {"****" + vehicle.vin?.slice(-6)}</p>
                                <div className={dashcamSuccessOrAlert}>{isDashcamEligible}</div>
                                <div className={successOrAlert}>{enrolled}</div>
                            </div>
                            <div className="accordion-right-content-wrapper">
                                <span><b>Camera ID: </b> {vehicle.cameraId}</span>
                                <p><b>Tracking ID: </b> {vehicle.shippingStatus?.trackingId}</p>
                                <p><b>Order Status: </b> {vehicle.shippingStatus?.orderStatus}</p>
                                <p><b>Latest Delivery Date: </b> {vehicle.shippingStatus?.latestDeliveryDate}</p>
                            </div>                            
                        </div>
                    </div>
                    <div className="accordion-content-container" >
                        <div className="accordion-content">
                            <div className='data-table'>
                                <table className="table">
                                    <thead>
                                        <tr className="management-table-header">
                                            {
                                                headers && headers.map((header) => (
                                                    <th key={header} className="header">
                                                        <a>{header}</a>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            vehicle.clips?.clips?.length > 0 ? 
                                            vehicle.clips.clips.slice(0, 10).map((clip) => (
                                                this.renderClipRow(clip)
                                            )) 
                                            : <tr><td>No clips to show.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        );
    }

    componentDidMount = () => {
        const { vehicles } = this.state;

        vehicles.forEach((vehicle) => {
            var accordion = new GdkAccordion({
                content: '#accordion' + vehicle.id
            });
        });
    }

    render(): ReactElement {
        const { vehicles } = this.state;

        return (
            <div>
                {
                    vehicles ?
                        vehicles.map((vehicle) => (
                            this.renderAccordion(vehicle)
                        )) : null
                }
            </div>
        );
    }
}
