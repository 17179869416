import React from "react";
import { ReactElement } from "react";
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import VehiclesTab from "./VehiclesTab";
import DevicesTab from "./DevicesTab";
import DashboardTab from "./DashboardTab";
import MapPopup from "../common/MapPopup";
import * as DashboardIcons from "./DashboardIcons";
import { VehicleChipMessages, VehicleChipMessagesDict, VehicleChipStyles } from "../common/DashboardVehicle";
import DanlawVehicle from "../../models/DanlawVehicle";
import DeviceShipment from "../../models/DeviceShipment";
import DeviceNotification from "../../models/DeviceNotification";
import { excludeSpecialCharacters } from "../../helpers/textHelpers";

interface CustomerDashboardProps {

}

interface CustomerDashboardState {
    searchPolicyNumber: string;
    selectedVehicle: DanlawVehicle;
    singleVehicleMap: any;
}

export function generateMapIconsWithId(vehicles: DanlawVehicle[], idValue: string): ReactElement[] {
    let icons: ReactElement[] = [];
    let index = 1;
    if (vehicles) {
        vehicles.forEach((vehicle: DanlawVehicle) => {
            let chipMessage = VehicleChipMessagesDict.find((chipMessage) => chipMessage.message === vehicle.chipMessage)?.status || "";
            icons.push(DashboardIcons.RelevantIcon(idValue + index, chipMessage));
            index++;
        });
    }
    return icons;
}

export function createMap(container: string, style?: string, center?: any, zoom?: number, dragPan = true, showZoomControls = true, interactive = true): any {
    const Radar = window.Radar;
    return Radar.ui.map({
        container: container,
        style: style || 'radar-default-v1',
        center: center,
        zoom: zoom,
        dragPan: dragPan,
        showZoomControls: showZoomControls,
        interactive: interactive
    });
}

export function createMapMarker(container: any, longAndLat: any, markerOptions?: any) {
    const Radar = window.Radar;
    return Radar.ui.marker(markerOptions)
        .setLngLat(longAndLat)
        .addTo(container);
}

export function zoomOnVehicle(vehicle: DanlawVehicle, vehiclesMap: any) {
    let selectedMarker: any = undefined;
    let markers = vehiclesMap.getMarkers();
    let zoomButton = document.getElementById("zoom-button" + vehicle.id) as HTMLElement;

    markers.forEach((marker: any) => {
        if (marker._element.id === ("svgIcon-" + vehicle.id)) {
            selectedMarker = marker;
        }
    });
    
    if (vehiclesMap.getZoom() >= 17) {
        vehiclesMap.fitToMarkers({speed: 2, duration: 2500});
    }
    else {
        vehiclesMap.flyTo({
            center: [selectedMarker._lngLat.lng, selectedMarker._lngLat.lat],
            zoom: 19,
            speed: 1,
            curve: 0.5,
            duration: 2500
        });
    }
}

export class CustomerDashboard extends React.Component<any, CustomerDashboardState> {
    constructor(props: CustomerDashboardProps) {
        super(props);
        this.state = {
            searchPolicyNumber: excludeSpecialCharacters(localStorage.getItem("policyNumber") ?? ""),
            selectedVehicle: {},
            singleVehicleMap: undefined,
        };
    }

    componentDidMount(): void {
        const { searchPolicyWithoutCproa } = this.props;
        const { searchPolicyNumber } = this.state;

        /// Set intial Dashboard button style
        let dashboardButton = document.getElementById("header-dashboard") as HTMLElement || HTMLElement;
        dashboardButton.style.fontWeight = "700";
        dashboardButton.style.background = "var(--Light-Mode-Light-Blue, #F0F4FB) !important";

        if (localStorage.getItem("policyNumber")) {
            localStorage.removeItem("policyNumber");
        }
        if (searchPolicyNumber) {
            searchPolicyWithoutCproa(searchPolicyNumber.trim(), 'Policy');
        }
    }

    generateRedAlertDeviceNotifications(): DeviceNotification[] {
        let notifications: DeviceNotification[] = [];
        //notifications.push({ alertType: "Red Alert", notificationTitle:"Vehicle Not Listed, Please Contact Us", notificationText: "The 2015 Ford Transit VIN ...123456 does not match the vehicles listed on the policy. To update the VIN, add a vehicle, or replace a vehicle, call us at (866) 509-9444." });
        return notifications;
    }

    generatGrayAlertDeviceNotifications(): DeviceNotification[] {
        let notifications: DeviceNotification[] = [];
        //notifications.push({ alertType: "Gray Alert",  notificationTitle:"Assign Vehicle to Finish Device Setup", notificationText: "To finish setup for the devices you recently installed, match them to the vehicle into which they were installed. Once matched, you’ll be able to track your vehicles' locations on the Vehicles page.",url:"#", urlDisplayText:"Assign Vehicle" });
        return notifications;
    }

    generateGreenAlertDeviceNotifications(): DeviceNotification[] {
        let notifications: DeviceNotification[] = [];
        //notifications.push({ alertType: "Green Confirmations",  notificationTitle:"Device Successfully Connected!", notificationText: "OBD ...4554 was successfully plugged into the 2012 Toyota Camry ...667332.\n You can now track your vehicle’s location and status on the Vehicles page.",url:"#", urlDisplayText:"View Vehicles Page" });
        return notifications;
    }

    generateInstallNoticeDeviceNotifications(): DeviceNotification[] {
        let notifications: DeviceNotification[] = [];
        //notifications.push({ alertType: "Install Notice",  notificationTitle:"Install Your Device to Start Tracking", notificationText: "Your devices have arrived and are ready for install.", url:"#", urlDisplayText: "View Install Guide" });
        return notifications;
    }

    render(): ReactElement {
        const { vehicles, shipments } = this.props;
        const { selectedVehicle } = this.state;
        let dashboardTabProps = {
            vehicles: vehicles,
        }
        let vehiclesTabProps = {
            vehicles: vehicles,
            selectedVehicle: selectedVehicle,
            popups: this.props.popups,
        }
        let devicesTabProps = {
            vehicles: vehicles,
            deviceShipments: shipments,
            selectedVehicle: selectedVehicle,
            redAlertDeviceNotifications: this.generateRedAlertDeviceNotifications(),
            grayAlertDeviceNotifications: this.generatGrayAlertDeviceNotifications(),
            greenAlertDeviceNotifications: this.generateGreenAlertDeviceNotifications(),
            installNoticeDeviceNotifications: this.generateInstallNoticeDeviceNotifications(),
            policy: this.props.policy
        }

        return (
            <>
                <DashboardTab {...dashboardTabProps} />
                <VehiclesTab {...vehiclesTabProps} />
                <DevicesTab {...devicesTabProps} />
            </>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    error: state.dshs.error,
    selectedVehicle: state.drivers.policy,
    isLoading: state.drivers.isLoading,
    loggedInUser: state.loggedInUser,
    vehicles: state.dshs.vehicles,
    shipments: state.dshs.shipments,
    policy: state.dshs.policy,
    popups: state.dshs.popups
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators,
)(CustomerDashboard as any);