import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import Input from '../common/Input';
import * as UnderWritingStore from '../../store/UnderWritingStore';
import { connect } from 'react-redux';
import MessageAlert from '../common/MessageAlert';
import { formatEffectiveDate, getCurrentEnvironment } from '../../helpers/textHelpers';

export class UnderWritingReport extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: '',
            oldDate: '',
            initiatedDownload: false,
        };
    }

    updateEffectiveDate = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            date: formatEffectiveDate(e.target.value, this.state.date)
        })
    }

    downloadCsv = () => {
        const { date } = this.state;
        const { downloadCsv } = this.props;

        if (date !== null && date.length > 0) {
            this.setState({
                initiatedDownload: true,
                oldDate: date
            })
            downloadCsv(date)
        }
    }

    underwritingSearchError = () => {
        const { oldDate, initiatedDownload } = this.state;
        const { error } = this.props;
        if (initiatedDownload && error) {
            window.scrollTo(0, 0);
            return (<MessageAlert
                title={"Technical Error"}
                message={error + " " + oldDate} />)
        }
    }

    render(): ReactElement {
        return (
            <div>
                <div className="container margin-top">
                    {this.underwritingSearchError()}
                    <p><i className="ml-4">Please enter in the date of the underwriting report that you would like to receive.</i></p>
                    <div className="col-sm-6">
                        <Input
                            id="date"
                            label="Date"
                            name="Date"
                            size={20}
                            maxLength={10}
                            placeHolder="MM/DD/YYYY"
                            value={this.state.date}
                            onChange={this.updateEffectiveDate}
                        />
                    </div>
                    <div className="col-sm-6 mt-5">
                        <Button
                            btnStyle={ButtonStyles.RightBlueButton}
                            onClick={this.downloadCsv}
                            title="Download CSV"
                        />
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = (state: any) => ({
    error: state.underWriting.error
})

export default connect(mapStateToProps, UnderWritingStore.actionCreators)(UnderWritingReport);