import React, { ChangeEvent } from "react";
import { ReactElement } from "react";
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import DashboardVehicle, { VehicleChipMessages, VehicleChipStyles } from "../common/DashboardVehicle";
import { createMap, createMapMarker, generateMapIconsWithId } from "./CustomerDashboard";
import { MapRecenterButton } from "./MapRecenterButton";
import DanlawVehicle from "../../models/DanlawVehicle";
import PopupModel from "../../models/PopupModel";
import MapPopup from "../common/MapPopup";
import '@gdk/base/dist/styles/gdk-base.css';
import { AllVehiclesButton } from "./AllVehiclesButton";
import Button, { ButtonStyles } from "../common/Button";
import { PolicyInfo } from "../../models/PolicyInfo";

interface VehiclesTabProps {
    vehicles: DanlawVehicle[];
    selectedVehicle: DanlawVehicle;
    policy: PolicyInfo;
    popups: ReactElement[];
}

interface VehiclesTabState {
    vehicles: DanlawVehicle[];
    singleVehicleMap: any;
    vehiclesMap: any;
    selectedVehicle: DanlawVehicle;
    popups: ReactElement[];
}

export class VehiclesTab extends React.Component<any, any> {
    interval!: NodeJS.Timeout;
    refreshLocationInterval!: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles,
            singleVehicleMap: undefined,
            vehiclesMap: undefined,
        };
    }

    componentDidMount(): void {
        const { vehicles } = this.props;

        this.Radar();

        const vehiclesMap = createMap('vehicles-map', '2111b9d1-66dd-4b4a-afac-30755940c153', undefined, 5);
        const singleVehicleMap = createMap('single-vehicle-map', '2111b9d1-66dd-4b4a-afac-30755940c153', undefined, 5);
        if (vehicles) {
            for (let i = 1; i <= vehicles.length; i++) {
                let popup = document.getElementById("popup" + i) as HTMLElement;
                let svgIcon = document.getElementById("svgIcon-" + i) as HTMLElement;
                let newMarker = createMapMarker(vehiclesMap, [vehicles[i-1].latLong?.longitude,vehicles[i-1].latLong?.latitude], { popup: {element: popup, offset:35}, element: svgIcon });
                newMarker.getElement().addEventListener('click', () => {
                    this.handleMapMarkerClick(vehiclesMap, newMarker, vehicles, i);
                });
            }
        }
        
        let markersList = vehiclesMap.getMarkers();
        vehiclesMap.fitToMarkers({duration: 0});
        vehiclesMap.center = this.findCenter(markersList);

        const recenterButton = new MapRecenterButton();
        vehiclesMap.addControl(recenterButton);

        this.setState({ vehiclesMap: vehiclesMap });

        let popup = document.getElementById("popupSingle") as HTMLElement;
        if (vehicles) {
            for (let i = 1; i <= vehicles.length; i++) {
                let svgIcon = document.getElementById("svgIconSingle-" + i) as HTMLElement;
                svgIcon.setAttribute("width", "13%");
                svgIcon.setAttribute("height", "13%");
                createMapMarker(singleVehicleMap, [vehicles[i-1].latLong?.longitude, vehicles[i-1].latLong?.latitude], { popup: {element: popup}, element: svgIcon });
            }
        }

        singleVehicleMap.fitToMarkers({duration: 0});

        const viewAllVehiclesButton = new AllVehiclesButton();
        singleVehicleMap.addControl(viewAllVehiclesButton, 'top-left');

        this.setState({ singleVehicleMap: singleVehicleMap });

        document.getElementById("vehicles-list-container")?.addEventListener('click', () => this.deselectVehicle(true));
        document.getElementById("vehicles-map-container")?.addEventListener('click', () => this.deselectVehicle(false));

        this.interval = setInterval(() => this.setRecenterVisibility(vehiclesMap), 1000);
        this.refreshLocationInterval = setInterval(() => this.refreshVehicleLocations(this.props.policy), 10000);
    }

    async handleMapMarkerClick(vehiclesMap: any, newMarker: any, vehicles: any, i: number) {
        let width = window.innerWidth;
        let scale = "scale(1.2)";
        if (width <= 480 ) { 
            vehiclesMap.flyTo({center: newMarker._lngLat, curve: 0.5, duration: 2000, zoom: 17});
            scale = "scale(1.7)";
        }
        else {
            vehiclesMap.flyTo({center: newMarker._lngLat, curve: 0.5, duration: 1000});
        }
        newMarker._element.children[0].style.transform = scale;
        newMarker._element.children[1].style.transform = scale;
        let selectedVehicle = document.getElementById("vehicle-info" + i) as HTMLElement;
        let vehiclesList = document.getElementById("vehicles-list-container") as HTMLElement;

        var listTop = vehiclesList.getBoundingClientRect().top;
        var listBottom = vehiclesList.getBoundingClientRect().bottom;

        var vehicleTop = selectedVehicle.getBoundingClientRect().top;
        var vehicleBottom = selectedVehicle.getBoundingClientRect().bottom;

        var scroll_by = 0;
        if(vehicleTop < listTop) {
            scroll_by = -(listTop - vehicleTop);
        }
        else if(vehicleBottom > listBottom) {
            scroll_by = vehicleBottom - listBottom + 30;
        }

        if(scroll_by != 0) {
            vehiclesList.scrollTo({top: vehiclesList.scrollTop + scroll_by, behavior: 'smooth'});
        }

        selectedVehicle.style.background = "#F0F4FB";
        selectedVehicle.style.border = "1px solid #005CCC";
        let allMarkers = vehiclesMap.getMarkers();
        allMarkers.forEach((marker: any) => {
            if (marker._element.id != newMarker._element.id) {
                marker._element.children[0].style.transform = "scale(1.0)";
                marker._element.children[1].style.transform = "scale(1.0)";
            }
        });

        let popupVehicleType = document.getElementById("mobile-popup-vehicle-type") as HTMLElement;
        let popupVehicleAddress = document.getElementById("mobile-popup-address") as HTMLElement;
        let popupVehicleChip = document.getElementById("mobile-popup-chip") as HTMLElement;
        let popupVehicleCityState = document.getElementById("mobile-popup-city-state") as HTMLElement;
        let popupVehicleDetailsButton = document.getElementById("mobile-popup-vehicle-details-button") as HTMLElement;
        let popupVehicleTime = document.getElementById("mobile-popup-time") as HTMLElement;
        let popupVehicleDate = document.getElementById("mobile-popup-date") as HTMLElement;

        for (let j = 1; j <= vehicles.length; j++) {
            let vehicle = document.getElementById("vehicle-info" + j) as HTMLElement;
            if (vehicle.id != "vehicle-info" + i) {
                vehicle.style.removeProperty("background");
                vehicle.style.removeProperty("border");
            }
            else {
                let vehicleLocationAddress = vehicles[j-1].nearestAddress?.addressLine1 ?? "No address found";
                let vehicleLocationCityState = (vehicles[j-1].nearestAddress?.city ? (vehicles[j-1].nearestAddress.city + ", ") : "") + (vehicles[j-1].nearestAddress?.state ?? "") + " " + (vehicles[j-1].nearestAddress?.zipCode ?? "");
                let timeAndDate = vehicles[j-1].deviceInfo?.lastConnectionTime?.split(", ") ?? ["", ""];

                popupVehicleType.textContent = vehicles[j-1].vehicleType;
                popupVehicleAddress.textContent = vehicleLocationAddress;
                popupVehicleCityState.textContent = vehicleLocationCityState;
                popupVehicleChip.textContent = vehicles[j-1].chipMessage;
                popupVehicleChip.className = "chips chips-" + vehicles[j-1].chipStatus;
                popupVehicleDetailsButton.onclick = () => this.props.vehicleInformationOnClick(vehicles[j-1], this.state.singleVehicleMap, vehicles);
                popupVehicleTime.textContent = timeAndDate[0];
                popupVehicleDate.textContent = timeAndDate[1];
            }
        }

        window.scrollTo(0, 1);
        
        if (width <= 480 ) { 
            let footer = document.getElementById("primary-footer") as HTMLElement;
            let mobilePopupRow = document.getElementsByClassName("mobile-vehicle-popup-row")[0] as HTMLElement;
            footer.setAttribute("style", "display: none");
            mobilePopupRow.setAttribute("style", "display: block");
            await new Promise(resolve => setTimeout(resolve, 0));
            mobilePopupRow.setAttribute("style", "display: block; transform: translateY(0%);");
        }
    }

    setRecenterVisibility(vehiclesMap: any) {
        let button = document.getElementById("recenter-button");
        let allMarkersInBounds = this.checkIfAllMarkersAreInBounds(vehiclesMap);
        let zoomButtons = document.getElementsByClassName("map-popup-button-zoom");
        if (button !== null) {
            if (allMarkersInBounds) {
                button.style.display = "none";
            }
            else {
                button.style.display = "block";
            }
        }

        for (let i = 0; i < zoomButtons.length; i++) {
            if (vehiclesMap.getZoom() >= 17) {
                (zoomButtons[i] as HTMLElement).textContent = "Zoom Out";
            }
            else {
                (zoomButtons[i] as HTMLElement).textContent = "Zoom In";
            }
        }
    }

    checkIfAllMarkersAreInBounds(vehiclesMap: any): boolean {
        let allInBounds = true;
        let markers = vehiclesMap?.getMarkers();
        for (let marker of markers || []) {
            let inBounds = vehiclesMap.getBounds().contains(marker?._lngLat);
            if (!inBounds) {
                allInBounds = false;
                break;
            }
        }
        return allInBounds;
    }

    async refreshVehicleLocations(policy: PolicyInfo) {
        const { getLastKnownLocationByPolicyNumber, selectedVehicle, generateMapPopups, vehicleInformationOnClick } = this.props;
        const { vehicles, singleVehicleMap, vehiclesMap, popups } = this.state;

        console.dir("refreshing");
        await getLastKnownLocationByPolicyNumber(policy, vehicles);

        let markers = vehiclesMap.getMarkers();
        let singleMarkers = singleVehicleMap.getMarkers();

        markers.forEach((marker: any) => {
            vehicles.forEach((vehicle: DanlawVehicle) => {
                if(marker._element.id === "svgIcon-" + vehicle.id) {
                    marker.setLngLat([vehicle.latLong?.longitude, vehicle.latLong?.latitude]);
                }
            });
        });

        singleMarkers.forEach((marker: any) => {
            vehicles.forEach((vehicle: DanlawVehicle) => {
                if(marker._element.id === "svgIconSingle-" + vehicle.id) {
                    marker.setLngLat([vehicle.latLong?.longitude, vehicle.latLong?.latitude]);
                }
            });
        });

        vehicles?.forEach((vehicle: DanlawVehicle) => {
            let popupElement = document.getElementById("popup" + vehicle.id) as HTMLElement;

            let vehicleLocationAddress = vehicle?.nearestAddress?.addressLine1 ?? "No address found";
            let vehicleLocationCityState = (vehicle?.nearestAddress?.city ? (vehicle.nearestAddress.city + ", ") : "") + (vehicle?.nearestAddress?.state ?? "");
            let vehicleContainer = document.getElementById("vehicle-info" + vehicle.id) as HTMLElement;
            
            vehicleContainer.children[2].textContent = vehicleLocationAddress + ", " + vehicleLocationCityState;
            if (popupElement) 
            {
                popupElement.children[0].textContent = vehicle.chipMessage ?? "";
                popupElement.children[1].textContent = vehicle.vehicleType ?? "";
                popupElement.children[2].children[1].children[0].textContent = vehicleLocationAddress;
                popupElement.children[2].children[2].children[0].textContent = vehicleLocationCityState + ", " + vehicle?.nearestAddress?.zipCode;
                popupElement.children[3].children[1].children[0].textContent = new Date(Date.now()).toLocaleString();
            }
        });
        let vehicleLocationAddress = selectedVehicle?.nearestAddress?.addressLine1 ?? "No address found";
        let vehicleLocationCityState = (selectedVehicle?.nearestAddress?.city ? (selectedVehicle.nearestAddress.city + ", ") : "") + (selectedVehicle?.nearestAddress?.state ?? "");
        
        let singleVehicleAddress = document.getElementById("single-vehicle-location-address") as HTMLElement;
        let singleVehicleCityState = document.getElementById("single-vehicle-location-city-state") as HTMLElement;
        let singleVehicleLastConnection = document.getElementById("single-vehicle-location-last-connection") as HTMLElement;

        singleVehicleAddress.textContent = vehicleLocationAddress;
        singleVehicleCityState.textContent = vehicleLocationCityState;
        singleVehicleLastConnection.textContent = new Date(Date.now()).toLocaleString();
    }

    createMapPopup(popupModel: PopupModel) {
        const { vehicleInformationOnClick } = this.props;

        let popup = (
            <MapPopup 
            chipMessage={popupModel.chipMessage || ""} 
            chipStatus={popupModel.chipStatus || ""} 
            id={popupModel.id} 
            vehicle={popupModel.vehicle} 
            singleVehicleMap={popupModel.singleVehicleMap} 
            vehiclesMap={popupModel.vehiclesMap} 
            vehicles={popupModel.vehicles} 
            vehicleInformationOnClick={vehicleInformationOnClick}/>
        );
        return popup;
    }

    Radar() {
        const script = document.createElement("script");
        script.src = "https://js.radar.com/v4.4.2/radar.min.js";
        script.async = true;
        document.body.appendChild(script);
    };

    findCenter(markersList: any): any {
        let lat = 0;
        let long = 0;
        markersList.forEach((marker: any) => {
            lat += marker.getLngLat().lat;
            long += marker.getLngLat().lng;
        });
        return [long/markersList.length, lat/markersList.length];
    }

    vehicleDropDownRow(vehicle: DanlawVehicle): ReactElement {
        return (
            <option id={vehicle.id}>{vehicle.vehicleType}</option>
        )
    }

    onChangeSelectedVehicle = (e: ChangeEvent<HTMLSelectElement>) => {
        const { vehicles, singleVehicleMap } = this.state;
        let newVehicle = vehicles.find((vehicle: DanlawVehicle) => vehicle.id === e.target.selectedOptions[0].id);
        this.props.vehicleInformationOnClick(newVehicle, singleVehicleMap, vehicles);
        let vehicleSelect = document.getElementById("vehicleSelect") as HTMLSelectElement;
        if (vehicleSelect !== null) {
            vehicleSelect.selectedIndex = 0;
        }
    }

    deselectVehicle = (closePopup: boolean) => {
        const { vehicles } = this.props;
        const { vehiclesMap } = this.state;

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let vehicle = document.getElementById("vehicle-info" + vehicleFromList.id) as HTMLElement;
            vehicle.style.removeProperty("background");
            vehicle.style.removeProperty("border");
        });

        let markers = vehiclesMap.getMarkers();
        markers.forEach((marker: any) => {
            marker._element.children[0].style.transform = "scale(1.0)";
            marker._element.children[1].style.transform = "scale(1.0)";
        });

        if (closePopup) {
            let closeButton = document.getElementsByClassName("maplibregl-popup-close-button")[0];
            closeButton?.dispatchEvent(new MouseEvent("click"));
        }
    }

    closeMobilePopup = async (vehiclesMap: any) => {
        vehiclesMap.fitToMarkers({speed: 2, duration: 2500});
        let mobilePopupRow = document.getElementsByClassName("mobile-vehicle-popup-row")[0] as HTMLElement;
        let footer = document.getElementById("primary-footer") as HTMLElement;
        mobilePopupRow.setAttribute("style", "transform: translateY(110%); display: block;");
        await new Promise(resolve => setTimeout(resolve, 250));
        footer.setAttribute("style", "display: block");
        mobilePopupRow.setAttribute("style", "transform: translateY(110%); display: none;");
    }
    
    render(): ReactElement {
        const { selectedVehicle, generateMapPopups, vehicleInformationOnClick } = this.props;
        const { vehicles, singleVehicleMap, vehiclesMap, popups } = this.state;
        let vehicleChipClass = "vehicle-chip-label chips chips-" + selectedVehicle?.chipStatus;
        let deviceStatus = selectedVehicle?.deviceInfo?.deviceStatus;
        let deviceStatusTagType = "confirmation";

        if (deviceStatus){
            if (deviceStatus.toLowerCase() === "not connected" || deviceStatus.toLowerCase() === "being towed") {
                deviceStatusTagType = "concerning";
            }
            else if (deviceStatus.toLowerCase() === "parked" || deviceStatus.toLowerCase() === "idling") {
                deviceStatusTagType = "removed";
            }
            else {
                deviceStatusTagType = "confirmation";
            }
        }
        else {
            if (selectedVehicle?.deviceInfo?.deviceId){
                deviceStatusTagType = "removed";
                deviceStatus = "Error";
            }
            else {
                deviceStatus = "No Device Assigned"
                deviceStatusTagType = "";
            }
        }

        let deviceStatusTag = "tags tags-" + deviceStatusTagType;
        if (deviceStatusTagType === "") {
            deviceStatusTag = "";
        }

        let vehicleLocationAddress = selectedVehicle?.nearestAddress?.addressLine1 ?? "No address found";
        let vehicleLocationCityState = (selectedVehicle?.nearestAddress?.city ? (selectedVehicle.nearestAddress.city + ", ") : "") + (selectedVehicle?.nearestAddress?.state ?? "") + " " + (selectedVehicle?.nearestAddress?.zipCode ?? "");
        let lastConnectionTime = selectedVehicle?.deviceInfo?.lastConnectionTime ? new Date(Date.parse(selectedVehicle.deviceInfo.lastConnectionTime)).toLocaleString() : "No time found";

        return (
            <>
            <div className="container vehicles-container" id="vehicles-container" style={{display:"none"}}>
                <div className="row vehicles-container-row">
                    <div className="col-md-4 vehicles-list-container" id="vehicles-list-container" >
                        <h3 className="vehicles-list-title">Vehicles</h3>
                        {vehicles && vehicles.map((vehicle: DanlawVehicle) => <DashboardVehicle danlawVehicle={vehicle} vehiclesMap={vehiclesMap} vehicles={vehicles}></DashboardVehicle>)}
                    </div>
                    <div className="col-md-8 vehicle-map-column">
                        <div className="flexible-container vehicle-map-container">
                            <div id="vehicles-map-container" style={{ position: 'absolute' }}>
                                <div id="vehicles-map" style={{ position: 'absolute' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mobile-vehicle-popup-row">
                    <div className="flexible-container mobile-vehicle-popup">
                            <div className="row">
                                <div className="icon-close mobile-popup-close-icon" onClick={() => this.closeMobilePopup(vehiclesMap)}></div>
                            </div>
                            <div className="mobile-popup-body">
                                <div className="row">
                                    <div id="mobile-popup-chip"></div>
                                </div>
                                <div className="row">
                                    <p className="text-left" id="mobile-popup-vehicle-type"></p>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 mobile-popup-near">
                                        <div className='popup-text-block'>
                                            <p className="popup-text popup-text-headers">Near:</p>
                                            <p className="popup-text" id="mobile-popup-address"><b></b></p>
                                            <p className="popup-text" id="mobile-popup-city-state"><b></b></p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mobile-popup-since">
                                        <div className='popup-text-block'>
                                            <p className="popup-text popup-text-headers">As of:</p>
                                            <p className="popup-text" id="mobile-popup-time"><b></b></p>
                                            <p className="popup-text" id="mobile-popup-date"><b></b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row popup-separator-row">
                                <span className="stroke-separator mobile-popup-separator"></span>
                            </div>
                            <div className="row">
                                <div className="mobile-popup-vehicle-details">
                                <Button title="Vehicle Details" btnStyle={ButtonStyles.RightGreenButton} onClick={() => console.dir()} extraClassNames='map-popup-button map-popup-button-details' id="mobile-popup-vehicle-details-button"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div>
                {(generateMapPopups(vehicles, selectedVehicle, singleVehicleMap, vehiclesMap).payload).map((popup: PopupModel) => this.createMapPopup(popup))}
                {generateMapIconsWithId(vehicles, "svgIcon-").map((icon: ReactElement) => icon)}
            </div>
            <div className="container single-vehicle-container" id="single-vehicle-container" style={{display:"none"}}>
                <div className="row vehicle-select-header">
                    <div className="col-md-12 single-vehicle-select-vehicle">
                        <div className="vehicle-select-banner">
                            <div className="vehicle-select-type">
                                {selectedVehicle?.vehicleType}
                            </div>
                            <div className="vehicle-select-dropdown">
                                <form>
                                    <div className="form-field">
                                        <div className="select-box">
                                            <select id="vehicleSelect" name="vehicleSelect" defaultValue="Select Vehicle" onChange={this.onChangeSelectedVehicle}>
                                                <option disabled hidden>Select Vehicle</option>
                                                {vehicles && vehicles.map((vehicle: DanlawVehicle) => this.vehicleDropDownRow(vehicle))}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row single-vehicle-view">
                    <div className="col-md-8 single-vehicle-map-column">
                        <div className="flexible-container single-vehicle-map-container">
                            <div id="single-vehicle-map-container" style={{ position: 'absolute' }}>
                                <div id="single-vehicle-map" style={{ position: 'absolute' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 single-vehicle-details-list-container">
                        <div className="flexible-container single-vehicle-info">
                            <div className="single-vehicle-status">
                                <p className="text-left text-bold vehicle-info-header">Status <span className={vehicleChipClass + " single-vehicle-chip-label"}>{selectedVehicle?.chipMessage}</span></p>
                            </div>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">Near</p>
                                <p id="single-vehicle-location-address" className="text-left"><span data-qm-encrypt="true">{vehicleLocationAddress}</span></p>
                                <p id="single-vehicle-location-city-state" className="text-left">{vehicleLocationCityState}</p>
                            </div>
                                <span className="stroke-separator"></span>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">As of</p>
                                <p id="single-vehicle-location-last-connection" className="text-left">{lastConnectionTime}</p>
                            </div>
                        </div>
                        <div className="flexible-container single-vehicle-info">
                            <p className="text-left text-bold vehicle-info-header">Vehicle Info</p>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">Model</p>
                                <p className="text-left">{selectedVehicle?.vehicleType}</p>
                            </div>
                                <span className="stroke-separator"></span>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">VIN</p>
                                <p className="text-left"><span data-qm-encrypt="true">{"****" + selectedVehicle?.vin?.slice(-6)}</span></p>
                            </div>
                        </div>
                        <div className="flexible-container single-vehicle-info">
                            <p className="text-left text-bold vehicle-info-header">Device Info</p>
                            <div className="single-vehicle-data">
                                <p className="text-left">{selectedVehicle?.deviceInfo?.deviceId}</p>
                                <p className={deviceStatusTag}>{deviceStatus}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {generateMapIconsWithId(vehicles, "svgIconSingle-").map((icon: ReactElement) => icon)}
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    selectedVehicle: state.dshs.selectedVehicle,
    popups: state.dshs.popups,
    policy: state.dshs.policy
});

export default connect(mapStateToProps, DashboardStore.actionCreators)(VehiclesTab as any);