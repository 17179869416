import * as React from 'react';
import { Route, Switch } from 'react-router';
import './custom.css'
import '@gdk/full/dist/styles/gdk-full.css';
import '@gdk/base';
import '@gdk/styles';
import '@gdk/base/dist/styles/gdk-base.css';
import '@gdk/styles/dist/styles/gdk-styles.css';
import '@gdk/button-switch/dist/styles/gdk-button-switch.css';
import '@gdk/navigation/dist/styles/gdk-navigation.css';
import '@gdk/navigation/dist/styles/gdk-navigation.min.css';
import '@gdk/alert/dist/styles/gdk-alert.css';
import '@gdk/tooltip/dist/styles/gdk-tooltip.css';
import '@gdk/footer/dist/styles/gdk-footer.css';
import '@gdk/modal/dist/styles/gdk-modal.css';
import TelematicsPolicyManagment from './components/telematicsPolicyManagment/TelematicsPolicyManagment';
import InvoluntaryTelematics from './components/involuntaryTelematics/InvoluntaryTelematics';
import PrimaryFooterNavigation from './components/common/PrimaryFooterNavigation';
import PrimaryNavigation from './components/common/PrimaryNavigation';
import { connect } from 'react-redux';
import * as AuthStore from './store/AuthStore'
import { LoggedInUser, UserRole } from './models/LoggedInUser';
import { AuthProvider } from './api/authApi';
import AccessBanner from './components/common/AccessBanner';
import Logout from './components/common/logout';
import UnderWritingReport from './components/underwritingReport/UnderWritingReport';
import BulkPolicyUpload from './components/bulkPolicyUpload/BulkPolicyUpload';
import TelematicsJobs from './components/jobs/Jobs';
import BatchJobSettings from './components/batchJobSettings/BatchJobSettings';
import BlobUpload from './components/blobUpload/BlobUpload';
import DeleteCmtData from './components/deleteCmtData/DeleteCmtData';
import CustomerDashboard from './components/customerDashboard/CustomerDashboard';

declare global {
    interface Window {
        Radar: any;
    }
}

const getEssidCookie = (): boolean | null => {
    const value = new URLSearchParams(window.location.search).get("returnUrl") ?? "";
    if(value === null ||value === '' ||value?.includes("clientId"))
    {
        return false;
    }
    else 
    {
        return true;
    }
};
const essidCookie = getEssidCookie();

class App extends React.Component<any, any> {
    redirectToDashboard = false;
    constructor(props: any) {
        super(props);
        if (!essidCookie) {
            AuthProvider.registerAuthCallBack(this.onLoginCallBack);

            this.state = {
                originalNavigationUrl: window.location.href,
                originalNavigationPath: window.location.pathname + window.location.search,
            };
        }
        else {

            this.state = {
                originalNavigationUrl: window.location.href,
                originalNavigationPath: "/dashboard",
                redirectToDashboard: true,
            };
        }
    }

    onLoginCallBack(isLoggedIn: boolean) {
        console.log("App:onLoginCallBack");
        const { silentloginUser, loginError } = this.props;
        if (isLoggedIn) {
            silentloginUser();
        }
        else {
            loginError();
        }
    }

    async componentDidMount(): Promise<void> {
        const { loginCustomer } = this.props;
        const { silentloginUser } = this.props;
        const { originalNavigationPath, originalNavigationUrl } = this.state;
        
        if (!localStorage.getItem("policyNumber")) {
            localStorage.setItem("policyNumber", atob(new URLSearchParams(window.location.search).get("policyNumber") ?? ""));
        }

      if( new URLSearchParams(window.location.search).get("returnUrl") != ""){
        localStorage.setItem("returnUrl", (new URLSearchParams(window.location.search).get("returnUrl") ?? ""));
      }
        

        if (((originalNavigationPath != "/") && originalNavigationPath != "/dashboard") && window.location.href == originalNavigationUrl) {
            window.location.replace(window.location.origin);
        }

        if (essidCookie) {
            loginCustomer();
        }
        else {
            silentloginUser();
        }

        await new Promise(resolve => setTimeout(resolve, 1000));
        if (originalNavigationPath != "/") {
            this.setState({
                originalNavigationPath: "/",
            })
        }
        
        if (window.location.href != originalNavigationUrl) {
            window.location.replace(originalNavigationUrl);
        }
    }


    onLoginLogout(isAuthenticated: boolean): void {
        const { loginUser, logoutUser } = this.props;
        if (isAuthenticated) {
            logoutUser();
        }
        else {
            loginUser();
        }
    }

    render() {
        const { loggedInUser } = this.props;
        
        return (
            <div className="pattern-container main-content">
                <PrimaryNavigation loggedInUser={loggedInUser} onLoginLogout={this.onLoginLogout.bind(this)} />
                <main id="wrapper" role="main" className="bg-color--cloudy">
                    <div>
                        <Switch>
                            {this.showMainContent(loggedInUser)}
                            {this.enableLogout()}
                        </Switch>
                    </div>
                </main>
                <PrimaryFooterNavigation />
            </div>
        );
    }

    showMainContent = (loggedInUser: LoggedInUser) => {
        if (loggedInUser?.isAuthenticated) {
            switch (loggedInUser.userRole) {
                case UserRole.Admin:
                    {
                        return (
                            <React.Fragment>
                                {this.enableCustomerDashboard(loggedInUser)}
                                {this.enablePolicyManagement(loggedInUser)}
                                {this.enableInvoluntaryTelematics()}
                                {this.enableUnderWritingReport()}
                                {this.enableBulkPolicyUpload()}
                                {this.enableTelematicsJobsPanel()}
                                {this.enableBatchJobSettings()}
                                {this.enableBlobUpload()}
                                {this.enableCmtDelete()}
                            </React.Fragment>
                        );
                    }
                case UserRole.ServiceUser:
                    {
                        return (
                            <React.Fragment>
                                {this.enablePolicyManagement(loggedInUser)}
                                {this.enableUnderWritingReport()}
                                {this.enableCustomerDashboard(loggedInUser)}
                            </React.Fragment>
                        );
                    }
                case UserRole.Agent:
                    {
                        return (
                            <React.Fragment>
                                {this.enablePolicyManagement(loggedInUser)}
                                {this.enableUnderWritingReport()}
                                {this.enableCustomerDashboard(loggedInUser)}
                            </React.Fragment>
                        );
                    }
                case UserRole.Customer:
                    {
                        return (
                            <React.Fragment>
                                {this.enableCustomerDashboard(loggedInUser)}
                            </React.Fragment>
                        );
                    }
                default:
                    {
                        return (this.showNoAccessScreen(loggedInUser));
                    }
            }
        }
        else {
            return (this.showWelcomeScreen());
        }
    }

    isChrome = (): boolean => {
        return navigator.userAgent.indexOf("Chrome") != -1;
    }

    showUnsupportedBrowserScreen = () => {
        return (
            <Route render={() => (<AccessBanner title="(IE) Browser Not Supported" details="Please use Microsoft Edge or Google Chrome." />)} />
        );
    }

    showWelcomeScreen = () => {
        return (
            <Route exact path='/' render={() => (<AccessBanner title="Welcome" details="Commercial Telematics" />)} />
        );
    }

    showNoAccessScreen = (loggedInUser: LoggedInUser) => {
        if (!loggedInUser?.isAuthenticated && loggedInUser?.hasError) {
            return (<Route exact path='/' render={() => (<AccessBanner title="Access Denied." details={loggedInUser?.errorMessage} />)} />);
        }
        else {
            return (<Route exact path='/' render={() => (<AccessBanner title="Contact Helpdesk" details="Role(s) not assigned to user." />)} />);
        }
    }

    enableCustomerDashboard = (loggedInUser: LoggedInUser) => {
        if (loggedInUser?.userRole === UserRole.Customer) {
            return (<Route exact path='/' render={() => (<CustomerDashboard />)} />);
        }
        else {
            return (<Route exact path='/dashboard' render={() => (<CustomerDashboard />)} />);
        }
    }

    enablePolicyManagement = (loggedInUser: LoggedInUser) => {
        if (loggedInUser?.userRole === UserRole.Customer) {
            return (<Route exact path='/TelematicsPolicyManagment' render={() => (<TelematicsPolicyManagment />)} />);
        } else {
            return (<Route exact path='/' render={() => (<TelematicsPolicyManagment />)} />);
        }
    }

    enableUnderWritingReport = () => {
        return (<Route exact path='/UnderWritingReport' render={() => (<UnderWritingReport />)} />);
    }

    enableBulkPolicyUpload = () => {
        return (<Route exact path='/BulkPolicyUpload' render={() => (<BulkPolicyUpload />)} />);
    }

    enableInvoluntaryTelematics = () => {
        return (<Route exact path='/InvoluntaryTelematics' render={() => (<InvoluntaryTelematics />)} />);
    }

    enableBatchJobSettings = () => {
        return (<Route exact path='/BatchJobSettings' render={() => (<BatchJobSettings />)} />);
    }

    enableTelematicsJobsPanel = () => {
        return (<Route exact path='/Jobs' render={() => (<TelematicsJobs />)} />);
    }

    enableBlobUpload = () => {
        if (window.location.hostname.split("-")[1] || window.location.hostname === "localhost") {
            return (<Route exact path='/BlobUpload' render={() => (<BlobUpload />)} />);
        }
    }

    enableCmtDelete = () => {
        if (window.location.hostname.split("-")[1] || window.location.hostname === "localhost") {
            return (<Route exact path='/DeleteCmtData' render={() => (<DeleteCmtData />)} />);
        }
    }

    enableLogout = () => {
        if (!this.isChrome()) {
            return (
                <Route exact path='/logout' render={() => (<Logout />)} />
            );
        }
    }
}

const mapStateToProps = (state: any) => ({
    loggedInUser: state.loggedInUser
});

export default connect(
    mapStateToProps,
    AuthStore.msalAuthActions
)(App as any);