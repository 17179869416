import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import TelematicsApi from '../api/telematicsApi';
import { ActionWithPayload, API_ERROR, FINISHED_LOADING, IS_LOADING, KnownAction, BATCHJOBSETTINGS_RETRIEVAL_SUCCESS, BATCHJOBSETTINGS_UPDATE_SUCCESS } from './Actions';
import BatchJobSettings from '../models/BatchJobSettings';


export const actionCreators = {
    getBatchJobs: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.getBatchJobSettings()
            .then(result => {
                dispatch({
                    type: BATCHJOBSETTINGS_RETRIEVAL_SUCCESS,
                    payload: result,
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            })
            .catch(err => {
                dispatch({
                    type: API_ERROR,
                    payload: "Error retrieving Batch Job Settings. Please Try Again",
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    updateBatchJobs: (putRequest: BatchJobSettings): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.putBatchJobSettings(putRequest)
            .then(() => {
                dispatch(actionCreators.getBatchJobs());
            })
            .catch(err => {
                dispatch({
                    type: API_ERROR,
                    payload: "Error Updating Batch Job Settings. Please Try Again",
                })
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
};

const unloadedState: any = {
    batchJobs: null,
    isLoading: false,
    error: null,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case BATCHJOBSETTINGS_RETRIEVAL_SUCCESS:
                return {
                    ...state,
                    batchJobs: action.payload.batchJobs,
                    error: null,
                    isLoading: false,
                };
            case BATCHJOBSETTINGS_UPDATE_SUCCESS:
                return {
                    ...state,
                    error: null,
                    isLoading: false,
                };
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false
                };
            default:
                return state;
        }
    };
