import React, { ChangeEvent, ReactElement } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import Input, { InputType } from '../common/Input';
import ButtonSwitch from '../common/ButtonSwitch';
import { Vehicle } from '../../models/Vehicle';
import DatePicker from '../common/DatePicker';
import { excludeSpecialCharacters, excludeSpecialCharactersForTagMac, formatEffectiveDate } from '../../helpers/textHelpers';
import { isNullOrUndefined } from 'util';

interface VehicleModalProps {
    vehicle: Vehicle;
    onClose(): void;
    onSave(vehicle: Vehicle): void;
    mode: PopUpMode;
}

export enum PopUpMode {
    AddManually,
    Add,
    Remove,
    Update
}
export interface Style {
    display: string;
}

export default class VehicleModalPopup
    extends React.Component<VehicleModalProps, any> {
    currentVehicle!: Vehicle;

    constructor(props: VehicleModalProps) {
        super(props);
        const { vehicle } = this.props;
        this.currentVehicle = vehicle;
        this.state = {
            enrolled: !this.currentVehicle.isEnrolled,
            sendTag: !this.currentVehicle.sendTag,
            suppressCommunication: !this.currentVehicle.suppressCommunication,
            currentVehicle: this.props.vehicle,
            validationErrors: {} as any
        };
    }

    dateRegex = RegExp(/(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](20)\d\d/);
    yearNumberRegex = RegExp(/[1-2]\d\d\d/);

    componentDidMount(): void {
        const comp = document.getElementById('id');
        const { mode } = this.props;
        if (comp) {
            comp.focus();
        }

        if (mode !== PopUpMode.Update && !this.state.currentVehicle.sendTag) {
            this.toggleSendTag();
        }
    }

    toggleTelematicsEnrollment = (): void => {
        const { mode } = this.props;
        if (mode !== PopUpMode.AddManually) {
            this.setState({
                sendTag: false,
                enrolled: !this.state.currentVehicle.isEnrolled,
                currentVehicle: {
                    ...this.state.currentVehicle,
                    sendTag: false,
                    isEnrolled: !this.state.currentVehicle.isEnrolled,
                },
            });
        }
    }

    toggleSendTag = (): void => {
        if (this.state.currentVehicle.isEnrolled) {
            this.setState({
                sendTag: !this.state.currentVehicle.sendTag,
                currentVehicle: {
                    ...this.state.currentVehicle,
                    sendTag: !this.state.currentVehicle.sendTag,
                },
            });
        }
    }

    toggleSuppressCommunication = (): void => {
        this.setState({
            suppressCommunication: !this.state.currentVehicle.suppressCommunication,
            currentVehicle: {
                ...this.state.currentVehicle,
                suppressCommunication: !this.state.currentVehicle.suppressCommunication,
            },
        });
    }

    onCancel = (): void => {
        const { onClose } = this.props;
        onClose();
    }

    onSave = (): void => {
        const { onSave, mode } = this.props;
        if (mode !== PopUpMode.Add && !this.validateVehicle()) {
            return;
        }
        onSave(this.state.currentVehicle);
    }

    onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            currentVehicle: {
                ...this.state.currentVehicle,
                [e.target.id]: excludeSpecialCharacters(e.target.value),
            }
        });
    }

    onChangeTagMacAddress = (e: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            currentVehicle: {
                ...this.state.currentVehicle,
                [e.target.id]: excludeSpecialCharactersForTagMac(e.target.value),
            }
        });
    }

    updateEffectiveDate = (e: ChangeEvent<HTMLInputElement>) => {
        const { currentVehicle } = this.state;

        this.setState({
            currentVehicle: {
                ...this.state.currentVehicle,
                enrollmentEffectiveDate: formatEffectiveDate(e.target.value, currentVehicle.enrollmentEffectiveDate)
            }
        })
    }

    validateVehicle() {
        const { currentVehicle } = this.state;
        let validationErrors = {} as any;
        let isValid = true;
        if (currentVehicle.id === '' || currentVehicle.id == null) {
            validationErrors.id = 'Vehicle ID is required.'
            isValid = false;
        }
        if (currentVehicle.vin === '' || currentVehicle.vin == null) {
            validationErrors.vin = 'Vin is required.'
            isValid = false;
        }
        if (currentVehicle.make === '' || currentVehicle.make == null) {
            validationErrors.make = 'Make is required.'
            isValid = false;
        }
        if (currentVehicle.model === '' || currentVehicle.model == null) {
            validationErrors.model = 'Model is required.'
            isValid = false;
        }
        if (currentVehicle.year === '' ||
            currentVehicle.year == null ||
            !this.yearNumberRegex.test(currentVehicle.year)) {
            validationErrors.year = 'Year is required.'
            isValid = false;
        }
        if (currentVehicle.enrollmentEffectiveDate === '' ||
            currentVehicle.enrollmentEffectiveDate == null ||
            !this.dateRegex.test(currentVehicle.enrollmentEffectiveDate)) {
            validationErrors.enrollmentEffectiveDate = "Enrollment effective date is required."
            isValid = false;
        }

        this.setState({
            validationErrors,
        });
        return isValid;
    }

    render(): ReactElement {
        const { mode } = this.props;
        if (mode === PopUpMode.AddManually) {
            this.state.currentVehicle.isEnrolled = true;
        }
        return (
            <div id="vehiclePopup" className="modal modal--show " style={{ display: 'block' }}>
                <div className="modal-container">
                    <span className="tab-filler" />
                    <div className="section-header ">
                        <span className="geico-icon icon-vehicle" />
                        <h2>
                            {mode === PopUpMode.AddManually ? 'Add Vehicle Manually' : this.AddOrUpdateVehicleTitle(mode)}
                        </h2>
                    </div>
                    <button className="btn-close icon-close" aria-label="Close" type="button" onClick={this.onCancel} />
                    <div className="modal-content ">
                        <div className="container margin-top">
                            <div className="row" id="row-0">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="id"
                                        label="Vehicle ID"
                                        onChange={this.onChange}
                                        value={this.state.currentVehicle.id}
                                        name="IdTextBox"
                                        size={29}
                                        dataQmAllow={true}
                                        maxLength={100}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.id}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="tagMacAddress"
                                        label="Tag MAC Address"
                                        onChange={this.onChangeTagMacAddress}
                                        value={this.state.currentVehicle.tagMacAddress}
                                        dataQmAllow={true}
                                        name="TagMacAddressTextBox"
                                        size={29}
                                        maxLength={100}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-1">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="vin"
                                        label="Vin"
                                        onChange={this.onChange}
                                        value={this.state.currentVehicle.vin}
                                        name="VinTextBox"
                                        size={29}
                                        maxLength={17}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.vin}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="make"
                                        label="Make"
                                        onChange={this.onChange}
                                        value={this.state.currentVehicle.make}
                                        dataQmAllow={true}
                                        name="MakeTextBox"
                                        size={29}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.make}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-2">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="model"
                                        label="Model"
                                        onChange={this.onChange}
                                        value={this.state.currentVehicle.model}
                                        dataQmAllow={true}
                                        name="ModelTextBox"
                                        size={29}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.model}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="year"
                                        label="Year"
                                        onChange={this.onChange}
                                        value={!isNullOrUndefined(this.state.currentVehicle.year) && this.state.currentVehicle.year.length === 5
                                            ? this.state.currentVehicle.year.substring(1) : this.state.currentVehicle.year}
                                        name="YearTextBox"
                                        size={29}
                                        maxLength={4}
                                        dataQmAllow={true}
                                        inputType={InputType.Number}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.year}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-3">
                                <div className="col-sm-12 col-md-6">
                                    <DatePicker
                                        id="enrollmentEffectiveDate"
                                        name="effectiveDate"
                                        size={29}
                                        maxLength={10}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={this.state.validationErrors.enrollmentEffectiveDate}
                                        value={this.state.currentVehicle.enrollmentEffectiveDate}
                                        label="Effective Date"
                                        dataQmAllow={true}
                                        onChange={this.updateEffectiveDate}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <ButtonSwitch id="enrolled"
                                        isChecked={this.state.currentVehicle.isEnrolled}
                                        onClick={this.toggleTelematicsEnrollment}
                                        title="Enrolled in Telematics?"
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-4">
                                <div className="col-sm-12 col-md-6">
                                    <ButtonSwitch id="sendTag"
                                        isChecked={this.state.currentVehicle.sendTag}
                                        onClick={this.toggleSendTag}
                                        title="Send Tag"
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <ButtonSwitch id="suppressCommunication"
                                        isChecked={this.state.currentVehicle.suppressCommunication}
                                        onClick={this.toggleSuppressCommunication}
                                        title="Suppress Communications"
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-5">
                                <div className="col-sm-6" />
                                <div className="col-sm-6">
                                    <div className="modal--call-to-action-bar">
                                        <Button btnStyle={ButtonStyles.BlueTableButton} onClick={this.onCancel} title="Cancel" />
                                        <Button btnStyle={ButtonStyles.GreenTableButton} onClick={this.onSave} title="Save" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private AddOrUpdateVehicleTitle(mode: PopUpMode): React.ReactNode {
        return mode === PopUpMode.Add ? 'Add Vehicle' : 'Update Vehicle';
    }
}
