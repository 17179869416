import React, { Component, Fragment } from 'react';

interface ButtonSwitchProps {
    id?: string;
    isChecked: boolean;
    title?: string;
    onClick: () => void;
}

export default class ButtonSwitch extends Component<ButtonSwitchProps> {
    getClassNames(): string {
        const { isChecked } = this.props;
        if (isChecked) {
            return 'button-switch button-switch--start-left button-switch--slide-right';
        }

        return 'button-switch button-switch--start-left button-switch--slide-left';
    }

    setChecked(): boolean {
        const { isChecked } = this.props;
        if (isChecked) {
            return true;
        }

        return false;
    }

    render() {
        const { id, title, onClick } = this.props;
        return (
            <Fragment>
                <label
                    htmlFor="ButtonSwitchDemo"
                    className="text"
                >
                    {title}
                </label>
                <div
                    id={id}
                    className={this.getClassNames()}
                    aria-labelledby="ButtonSwitchDemolabel"
                    aria-checked={this.setChecked()}
                    role="checkbox"
                    tabIndex={0}
                    onClick={() => onClick()}
                >
                    <span className="icon-confirmation" />
                    <span className="icon-close" />
                </div>
            </Fragment>
        );
    }
}
