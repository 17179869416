import React, { Fragment } from 'react';

interface ButtonProps {
    id?: string;
    title: string;
    btnStyle: ButtonStyles;
    onClick: (event: any) => void;
    onKeyPress?: (event: any) => void;
    disabled?: boolean;
    extraClassNames? :string;
}

export enum ButtonStyles {
    LeftGreenButton = 'btn--primary btn--pull-left',
    RightGreenButton = 'btn--primary btn--pull-right',
    LeftBlueButton = 'btn--secondary btn--pull-left',
    RightBlueButton = 'btn--secondary btn--pull-right',
    GreenTableButton = 'btn--primary btn--pull-right right-table-button',
    BlueTableButton = 'btn--secondary btn--pull-right right-table-button',
    RedTableButton = 'btn--secondary btn--pull-right right-table-button remove',
    DataFixDelete = 'btn--secondary remove-data-fix',
    BlueCenteredTableButton = 'btn--secondary btn--pull-center middle-table-button',
    TertiaryButton = 'btn--tertiary btn--pull-right',
}

export default ({ id, btnStyle, onClick, onKeyPress, title, disabled, extraClassNames }: ButtonProps) => {
    return (
        <Fragment>
            <button
                id={id}
                className={btnStyle + " " + extraClassNames}
                onClick={onClick}
                onKeyPress={onKeyPress}
                type="button"
                disabled={disabled}
            >
                {title}
            </button>
        </Fragment>
    );
}
