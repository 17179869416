import React, { Component } from 'react';

interface InputProps {
    id?: string;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    onFocus?: (event: any) => void;
    onKeyDown?: (event: any) => void;
    onPositionChange?: (event: any) => void;
    startPosition?: number;
    endPosition?: number;
    name?: string;
    value?: string;
    placeHolder?: string;
    errorMessage?: string;
    label?: string;
    subText?: string;
    maxLength?: number;
    inputType?: InputType;
    size?: number;
    disabled?: boolean;
    className?: string;
    dataQmAllow?: boolean;
}

export enum InputSize {
    Small = 5,
    Medium = 10,
    Large = 20,
    ExtraLarge = 30,
    DoubleExtraLarge = 34,
}

export enum InputType {
    Text,
    Telephone,
    Number
}

export default class Input extends Component<InputProps> {

    inputRef = React.createRef<HTMLInputElement>();
    
    constructor(props: InputProps) {
        super(props);
        //@ts-ignore
        window.inputRef = this.inputRef
    }
    getInputType(){
        switch(this.props.inputType){
            case InputType.Text:
                return 'text';
            case InputType.Telephone:
                return 'tel';
            case InputType.Number:
                return 'number'
            default:
                return 'text';
        }
    }

    componentDidUpdate(){
        this.setPosition();
    }

    setPosition() {
        const { startPosition, endPosition } = this.props;
        if(startPosition != null && endPosition != null){
            this.inputRef.current && this.inputRef.current.setSelectionRange(startPosition, endPosition);
        }
    }

    setErrorClass() {
        if(this.props.errorMessage) {
            return 'form-field--error';
        }
        return '';
    }

    renderLabel() {
        if(this.props.label){
            return (
                <label htmlFor="field5" className="text">{this.props.label}</label>
            );
        }
    }

    setClasses() {
        if(this.props.className){
            return this.props.className;
        }
        return '';
    }

    possiblePositionChangeDetected(e: any, methodToCallAfter: ((event: any) => void) | undefined, callingmethod: string) {
        this.props.onPositionChange && this.props.onPositionChange(e.target && e.target);
        methodToCallAfter && methodToCallAfter(e);
    }

    renderSubText() {
        if(this.props.subText){
            return (
                <span className="form-descriptive-copy">{this.props.subText}</span>
            );
        }
    }

    renderErrorMessage() {
        if(this.props.errorMessage){
            return (
                <span id="formMessage" className="form-message" style={{ display: "block" }}>{this.props.errorMessage}</span>
            );
        }
    }

    render() {
        return (
            <div className={"form-field " + this.setErrorClass()}>
                {this.renderLabel()}
                {this.renderSubText()}
                <input id={this.props.id}
                    ref={this.inputRef}
                    className={this.setClasses()}
                    type={this.getInputType()}
                    name={this.props.name}
                    placeholder={this.props.placeHolder}
                    maxLength={this.props.maxLength}
                    size={this.props.size}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    data-qm-allow={this.props.dataQmAllow}
                    onChange={(e) => this.possiblePositionChangeDetected(e, this.props.onChange, "change")}
                    onKeyDown={(e) => this.possiblePositionChangeDetected(e, this.props.onKeyDown, "keydown")}
                    />
                    {this.renderErrorMessage()}
            </div>
        )
    }
}