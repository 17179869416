export default class AuthConfig {

    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    static loginRequest = {
        scopes: ["User.Read"]
    };

    // Add here scopes for access token to be used at MS Graph API endpoints.
    static tokenRequest = {
        scopes: ["Mail.Read"]
    };

    static getCurrentEnvironment = () => {
        let url = window.location.hostname.toUpperCase();
        if (url.indexOf("LOCALHOST") !== -1) { return "DV1"; }
        if (url.indexOf("DEV") !== -1) { return "DEV"; }
        if (url.indexOf("DV1") !== -1) { return "DV1"; }
        if (url.indexOf("IN1") !== -1) { return "IN1"; }
        if (url.indexOf("UT1") !== -1) { return "UT1"; }
        if (url.indexOf("EU1") !== -1) { return "EU1"; }
        if (url.indexOf("SB1") !== -1) { return "SB1"; }
        if (url.indexOf("LT1") !== -1) { return "LT1"; }
        if (url.indexOf("LT") !== -1) { return "LT1"; }
        if (url.indexOf("LT8") !== -1) { return "LT8"; }
        if (url.indexOf("TR1") !== -1) { return "TR1"; }
        if (url.indexOf("FT1") !== -1) { return "FT1"; }
        return "PD1";
    }

    static getAuthority() {
        return "https://login.microsoftonline.com/7389d8c0-3607-465c-a69f-7d4426502912";
    }
    static getRedirectUrl() {
        return window.location.origin;
    }

    static getClientId() {
        let env = AuthConfig.getCurrentEnvironment();
        switch (env) {
            case "DEV": { return "a05129a0-74f8-4a8a-9006-233002d6d285"; }
            case "DV1": { return "a05129a0-74f8-4a8a-9006-233002d6d285"; }
            case "IN1": { return "4b16064e-e3f1-4c1e-9610-76ac74248053"; }
            case "UT1": { return "2527917b-87c0-4e98-901d-7654e4120baa"; }
            case "LT1": { return "4c17e15a-1c69-4e34-87b8-d3a28e31ab3c"; }
            case "LT": { return "4c17e15a-1c69-4e34-87b8-d3a28e31ab3c"; }
            case "LT8": { return "4c17e15a-1c69-4e34-87b8-d3a28e31ab3c"; }
            case "FT1": { return "1e1710a9-df36-445a-bd9f-085112d57019"; }
            case "SB1": { return "f7fe5014-a570-42d5-8f88-de297f30bb80"; }
            //Default PD1 and PD8
            default: { return "92f96378-d309-4854-a368-4a6a2eea4ebe"; }
        }
    }

    static getConfig(): any {
        let config = {
            "authority": AuthConfig.getAuthority(),
            "clientId": AuthConfig.getClientId(),
            "redirectUri": AuthConfig.getRedirectUrl(),
            "postLogoutRedirectUri": AuthConfig.getRedirectUrl() + "/logout"
        }
        return config;
    }
}