import React, { Component } from 'react';

export interface DropdownProps {
	label?: string;
	data: string[];
	defaultValue?: string;
	errorMessage?: string;
	onChange?: (event: any) => void;
}

export class Dropdown extends Component<DropdownProps> {
	render() {
		const { onChange, defaultValue, label, data, errorMessage } = this.props;
		return (
			<div className='row'>
				<div className='col-sm-12'>
					<div className={`form-field ${errorMessage ? 'form-field--error' : ''}`}>
						{label &&
							<label className='text'>{label}</label>
						}
						<div className='select-box w-full'>
							<select name='state' defaultValue={defaultValue} onChange={onChange}>
								<option key={'Please Select'}>Please Select</option>
								{data.map((d) => (
									<option key={d}>{d}</option>))}
							</select>
							{errorMessage &&
								<span id='formMessage' className='form-message' style={{ display: 'block' }}>{errorMessage}</span>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
