export const formatEffectiveDate = (value: string, previousValue: string): string => {
    if (!value) {
        return value;
    }

    const currentValue = value.replace(/[^\d]/g, '');

    if (!previousValue || value.length > previousValue.length) {
        if (currentValue.length < 2) {
            return currentValue;
        }
        if (currentValue.length < 4) {
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
        }
        return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
    }

    return value;
};

export const formatPhoneNumber = (value: string, previousValue: string): string => {
    if (!value) {
        return value;
    }

    const currentValue = value.replace(/[^\d]/g, '');

    if (!previousValue || value.length > previousValue.length) {
        if (currentValue.length < 4) {
            return currentValue;
        }
        if (currentValue.length < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        }
        return `(${currentValue.slice(0, 3)})-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }

    return "";
};

export const excludeSpecialCharacters = (value: string): string => {
    if (!value) {
        return value;
    }

    return value.replace(/[^\w\s-.,]/gi, '');
};

export const excludeSpecialCharactersForTagMac = (value: string): string => {
    if (!value) {
        return value;
    }

    return value.replace(/[^\w\s:]/gi, '');
};

export const getCurrentEnvironment = () => {
    let url = window.location.hostname.toUpperCase();
    if (url.indexOf("LOCALHOST") !== -1) { return "Development"; }
    if (url.indexOf("DV1") !== -1) { return "Development"; }
    if (url.indexOf("ED1") !== -1) { return "Extended Development"; }
    if (url.indexOf("IN1") !== -1) { return "Integration"; }
    if (url.indexOf("UT1") !== -1) { return "User"; }
    if (url.indexOf("EU1") !== -1) { return "Extended User"; }
    if (url.indexOf("TT1") !== -1) { return "Time Travel"; }
    if (url.indexOf("SB1") !== -1) { return "SandBox"; }
    if (url.indexOf("LT1") !== -1) { return "Load(LT1)"; }
    if (url.indexOf("LT8") !== -1) { return "Load(LT8)"; }
    if (url.indexOf("TR1") !== -1) { return "Training"; }
    if (url.indexOf("FT1") !== -1) { return "FastTrack"; }
    return "Production";
}