import React, { Fragment, ReactElement } from 'react';

export default (firstColumn: string, secondColumn: (string|ReactElement)) => {
    return (
        <Fragment>
            <li>
                <div>{firstColumn}</div>
                <div>{secondColumn}</div>
            </li>
        </Fragment>
    );
};
