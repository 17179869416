import React from "react";
import DanlawVehicle from "../../models/DanlawVehicle";
import { GdkTooltip } from "@gdk/tooltip";
import { DeviceStatusHistory } from "../../models/DeviceInfo";
import { GdkAccordion } from '@gdk/accordion';

interface DeviceDetailsFlyoutProps {
    selectedVehicle: DanlawVehicle;
    onClose: () => void;
}

const getDeviceStatusStyle = (deviceStatus: string | undefined): string => {
    switch (deviceStatus) {
        case "Connected":
            return "success";
        case "DisConnected":
            return "alert";
        case "Ready For Install":
            return "warning";
        default:
            return "neutral";
    }
};

class DeviceDetailsFlyoutClass extends React.Component<DeviceDetailsFlyoutProps> {

    constructor(props: DeviceDetailsFlyoutProps) {
        super(props);
    }

    componentDidMount() {
        var deviceIdTooltip = new GdkTooltip({
            content: "#tooltip-trigger-1", 
        });
        const { selectedVehicle } = this.props;
        const accordion = new GdkAccordion({
            content: document.getElementById("statusHistoryAccordion") as HTMLElement,
            shouldCloseOthers: true,});
    }

    render() {
        const { selectedVehicle } = this.props;
        let dateAsDate = selectedVehicle?.deviceInfo?.lastConnectionTime ? Date.parse(selectedVehicle.deviceInfo.lastConnectionTime) : NaN;
        let dateString = new Date(dateAsDate).toLocaleString();

        return (
            <form>
                <div className="edit-panel">
                    <h3>
                        OBD {" ..." + (selectedVehicle.deviceInfo?.deviceId?.slice(-4) ?? "")}{"  "}
                        {(() => {
                            let deviceStatus = selectedVehicle?.deviceInfo?.deviceStatus || "--";
                            let vehicleChipClass = deviceStatus === "--" ? "" : "text-left chips chips-" + getDeviceStatusStyle(deviceStatus);
                            if (deviceStatus && deviceStatus !== "--") {
                                return <p className={vehicleChipClass} style={{ fontSize: '2em' }}>{deviceStatus}</p>;
                            }
                            return null;
                        })()}
                        <button 
                            type="button" 
                            className="close icon-close" 
                            data-dismiss="modal" 
                            aria-label="Close" 
                            style={{ fontSize: '1.25em', opacity: 1 }}
                            onClick={this.props.onClose}
                        >
                        </button>
                    </h3>
                    <br />
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 data-flyout-table-data-font" >
                                <div className="tooltip-label-container" id="deviceIdTooltip" >
                                    <label id="tooltip-label-1" className="text label--inline device-id-status-text">Device ID</label>
                                    <div className="tooltip-container device-id-status-icon">
                                        <a href="#" id="tooltip-trigger-1" className="tooltip-trigger" data-tooltip-view="tooltip-1" aria-labelledby="tooltip-label-1"><span className="icon-question-mark"></span></a>
                                    </div>
                                    <div id="tooltip-1" className="tooltip">
                                        <div style={{ color: 'white' }}>The Device ID is the 10 digit serial number found on the sticker on the bottom of your device.</div>
                                        <button className="icon-close tooltip-close-button" aria-label="Close tooltip"></button>
                                    </div>
                                </div>
                                {selectedVehicle.deviceInfo?.deviceId}
                            </div>
                            <div className="col-lg-6">
                                <b>Device Type</b>
                                <br />
                                <span className="data-flyout-table-data-font">OBD</span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-lg-6" >
                                <b>Vehicle</b>
                                <br />
                                <span className="data-flyout-table-data-font">
                                    <a href="#" onClick={(event) => { event.preventDefault(); }}>
                                    {selectedVehicle.vehicleType}
                                    {" ..." + (selectedVehicle.vin?.slice(-4) ?? "")}
                                    </a>
                                </span>
                            </div>
                            <div className="col-lg-6 device-flyout-data-modified">
                                <b>Last Connection</b>
                                <br />
                                <span className="data-flyout-table-data-font">
                                    {dateString}
                                    <br />
                                    <span data-qm-encrypt="true">{selectedVehicle?.nearestAddress?.addressLine1},</span>{" "}{selectedVehicle?.nearestAddress?.city},{" "}{selectedVehicle?.nearestAddress?.state}{" "}{selectedVehicle?.nearestAddress?.zipCode}                                </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <ul id="statusHistoryAccordion" className="accordion" style={{ width: "100%" }}>
                        <li>
                            <div className="accordion-headline">
                                <div className="accordion-headline-content-wrapper">
                                    <div className="accordion-left-content-wrapper">
                                        <div className="heading h4">History</div>
                                    </div>
                                    <div className="accordion-right-content-wrapper">
                                        <span>{selectedVehicle.deviceInfo?.statusHistory ? selectedVehicle.deviceInfo?.statusHistory.length : 0} entries</span>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-content-container" >
                                <div className="accordion-content">
                                        <div className="data-table">
                                            <table className="table data-table-suppress-header">
                                                <thead style={{ display: 'none' }}>
                                                    <tr >
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {selectedVehicle.deviceInfo?.statusHistory?.map((deviceStatusItem: DeviceStatusHistory) => (
                                                    <tr className="device-flyout-data " style={{ padding: 0, margin: 0 }}>
                                                        <td data-title="Date" className="col-md-2">
                                                            {deviceStatusItem.statusDateTime && new Date(deviceStatusItem.statusDateTime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                                            <br />
                                                            {deviceStatusItem.statusDateTime ? new Date(deviceStatusItem.statusDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace('AM', 'AM ET').replace('PM', 'PM ET') : ''}
                                                        </td>
                                                        <td data-title="Status" className="col-md-6">
                                                            {deviceStatusItem.status}
                                                            <br />
                                                            <br />
                                                            {deviceStatusItem.isAssociatedToVehicle && (
                                                               <>{selectedVehicle.vehicleType} {" ..." + (selectedVehicle.vin?.slice(-4) ?? "")}
                                                               <br /></>
                                                            )}
                                                            {deviceStatusItem.address && (
                                                                <>
                                                                    <span data-qm-encrypt="true">{deviceStatusItem.address.addressLine1 && <>{deviceStatusItem.address.addressLine1}</>},</span> {deviceStatusItem.address.city}, {deviceStatusItem.address.state} {deviceStatusItem.address.zipCode}
                                                                </>
                                                            )}
                                                            {deviceStatusItem?.status?.toLowerCase() === "device on the way" && (
                                                                <a href={`https://www.ups.com/track?track=yes&trackNums=12345`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                                    <u>Track Shipment</u>
                                                                </a>
                                                            )}
                                                       </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>
        );
    }
}

export default DeviceDetailsFlyoutClass;
