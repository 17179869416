import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import Input, { InputType } from '../common/Input';
import { connect } from 'react-redux';
import MessageAlert from '../common/MessageAlert';
import * as BlobUploadStore from '../../store/BlobUploadStore';
import { Dropdown } from '../common/Dropdown';
import { excludeSpecialCharacters, formatEffectiveDate, getCurrentEnvironment } from '../../helpers/textHelpers';
import ButtonSwitch from '../common/ButtonSwitch';
import DatePicker from '../common/DatePicker';
import { PromptModal } from '../common/PromptModal';
import TelematicsApi from '../../api/telematicsApi';

export class BlobUpload extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFileSelected: false,
            fileType: 'Please Select',
            fileUploaded : File,
            showSubmitPromptModal: false,
            submitted: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: '',
            fileDate: null
        };
    }

    fileNames = [
        'driver_summary',
        'heartbeat',
        'tag_lite_summary_list',
        'trip_summary',
        'user_permissions_report',
    ];

    componentDidMount = () => {
        const { resetProps } = this.props;
        resetProps();
    }

    reset = () => {
        const { resetProps } = this.props;

        this.setState({
            isFileSelected: false,
            fileUploaded: null,
            fileType: null,
            showSubmitPromptModal: false,
            fileDate: null,
        })
        resetProps();
    }

    submitChanges = () => {
        window.scrollTo(0, 0);
        const { uploadFile } = this.props;
        const {
            fileType,
        } = this.state;
        const {
            fileDate,
        } = this.state;

        if (fileType != 'Please Select' && fileDate != null) {
            const form = document.getElementById('file-form1') as HTMLFormElement
            const formData = new FormData(form)
            console.log(fileDate.value)

            uploadFile(formData, fileType, fileDate)

            this.reset()
        }
        else {
            this.setState({ showSubmitPromptModal: false })
        }
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue });
    }

    renderErrorMessage = () => {
        const { formMessage } = this.state;
        const { error } = this.props;
        if (error && formMessage !== "Unable to upload file. Please try again later.") {
            this.setState({ formMessageColor: "red", formMessage: "Unable to upload file. Please try again later." })
        }

        if (error) {
            return (
              <MessageAlert title={"Technical Error"} message={error} />
            )
        }
    }

    renderSuccessMessage = () => {
        const { blobFileResponse } = this.props;
        if (blobFileResponse) {
            let fileSuccessMessage = 'Successfully uploaded file: ' + blobFileResponse.filePath;
            return (
                <MessageAlert title={"Upload Success"} message={fileSuccessMessage} />
            )
        }
    }

    render(): ReactElement {
        const {
            fileType,
            showSubmitPromptModal,
            fileUploaded,
            isFileSelected,
            fileDate,
        } = this.state;
        return (
            <div>
                <div className="container margin-top">
                    {this.renderErrorMessage()}
                    {this.renderSuccessMessage()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Upload Blob File'
                            infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                            yes={this.submitChanges}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                revertedChanges: false,
                                submitted: false,
                                formMessageColor: "red",
                                formMessage: "Changes have not been saved."
                            })}
                        />
                    }
                    {
                    <div hidden={isFileSelected } >
                            <div className="drop drop-zone">
                                <div className="font--center">
                                    <form id="file-form1" >
                                    <div className = "form-field">
                                    <span className="geico-icon icon-logout icon-upload"></span>
                                        <p><span></span> <label className="drop-link"><input type="file" accept=".csv" id="FileSelected" name="uploadedFile" className="hide-file" onChange={(e) => this.setState({ fileUploaded: e.target.files?.item(0), isFileSelected: true })} /> Select file</label></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                    {isFileSelected &&
                        <div className="drop doc-status-wrapper">
                            <div className="container-padding">
                            </div>
                            <div className="doc-upload container-padding">
                                <div className="doc-upload-header">
                                    <h3>Selected File</h3>
                                    <Button
                                        btnStyle={ButtonStyles.RightGreenButton}
                                        onClick={() => this.setState({ showSubmitPromptModal: true })}
                                        title="Submit File"
                                    />
                                </div>
                                <div className="doc-upload-files">
                                    <span className="img-icon csv-image-icon hidden-sm hidden-md"></span>
                                    <label>{fileUploaded.name}</label>
                                    <a onClick={() => this.reset() } ><span className="geico-icon geico-icon--actionable geico-icon--small icon-trash"></span></a>
                                </div>
                                <div className="doc-upload-select">
                                    <form id="file-form">
                                        <div className="form-field" >
                                            <label htmlFor="upload1" className="text ">Select the file name:</label>
                                            <Dropdown
                                                defaultValue={fileType}
                                                data={this.fileNames}
                                                onChange={(e) => this.setState({ fileType: e.target.value })}
                                                />
                                        </div>
                                        <div className="form-field">
                                            <label htmlFor="upload1" className="text ">Select the Blob file date:</label>
                                        <input id="fileDate" name="fileDate" className="date date-datepicker" type="date" placeholder="MM/DD/YYYY" onChange={(e) => this.setState({fileDate: e.target.value}) } />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

const mapStateToProps = (state: any) => ({
    loggedInUser: state.loggedInUser,
    error: state.blobUpload.error,
    fileUploaded: state.fileUploaded,
    isFileSelected: state.isFileSelected,
    fileDate: state.fileDate,
    blobFileResponse: state.blobUpload.blobFileResponse
})

export default connect(mapStateToProps, BlobUploadStore.actionCreators)(BlobUpload);