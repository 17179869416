import React, { PureComponent, ReactElement, Fragment } from 'react';
import { getCurrentEnvironment } from '../../helpers/textHelpers';
import { Driver } from '../../models/Driver';
import Button, { ButtonStyles } from '../common/Button';
import InfoModal from '../common/InfoModal';
import { PromptModal } from '../common/PromptModal';
import DriverModalPopup, { PopUpMode } from './DriverPopupModal';

export interface DriverManagementProps {
    error: string;
    drivers: Driver[];
    headers?: string[];
    onSaveDriverChanges: (drivers: Driver[], userComments: string) => void;
}

interface DriverTableState {
    showDriverPopup: boolean;
    showErrorModal: boolean;
    selectedDriver: Driver;
    drivers: Driver[];
    popUpMode: PopUpMode;
    driversToSave: Array<Driver>;
    showSubmitPromptModal: boolean;
    submitted: boolean;
    revertedChanges: boolean;
    cancelledChanges: boolean;
    formMessage: string;
    formMessageColor: string;
    userComments: string;
}

export default class DriversTable extends PureComponent<
    DriverManagementProps, DriverTableState> {
    constructor(props: DriverManagementProps) {
        super(props);
        this.state = {
            showDriverPopup: false,
            showErrorModal: false,
            popUpMode: PopUpMode.Add,
            drivers: this.props.drivers,
            driversToSave: [],
            selectedDriver: {} as Driver,
            showSubmitPromptModal: false,
            submitted: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: "",
        };
    }

    componentDidMount() {
        const searchOption = document.getElementsByName('state')[0] as HTMLInputElement;
        const searchBy = document.getElementById('searchPolicyInput') as HTMLInputElement;
        const driverRow = document.getElementById(searchBy.value) as HTMLInputElement;
        if (searchOption.value === "DriverId/CustomerKey") {
            driverRow.style.fontWeight = '1000';
            driverRow.style.backgroundImage = 'linear-gradient(to right, rgba(255,255,0,0), rgba(255,255,0,1))';
        }
    }

    componentDidUpdate = (prevProps: any) => {
        const { drivers } = this.props
        if (prevProps.drivers !== drivers) {
            this.setState({
                drivers: drivers,
            });
        }
    }

    addDriverManually = () => {
        this.setState({
            showDriverPopup: true,
            popUpMode: PopUpMode.AddManually,
            selectedDriver: {} as Driver,
        });
    };

    addDriver = (driver: Driver) => {
        this.setState({
            showDriverPopup: true,
            popUpMode: PopUpMode.Add,
            selectedDriver: driver,
        });
    };

    editDriver = (driver: Driver) => {
        this.setState({
            showDriverPopup: true,
            popUpMode: PopUpMode.Update,
            selectedDriver: driver,
        });
    };

    removeDriver = (driver: Driver) => {
        let driversToSave = this.state.driversToSave;
        let index = driversToSave.indexOf(driver);

        if (index !== -1) {
            driversToSave.splice(index, 1);
            this.setState({
                driversToSave: driversToSave,
                showDriverPopup: false,
                selectedDriver: {} as Driver
            });
        }
    };

    onDriverPopupClose = () => {
        this.setState({
            showDriverPopup: false,
            selectedDriver: {} as Driver,
        });
    }

    onSaveDriver = (driver: Driver) => {
        let drivers = this.updateCurrentDriverInDriverList(driver);
        let changedDriversToSave = this.getDriverListWithDriverChanges(driver);
        this.setState({
            driversToSave: changedDriversToSave,
            drivers,
        });
        this.onDriverPopupClose();
    }

    updateCurrentDriverInDriverList(driver: Driver) {
        const { drivers } = this.state;
        if (driver.id == null || driver.id == "")
            return drivers;

        let modifiedDriverIndex = 0;
        let nonModifiedDrivers = drivers.filter((d, i) => {
            if (d.id == driver.id) {
                modifiedDriverIndex = i;
            }
            return d.id != driver.id
        });
        nonModifiedDrivers.splice(modifiedDriverIndex, 0, driver);
        return nonModifiedDrivers;
    }

    getDriverListWithDriverChanges(driver: Driver) {
        const { driversToSave } = this.state;
        const newDriverList = driversToSave.slice();


        if (driver.id == null || driver.id == '') {
            return newDriverList.concat(driver);
        }

        let indexOfOldData = 0;
        const oldDriverData = newDriverList.find((d, i) => {
            indexOfOldData = i;
            return d.id == driver.id
        });
        if (oldDriverData) {
            newDriverList[indexOfOldData] = driver;
        } else {
            newDriverList.push(driver);
        }
        return newDriverList;
    }

    onSaveDriverChanges = () => {
        this.props.onSaveDriverChanges(this.state.driversToSave, this.state.userComments);
        this.setState({
            driversToSave: [],
            showErrorModal: true,
            showSubmitPromptModal: false,
            submitted: true,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    updateCommentsSaveDriverChanges = (commentValue: string): void => {
        this.setState({ userComments: commentValue }, () => this.onSaveDriverChanges());
    }

    renderPopup = (): ReactElement | null => {
        const { popUpMode, selectedDriver, showDriverPopup } = this.state;
        if (showDriverPopup) {
            return (<DriverModalPopup
                driver={selectedDriver}
                onSave={this.onSaveDriver}
                onClose={this.onDriverPopupClose}
                mode={popUpMode}
            />);
        }

        return null;
    }

    renderResetButton = (): ReactElement | null => {
        if (this.state.driversToSave.length > 0) {
            return (
                <Button title="Reset"
                    btnStyle={ButtonStyles.RightBlueButton}
                    onClick={() => this.setState({
                        revertedChanges: true,
                        cancelledChanges: false,
                        submitted: false,
                        formMessageColor: "red",
                        formMessage: "Changes have been removed and not applied.",
                        driversToSave: [],
                        drivers: this.props.drivers
                    })} />
            )
        }
        return null;
    };

    maskDriversLicenseNumber = (isDriversLicenseNumberDirty: boolean,
        driversLicenseNumber: string) => {
        const mask = '****';

        if (driversLicenseNumber == null) {
            return driversLicenseNumber;
        }

        if (!isDriversLicenseNumberDirty && driversLicenseNumber.length >= 4) {
            return mask.concat(driversLicenseNumber.substr(driversLicenseNumber.length - 4))
        }

        return driversLicenseNumber;
    }

    renderDriverRow = (driver: Driver) => {
        const maskedDriverLicenseNumber = this.maskDriversLicenseNumber(
            driver.isDriversLicenseNumberDirty,
            driver.driversLicenseNumber,
        );
        return (
            <tr id={driver.id}>
                <td data-title={driver.firstName}>
                <span data-qm-encrypt="true"> {driver.firstName} </span>
                </td>
                <td data-title={driver.lastName}>
                <span data-qm-encrypt="true"> {driver.lastName} </span>
                </td>
                <td data-title={maskedDriverLicenseNumber}>
                    {maskedDriverLicenseNumber}
                </td>
                <td data-title={driver.driversLicenseState}>
                    {driver.driversLicenseState}
                </td>
                <td data-title={driver.mobileNumber}>
                <span data-qm-encrypt="true">{driver.mobileNumber?.length == 15 ? driver.mobileNumber?.substring(1) : driver.mobileNumber}</span>
                </td>
                <td data-title={driver.enrollmentEffectiveDate}>
                    {driver.enrollmentEffectiveDate}
                </td>
                <td data-title={driver.driverStatus}>
                    {driver.driverStatus.charAt(0) == "H" ? driver.driverStatus.substring(1) : driver.driverStatus}
                </td>
                <td data-title={driver.isEnrolled}>
                    {driver.isEnrolled ? 'Yes' : 'No'}
                </td>
                <td data-title="Edit">
                    {
                        driver.id
                            ?
                            this.isMissingDriver(driver)
                            : <Button
                                btnStyle={ButtonStyles.RedTableButton}
                                onClick={() => this.removeDriver(driver)}
                                title="Remove"
                            />

                    }
                </td>
            </tr>);
    }

    renderPolicyDrivers = () => {
        const { drivers } = this.state;
        if (!drivers) return;
        
        return drivers.map((driver) => {
            return this.renderDriverRow(driver);
        });
    }

    renderAddedDrivers = () => {
        const { driversToSave } = this.state;
        const hasNewDrivers = driversToSave.filter(driver => driver.id == "" || driver.id == null);
        if (driversToSave.length > 0 && hasNewDrivers.length != 0)
            return (
            <>
                <h3>Added</h3>
                <table className="table table--non-sortable table--editable-non-sortable">
                    <tbody>
                            {
                                driversToSave.map((driver) => {
                                if(!driver.id)
                                return this.renderDriverRow(driver);
                                })
                            }
                    </tbody>
                </table>
            </>);
        else
            return null;
    }

    private isMissingDriver(driver: Driver): React.ReactNode {
        return driver.driverStatus.charAt(0) == "H" ?
            <Button
                btnStyle={ButtonStyles.GreenTableButton}
                onClick={() => this.addDriver(driver)}
                title="Add Driver" />
            : <Button
                btnStyle={ButtonStyles.BlueTableButton}
                onClick={() => this.editDriver(driver)}
                title="Edit" />;
    }

    render(): ReactElement {
        const { headers, error, drivers } = this.props;
        const { showErrorModal, showSubmitPromptModal, formMessage } = this.state;

        const closeErrorModal = (): void => {
            if (formMessage !== "Changes have not been committed due to a technical error.") {
                this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
            }

            this.setState({ showErrorModal: false, drivers: drivers })
        }

        return (
            <Fragment>
                {this.renderPopup()}
                {showErrorModal && error &&
                    <InfoModal
                        title='Technical Error'
                        infoText={error}
                        onClose={closeErrorModal}
                    />
                }
                {showSubmitPromptModal &&
                    <PromptModal
                        title='Update Drivers'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateCommentsSaveDriverChanges}
                        no={() => this.setState({
                            showSubmitPromptModal: false,
                            cancelledChanges: true,
                            revertedChanges: false,
                            submitted: false,
                            formMessageColor: "red",
                            formMessage: "Changes have not been saved."
                        })}
                    />
                }
                <table className="table table--non-sortable table--editable-non-sortable">
                    <thead>
                        <tr className="management-table-header">
                            {
                                headers && headers.map((header) => (
                                    <th key={header} className="management-table-header">
                                        {header}
                                    </th>
                                ))
                            }
                            <td style={{ borderTop: 0 }}>
                                <Button
                                    btnStyle={ButtonStyles.GreenTableButton}
                                    onClick={this.addDriverManually}
                                    title="Add Driver Manually"
                                    key="AddManually"
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderPolicyDrivers()}
                    </tbody>
                </table>
                {this.renderAddedDrivers()}
                {(this.state.revertedChanges || this.state.cancelledChanges || this.state.submitted) &&
                    <p className={this.state.formMessageColor}>{this.state.formMessage}</p>
                }
                { this.state.driversToSave.length > 0 &&
                    <Button btnStyle={ButtonStyles.RightGreenButton} onClick={() => this.setState({ showSubmitPromptModal: true })} title="Save Driver Changes" key="Add" />
                }
                {this.renderResetButton()}
            </Fragment>
        );
    }
}
