import React, { Component } from 'react';
import { connect } from 'react-redux';
import GDKLoader from '@gdk/loader';

export class Loader extends Component<any,any>{
    
    componentDidMount() {

        (window as any).loader = new GDKLoader({
            content: "#the-coolest-loader-in-the-world",
            type: 'section'
        });
    }

    componentDidUpdate = () => {
        const { isLoading } = this.props;

        if(isLoading) {
            (window as any).loader.show();
        }else{
            (window as any).loader.hide();
        }
    }

    renderLoader = () => {
        const { isLoading } = this.props;
            return (
                <div style={{display: isLoading ? 'block' : 'none'}}>
                    <div id="the-coolest-loader-in-the-world" className="loader-container">
                        <div className="loader-content">
                            <div className="loader" aria-label="Please wait, it is loading"></div>
                            <div className="loader-copy">Loading</div>
                        </div>
                    </div> 
                </div>
            ); 
        }

    render () {
        return (
            this.renderLoader()
        )
    }

}

const mapStateToProps = (state: any) => ({
    isLoading: state.ui.isLoading
});

export default connect(mapStateToProps)(Loader);
