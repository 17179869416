import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ActionWithPayload, KnownAction } from './Actions';
import * as Actions from './Actions';
import MapPopup from '../components/common/MapPopup';
import DanlawVehicle, { LatLong } from '../models/DanlawVehicle';
import { setCustomerDashboardPanel } from '../helpers/navigationHelpers';
import TelematicsApi from '../api/telematicsApi';
import { ReplaceObdDeviceRequest } from '../models/ReplaceObdDeviceRequest';
import { ReplaceObdDeviceResponse } from '../models/ReplaceObdDeviceResponse';
import { ObdShipmentStatus } from '../models/ObdShipmentStatus';
import * as LastKnownLocation from '../models/LastKnownLocationResponse';
import { PolicyInfo, UpdatePolicyInfoRequest } from '../models/PolicyInfo';
import * as policyStore from './PolicyStore';
import { VehicleChipMessagesDict, VehicleChipStatusDict, VehicleChipStylesDict } from '../components/common/DashboardVehicle';
import { ReverseGeocodeRequest } from '../models/ReverseGeocodeRequest';
import { ReverseGeocodeResponse } from '../models/ReverseGeocodeResponse';

export const actionCreators = {
    updateSelectedVehicle: (vehicle: DanlawVehicle): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: Actions.UPDATE_SELECTED_VEHICLE,
            payload: vehicle
        });
    },
    generateMapPopups: (vehicles?: DanlawVehicle[], vehicle?: DanlawVehicle, singleVehicleMap?: any, vehiclesMap?: any): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        let popups: any[] = [];
        let index = 1;
        if (vehicles){
            vehicles.forEach((vehicle: DanlawVehicle) => {
                let newPopup = {
                    id: "popup" + index,
                    chipStatus: vehicle.chipStatus,
                    chipMessage: vehicle.chipMessage,
                    vehicle: vehicle,
                    singleVehicleMap: singleVehicleMap,
                    vehiclesMap: vehiclesMap,
                    vehicles: vehicles,
                };
                popups.push(newPopup);
                index++;
            });
        }

        return {payload: popups};
    },
    vehicleInformationOnClick: (vehicle: DanlawVehicle, singleVehicleMap: any, vehicles: DanlawVehicle[]): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        setCustomerDashboardPanel("single-vehicle");
        let footer = document.getElementById("primary-footer") as HTMLElement;
        footer.setAttribute("style", "display: block");

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let svgIcon = document.getElementById("svgIconSingle-" + vehicleFromList.id) as HTMLElement;
            if (vehicleFromList.id != vehicle.id) {
                svgIcon.setAttribute("style", "display: none");
            } else {
                svgIcon.setAttribute("style", "display: block");
            }
        });
    
        let markers = singleVehicleMap.getMarkers();
        markers.forEach(async (marker: any) => {
            if (marker._element.id === ("svgIconSingle-" + vehicle.id)) {
                /// 0 second timeout allows the map to display the marker before the flyTo function is called.
                await new Promise(resolve => setTimeout(resolve, 0));
                singleVehicleMap.flyTo({
                    center: [marker._lngLat.lng, marker._lngLat.lat],
                    zoom: 17,
                    speed: 1,
                    curve: 0.5,
                    duration: 2500
                });
            }
        });
        
        dispatch(actionCreators.updateSelectedVehicle(vehicle));
    },
    getLastKnownLocationByPolicyNumber : (policy: PolicyInfo, vehicles?: DanlawVehicle[]) => async (dispatch: any, getState: any) => {
        await TelematicsApi.getLastKnownLocationByPolicyNumber(policy?.policyNumber)
        .then(async (response: LastKnownLocation.LastKnownLocationResponse[]) =>{
            // Map LKL to all vehicles on the policy
            if (vehicles){
                let lklLoop = new Promise<void>((resolve) => {
                    vehicles.forEach((vehicle: DanlawVehicle) => {
                        response.forEach(async (locationResponse: LastKnownLocation.LastKnownLocationResponse) => {
                            if (vehicle.vin === locationResponse.vin){
                                vehicle.latLong = {
                                    latitude: locationResponse.lastKnownLocation.latitude,
                                    longitude: locationResponse.lastKnownLocation.longitude
                                };
                                vehicle.lastKnownLocation = locationResponse;
                                let geoPromise = new Promise<void>(async (geoResolve) => {
                                    let geoIndex = 0;
                                    TelematicsApi.reverseGeocode({
                                        latitude: vehicle.latLong?.latitude || 0,
                                        longitude: vehicle.latLong?.longitude || 0
                                    })
                                    .then((response: ReverseGeocodeResponse) => {
                                        vehicle.nearestAddress = {
                                            addressLine1: response.addresses[0].addressLabel, 
                                            city: response.addresses[0].city, 
                                            zipCode: response.addresses[0].postalCode, 
                                            state: response.addresses[0].state
                                        };
                                        geoIndex++;
                                        if (geoIndex === vehicles.length){
                                            geoResolve();
                                        }
                                    });
                                }).catch(() => {
                                    resolve();
                                });
                                geoPromise.then(() => {
                                    resolve();
                                });
                            }
                        });
                    });
                });
                lklLoop.finally(() => {
                    dispatch({
                        type: Actions.LAST_KNOWN_LOCATION_SUCCESS,
                        payload: vehicles
                    });
                    dispatch({
                        type: Actions.FOUND_POLICY,
                        payload: policy
                    });
                    dispatch(actionCreators.updateSelectedVehicle(vehicles[0]));
                    dispatch({
                        type: Actions.FINISHED_LOADING
                    });
                });
            }
            else {
                let vehicleArray: DanlawVehicle[] = [];
                let index = 1;
                let lklLoop = new Promise<void>((resolve) => {
                    response.forEach(async (locationResponse: LastKnownLocation.LastKnownLocationResponse) => {
                        let telematicsVehicle = policy.vehicles?.find((vehicle: any) => vehicle.vin === locationResponse.vin);
    
                        let status = VehicleChipStatusDict.find((chipStatus) => chipStatus.message == locationResponse.status)?.status;
                        let message = VehicleChipMessagesDict.find((chipMessage) => chipMessage.status == status)?.message;
                        let style = VehicleChipStylesDict.find((chipStyle) => chipStyle.message == status)?.style;
                        let vehicle: DanlawVehicle = {
                            id: index.toString(),
                            chipStatus: style,
                            chipMessage: message,
                            latLong: {
                                latitude: locationResponse.lastKnownLocation.latitude,
                                longitude: locationResponse.lastKnownLocation.longitude
                            },
                            lastKnownLocation: locationResponse,
                            vin: locationResponse.vin,
                            vehicleType: telematicsVehicle ? telematicsVehicle.year + " " + telematicsVehicle.make + " " + telematicsVehicle.model : "Vehicle",
                            deviceInfo: {
                                lastConnectionTime: locationResponse.lastConnection,
                                deviceStatus: message,
                                style: style,
                                deviceId: locationResponse.serialNumber,
                            },
                        };
                        vehicleArray.push(vehicle);
                        index++;
                    });
                    let geoPromise = new Promise<void>(async (geoResolve) => {
                        let geoIndex = 0;
                        vehicleArray.forEach((vehicle: DanlawVehicle) => {
                            TelematicsApi.reverseGeocode({
                                latitude: vehicle.latLong?.latitude || 0,
                                longitude: vehicle.latLong?.longitude || 0
                            })
                            .then((response: ReverseGeocodeResponse) => {
                                vehicle.nearestAddress = {
                                    addressLine1: response.addresses[0].addressLabel, 
                                    city: response.addresses[0].city, 
                                    zipCode: response.addresses[0].postalCode, 
                                    state: response.addresses[0].state
                                };
                                geoIndex++;
                                if (geoIndex === vehicleArray.length){
                                    geoResolve();
                                }
                            });
                        });
                    })
                    .catch(() => {
                        resolve();
                    });
                    geoPromise.then(() => {
                        resolve();
                    });
                });
                lklLoop.finally(() => {
                    dispatch({
                        type: Actions.LAST_KNOWN_LOCATION_SUCCESS,
                        payload: vehicleArray
                    });
                    dispatch({
                        type: Actions.FOUND_POLICY,
                        payload: policy
                    });
                    dispatch(actionCreators.updateSelectedVehicle(vehicleArray[0]));
                    dispatch({
                        type: Actions.FINISHED_LOADING
                    });
                });
            }
        })
        .catch(() => {
            dispatch({
                type: Actions.API_ERROR,
                payload: "There was an issue getting the last known location for the policy. Please try again later."
            });
            dispatch({
                type: Actions.FINISHED_LOADING
            });
        });
    },
    getLastKnownLocationByVin : (vin: string) => async (dispatch: any) => {
        TelematicsApi.getLastKnownLocationByVin(vin)
        .then((response: LastKnownLocation.LastKnownLocationResponse) =>{
            dispatch({
                type: Actions.LAST_KNOWN_LOCATION_SUCCESS,
                payload: response
        })})
        .catch(() => {
            dispatch({
                type: Actions.API_ERROR,
                payload: "There was an issue getting the last known location for the vin. Please try again later."
            });
        });
    },
    getObdShipmentStatus : (policyNumber: string) => async (dispatch: any) => {
        TelematicsApi.getObdShipmentStatus(policyNumber)
        .then((response: ObdShipmentStatus) =>{
            dispatch({
                type: Actions.SHIPMENT_STATUS_FOUND,
                payload: response
        })})
        .catch(() => {
            dispatch({
                type: Actions.SHIPMENT_STATUS_NOT_FOUND,
                payload: "There was an issue getting the OBD shipment status for the policy. Please try again later."
            });
        });
    },
    updatePolicyInformation : (updatePolicyInfoRequest: UpdatePolicyInfoRequest) => async (dispatch: any) => {
        dispatch(policyStore.actionCreators.updatePolicyInformation(updatePolicyInfoRequest));
    },
    replaceObdDevice : (replaceObdDeviceRequest: ReplaceObdDeviceRequest) => async (dispatch: any) => {
        dispatch({
            type: Actions.IS_LOADING,
            payload: true,
        });
        dispatch({
            type: Actions.CLEAR_ERROR,
        });
        TelematicsApi.replaceObdDevice(replaceObdDeviceRequest)
            .then((response: ReplaceObdDeviceResponse) =>{
                dispatch({
                    type: Actions.OBD_REPLACEMENT_SUCCESS,
                    payload: response
                })
                dispatch({
                    type: Actions.FINISHED_LOADING
                });
            })
            .catch(() => {
                dispatch({
                    type: Actions.OBD_REPLACEMENT_FAILURE,
                    payload: "There was an issue replacing the OBD device for the policy. Please try again later."
                });
                dispatch({
                    type: Actions.FINISHED_LOADING
                });
            });
    },
    searchPolicyWithoutCproa: (policyNumber: string, searchOption: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: Actions.IS_LOADING,
            payload: true,
        });
        TelematicsApi.getPolicyWithoutCproaByPolicyNumber(policyNumber, searchOption)
            .then(result => {
                if (result.programType !== null && result.programType[0] === "DriveEasyProOBD2") {
                    dispatch(actionCreators.getLastKnownLocationByPolicyNumber(result))
                    dispatch(actionCreators.getObdShipmentStatus(policyNumber));
                }
                else {
                    dispatch({
                        type: Actions.API_NO_POLICY_FOUND,
                        payload: "There was an issue finding policy number: " + policyNumber + ". Please try again later."
                    });
                    dispatch({
                        type: Actions.FINISHED_LOADING
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: Actions.API_NO_POLICY_FOUND,
                    payload: "There was an issue finding policy number: " + policyNumber + ". Please try again later."
                });
                dispatch({
                    type: Actions.FINISHED_LOADING
                });
        });
    },
};

const unloadedState: any = {
    selectedVehicle: undefined,
    popups: undefined,
    vehicles: undefined,
    shipments: undefined,
    policy: undefined,
    error: undefined,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case Actions.GENERATE_MAP_POPUPS:
                return {
                    ...state,
                    popups: action.payload,
                    isLoading: false,
                }
            case Actions.UPDATE_SELECTED_VEHICLE:
                return {
                    ...state,
                    selectedVehicle: action.payload,
                    isLoading: false,
                }
            case Actions.SHIPMENT_STATUS_FOUND:
                return {
                    ...state,
                    shipments: action.payload,
                    isLoading: false,
                }
            case Actions.SHIPMENT_STATUS_NOT_FOUND:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false,
                }
            case Actions.OBD_REPLACEMENT_SUCCESS:
                return {
                    ...state,
                    shipments: action.payload,
                    isLoading: false,
                }
            case Actions.OBD_REPLACEMENT_FAILURE:
                return {
                    ...state,
                    error: action.payload,
                    isLoading: false,
                }
            case Actions.LAST_KNOWN_LOCATION_SUCCESS:
                return {
                    ...state,
                    vehicles: action.payload,
                    isLoading: false,
                }
            case Actions.FOUND_POLICY:
                return {
                    ...state,
                    policy: action.payload,
                }
            case Actions.CLEAR_ERROR:
                return {
                    ...state,
                    error: undefined,
                }
            default:
                return state;
        }
    };
