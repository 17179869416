import React, { Fragment, ReactElement } from 'react';
import TwoColumnListItem from './TwoColumnListItem';

interface TwoColumnListProps {
    id?: string;
    firstColumn: string[];
    secondColumn: (string|ReactElement)[];
}

export default ({id, firstColumn, secondColumn}: TwoColumnListProps) => {
    return(
        <Fragment>
            <ul className="list list--two-column" id={id}>
                {TwoColumnList({id, firstColumn, secondColumn})}
            </ul>
        </Fragment>
    )
}

export const TwoColumnList = ({id, firstColumn, secondColumn}: TwoColumnListProps): JSX.Element[] => {
    let itemList = [];
    for (let i = 0; i < firstColumn.length; i++) 
    {
        itemList.push(TwoColumnListItem(firstColumn[i], secondColumn[i]));
    }

    return itemList;
}
