import React, { FunctionComponent } from 'react'
import Button, { ButtonStyles } from './Button'

type props = React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    title?: string;
    infoText?: string;
    no: () => any;
    yes: (x: string) => any;
}

export const PromptModal: FunctionComponent<props> = ({
    title,
    infoText,
    no,
    yes,
}) => {
    var commentValue: string;
    return (
        <div id="infoModal" className="modal modal--show" style={{ display: 'block' }}>
                <div className="modal-container modal--dialog-box">
                    <span tabIndex={0} className="tab-filler"></span>
                    <h2 className="modal-headline">{title}</h2>
                    <button className="btn-close icon-close" aria-label="Close" type="button" onClick={no} />
                    <div className="modal-content">
                        <p>{infoText}</p>
                        <div>
                            <p>Comments (optional)</p>
                            <input className="policy-comment-input" style={{ width: '90%' }} type="text" placeholder="Comments" onChange={x => commentValue = x.target.value}></input>
                        </div>
                        <div className="modal--call-to-action-bar">
                            <Button btnStyle={ButtonStyles.RightBlueButton} onClick={no} title="No" />
                            <Button btnStyle={ButtonStyles.RightGreenButton} onClick={() => yes(commentValue)} title="Yes" />
                        </div>
                    </div>
                    <span tabIndex={0} className="tab-filler"></span>
                </div>
            </div>
    )
}