import React, { PureComponent, ReactElement, Fragment, ChangeEvent, ChangeEventHandler } from 'react';
import { Driver } from '../../models/Driver';
import Input, { InputSize } from '../common/Input';
import Button, { ButtonStyles } from '../common/Button';
import { MailingAddress, PolicyInfo, UpdatePolicyInfoRequest } from '../../models/PolicyInfo';
import TwoColumnList from '../common/TwoColumnList';
import {GdkCardSelections} from '@gdk/card-selections'
import { Vehicle } from '../../models/Vehicle';
import PopUpModal from '../common/PopUpModal';
import { GdkButtonSwitch } from '@gdk/button-switch'; 
import { TagReplacementRequest } from '../../models/TagReplacementRequest';

export interface CmtTagReplacementProps {
    error: string;
    policy: PolicyInfo;
    drivers: Driver[];
    tagReplacement: (tagReplacementRequest: TagReplacementRequest) => void;
    updatePolicyInformation: (updatePolicyInfoRequest: UpdatePolicyInfoRequest) => void;
}

interface CmtTagReplacementState {
    showErrorModal: boolean;
    drivers: Driver[];
    mailingAddress: MailingAddress;
    selectedVehicle: Vehicle;
    updatePolicy: boolean;
    selectedReplacement: string;
    submitted: boolean,
    formMessage: string,
    formMessageColor: string,
    showSubmitPromptModal: boolean;
    showMailingAddressPromptModal: boolean;
}

export default class CmtTagReplacement extends PureComponent<
    CmtTagReplacementProps, CmtTagReplacementState> {
    constructor(props: CmtTagReplacementProps) {
        super(props);
        this.state = {
            showErrorModal: false,
            drivers: this.props.drivers || [],
            mailingAddress: {} as MailingAddress,
            selectedVehicle: {} as Vehicle,
            updatePolicy: false,
            selectedReplacement: '',
            submitted: false,
            formMessage: "",
            formMessageColor: "",
            showSubmitPromptModal: false,
            showMailingAddressPromptModal: false
        };
    }

    componentDidMount() {
        var cardSelections = new GdkCardSelections({
            "content" : "#card-selections-component",
            "initialActiveCard" : 1,
            "inputType": "checkbox"
        });

        this.setState({
            mailingAddress: {
                addressLine1: this.props.policy.addressLine1 || '',
                addressLine2: this.props.policy.addressLine2 || '',
                city: this.props.policy.city || '',
                zipCode: this.props.policy.zipCode || '',
                state: this.props.policy.state || ''
            }
        });
    }

    componentDidUpdate () {
        const { error } = this.props;

        if (error)
        {
            this.setState({
                formMessageColor: "red",
                formMessage: "Changes have not been committed due to a technical error."
            })
        }
    } 

    onChangeButtonSwitch = (e: React.MouseEvent<HTMLInputElement>) => {
        let checkbox = document.getElementById("your-switch-button-id-button-switch-checkbox") as HTMLInputElement;
        let checkboxValue = checkbox.checked;
        this.setState({
            updatePolicy: checkboxValue
        })
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.id) {
            case ("addressLine1"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        addressLine1: e.target.value
                    }
                })
                break;
            case ("addressLine2"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        addressLine2: e.target.value
                    }
                })
                break;
            case ("zipCode"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        zipCode: e.target.value
                    }
                })
                break;
            case ("state"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        state: e.target.value
                    }
                })
                break;
            case ("city"):
                this.setState({
                    mailingAddress: {
                        ...this.state.mailingAddress,
                        city: e.target.value
                    }
                })
                break;
        }
    }

    onChangeSelectedVehicle = (e: ChangeEvent<HTMLSelectElement>) => {
        let vehicleData =  e.target.value.split(" - ");
        let vin = vehicleData[1].slice(-4);
        let ymm = vehicleData[0].split(" ");
        let vehicle = this.props.policy.vehicles?.find(
            vehicle => vehicle.vin?.endsWith(vin) && 
            vehicle.year == ymm[0] &&
            vehicle.make == ymm[1] &&
            vehicle.model == ymm[2]) 
            || {} as Vehicle;

        this.setState({
            selectedVehicle: vehicle
        });
    }

    onChangeReplacementPart = (e: React.MouseEvent<HTMLFormElement>) => {
        let selectedItem = document.getElementsByClassName("card-selections-selected").item(0)?.id;
        this.setState({
            selectedReplacement: selectedItem || ''
        });
    }

    isSubmitButtonDisabled = (): boolean => {
        const { selectedVehicle, selectedReplacement } = this.state;
        return selectedVehicle.year && selectedReplacement ? false : true;
    }

    submitChanges = (): void => {
        const { tagReplacement, updatePolicyInformation, policy } = this.props;
        const { mailingAddress, selectedVehicle, updatePolicy } = this.state;
        this.setState({
            showSubmitPromptModal: false,
            selectedVehicle: {
                ... selectedVehicle,
                sendTag: true,
                tagShipmentNeeded: true
            }
        })

        if (updatePolicy) {
            const updatePolicyInfoRequest: UpdatePolicyInfoRequest = {
                policyNumber: policy.policyNumber,
                phoneNumber: policy.phoneNumber,
                isSmsEnabled: policy.isSmsEnabled,
                mailingAddress: {
                    addressLine1: mailingAddress.addressLine1,
                    addressLine2: mailingAddress.addressLine2,
                    city: mailingAddress.city,
                    state: mailingAddress.state,
                    zipCode: mailingAddress.zipCode,
                }
            };
            updatePolicyInformation(updatePolicyInfoRequest);
        }

        let tagReplacementRequest: TagReplacementRequest = {
            mailingAddress: mailingAddress,
            policyNumber: policy.policyNumber,
            messageSource: "telematics.EventType",
            messageType: "StandAloneTagRequest",
            isVoluntary: policy.isVoluntary || false,
            companyName: policy.companyName,
            ratedState: policy.ratedState || "",
            vendor: "CMT",
            vehicles: [selectedVehicle]
        }

        tagReplacement(tagReplacementRequest);

        this.setState({
            submitted: true,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    submitMailingAddressChanges = (): void => {
        this.setState({ 
            showMailingAddressPromptModal: false,
        });
    }

    popupModalDidMount = (): void => {
        var buttonSwitch = new GdkButtonSwitch({
            content: "#updatePolicyAddressSwitch", 
        });
    }

    popupSubmitModalDidMount = (): void => {
        var cardSelections = new GdkCardSelections({
            "content" : "#returnReasonSelection",
            "initialActiveCard" : 1,
            "inputType": "checkbox"
        });
    }

    editMailingAddressBody(): ReactElement {
        const { policy } = this.props;
        const { mailingAddress } = this.state;

        return (
            <>
                <div className="container">
                    <div className='row'>
                        <div id="policyInformationLeftColumn" className="col-lg-6">
                            <Input
                                id="companyName"
                                label="Company Name"
                                value={policy.companyName || ''}
                                name="CompanyNameTextBox"
                                dataQmAllow={true}
                                size={InputSize.Large}
                                disabled={true}
                            />
                            <Input
                                id="addressLine1"
                                label="Address Line 1"
                                value={mailingAddress.addressLine1 || ''}
                                name="AddressLine1TextBox"
                                size={InputSize.Large}
                                onChange={this.onChange}
                            />
                            <Input
                                id="addressLine2"
                                label="Address Line 2"
                                value={mailingAddress.addressLine2 || ''}
                                name="AddressLine2TextBox"
                                size={InputSize.Large}
                                onChange={this.onChange}
                            />
                            <Input
                                id="city"
                                label="City"
                                value={mailingAddress.city || ''}
                                name="CityTextBox"
                                dataQmAllow={true}
                                size={InputSize.Large}
                                onChange={this.onChange}
                            />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <Input
                                id="zipCode"
                                label="Zip Code"
                                value={mailingAddress.zipCode || ''}
                                name="ZipCodeTextBox"
                                dataQmAllow={true}
                                size={InputSize.Medium}
                                maxLength={10}
                                onChange={this.onChange}
                            />
                            <Input
                                id="state"
                                label="State"
                                value={mailingAddress.state || ''}
                                name="StateTextBox"
                                dataQmAllow={true}
                                size={InputSize.Small}
                                onChange={this.onChange}
                            />
                            <div id="updatePolicyAddressSwitch" className="button-switch-container" onClick={this.onChangeButtonSwitch}>
                                <div className="button-switch-label-wrapper">
                                    <label className="text">Update Policy Address?</label>
                                </div>
                                <input id="your-switch-button-id-button-switch-checkbox" name="updatePolicyAddressCheckbox" type="checkbox"/>
                                <div className="button-switch"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    submitBody(): ReactElement {
        return (
            <form>
                <div id="returnReasonSelection" className="card-selection-wrapper">
                    <div className="card-selections" aria-labelledby="card-selections-label-id">
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections1" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h4>Defective or damaged tag</h4>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections2" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h4>Dead Battery</h4>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                        <div className="card-selections-card">
                            <input type="checkbox" id="returnReasonSelections3" name="cardSelections" />
                            <span></span>
                            <div className="card-selections-header">
                                <h4>Other / General Replacement</h4>
                            </div>
                            <div className="card-selections-content">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    vehicleDropDown(): ReactElement {
        const { policy } = this.props;

        return (
            <form>
                <div className="form-field">
                    <div className="select-box">
                        <select id="vehicleSelect" name="vehicleSelect" defaultValue="Select Vehicle" onChange={this.onChangeSelectedVehicle} data-qm-allow="true">
                            <option disabled hidden>Select Vehicle</option>
                            {policy.vehicles?.map((vehicle) => this.vehicleDropDownRow(vehicle))}
                        </select>
                    </div>
                </div>
            </form>
        )
    }

    vehicleDropDownRow(vehicle: Vehicle): ReactElement {
        const ymm = `${vehicle.year.length === 5 ? vehicle.year.substring(1) : vehicle.year} ${vehicle.make} ${vehicle.model}`;
        const vin = "****" + vehicle.vin?.slice(-4);
        const optionValue = ymm + " - " + vin;

        return (
            <option>{optionValue}</option>
        )
    }

    render(): ReactElement {
        const { policy } = this.props;
        const { showSubmitPromptModal, showMailingAddressPromptModal, mailingAddress, submitted, formMessage, formMessageColor } = this.state;

        let addressMessage = mailingAddress.addressLine2 == null 
            ? { __html: `<span data-qm-encrypt="true">${mailingAddress.addressLine1}</span><br/>${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zipCode}` }
            : { __html: `<span data-qm-encrypt="true">${mailingAddress.addressLine1}</span><br/>${mailingAddress.addressLine2}<br/>${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zipCode}` };
            
        return (
            <Fragment>
                {showMailingAddressPromptModal &&
                    <PopUpModal
                        title='Update Mailing Address'
                        buttonText='Submit'
                        bodyContent={this.editMailingAddressBody()}
                        onClose={() => this.setState({ showMailingAddressPromptModal: false })}
                        onSubmit={this.submitMailingAddressChanges}
                        componentDidMount={this.popupModalDidMount}
                    />
                }
                {showSubmitPromptModal &&
                    <PopUpModal
                        title='Return Reason'
                        buttonText='Submit'
                        bodyContent={this.submitBody()}
                        onClose={() => this.setState({ showSubmitPromptModal: false })}
                        onSubmit={this.submitChanges}
                        componentDidMount={this.popupSubmitModalDidMount}
                    />
                }
                <div className="container">
                    <div className='row'>
                        <div style={{ whiteSpace: "pre-wrap" }} id="policyInformationLeftColumn" className="col-lg-6">
                            <TwoColumnList 
                                firstColumn={["Recipient Name", "Address", "Vehicle"]} 
                                secondColumn={[policy.companyName, <span dangerouslySetInnerHTML={addressMessage} />, this.vehicleDropDown()]} />
                            <Button btnStyle={ButtonStyles.LeftBlueButton} title="Edit Mailing Address" onClick={() => this.setState({ showMailingAddressPromptModal: true })} />
                        </div>
                        <div id="policyInformationRightColumn" className="col-lg-6">
                            <span></span>
                            <form onClick={this.onChangeReplacementPart}>
                                <div id="card-selections-component" className="card-selection-wrapper">
                                    <h3>Send Replacement</h3>
                                    <div className="card-selections" aria-labelledby="card-selections-label-id" id="cardSelectionsContainer">
                                        <div className="card-selections-card" id="DriveEasyProTag">
                                            <input type="checkbox" id="cardSelectionsRadio1" name="cardSelections" />
                                            <span></span>
                                            <div className="card-selections-header">
                                                <h4>DriveEasy Pro Tag</h4>
                                            </div>
                                            <div className="card-selections-content">
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Button btnStyle={ButtonStyles.LeftGreenButton} title="Submit" onClick={() => this.setState({ showSubmitPromptModal: true })} disabled={this.isSubmitButtonDisabled()} />
                        </div>
                    </div>
                    {submitted &&
                    <p className={formMessageColor}>{formMessage}</p>
                    }
                </div>
            </Fragment>
        );
    }
}