import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {
    DOWNLOAD_CSV,
    API_ERROR,
    IS_LOADING,
    FINISHED_LOADING,
    ActionWithPayload,
    KnownAction,
} from './Actions';
import TelematicsApi from '../api/telematicsApi';

export const actionCreators = {
    downloadCsv:
        (date: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
            dispatch({
                type: IS_LOADING,
            });
            TelematicsApi.downloadCsv(date)
                .then(result => {
                    const url = window.URL.createObjectURL(result);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'DriveEasyPro-UnderwritingReport-' + date + '.csv';
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                    dispatch({
                        type: DOWNLOAD_CSV,
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                })
                .catch(() => {
                    dispatch({
                        type: API_ERROR,
                        payload: "Error downloading an underwriting report",
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                });
        }
};

const unloadedState: any = {
    isLoading: false,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case DOWNLOAD_CSV:
                return {
                    ...state,
                    error: null,
                }
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                };
            default:
                return state;
        }
    };