import React, { Component, Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Driver } from '../../models/Driver';
import { Vehicle } from '../../models/Vehicle';
import * as PolicyStore from '../../store/PolicyStore';
import MessageAlert from '../common/MessageAlert';
import DriverManagement from './DriverManagement';
import PolicyInformation from './PolicyInformation';
import TelematicsDetailTabs from './TelematicsDetailTabs';
import TelematicsPolicySearch from './TelematicsPolicySearch';
import VehicleManagement from './VehicleManagement';
import { PolicyInfo, UpdatePolicyInfoRequest } from '../../models/PolicyInfo';
import { EnrollPolicyRequest } from '../../models/EnrollPolicyRequest';
import { excludeSpecialCharacters, getCurrentEnvironment } from '../../helpers/textHelpers';
import { Dropdown } from '../common/Dropdown';
import Button, { ButtonStyles } from '../common/Button';
import { PromptModal } from '../common/PromptModal';
import { AddPolicyInfoRequest } from '../../models/AddPolicyInfoRequest';
import DashcamPolicyInformation from '../dashcamPolicyManagement/DashcamPolicyInformation';
import DashcamVehicleManagement from '../dashcamPolicyManagement/DashcamVehicleManagement';
import DashcamDetailsTabs from '../dashcamPolicyManagement/DashcamDetailsTabs';
import CmtDriverManagement from '../cmtPolicyManagement/CmtDriverManagement';
import { LoggedInUser, UserRole } from '../../models/LoggedInUser';
import CmtVehicleManagement from '../cmtPolicyManagement/CmtVehicleManagement';
import { ReplaceMobileNumbersRequest } from '../../models/ReplaceMobileNumbersRequest';
import CmtTagReplacement from '../cmtPolicyManagement/CmtTagReplacement';
import { TagReplacementRequest } from '../../models/TagReplacementRequest';
import DashcamPartsReplacement from '../dashcamPolicyManagement/DashcamPartsReplacement';
import { Redirect } from 'react-router';


export class TelematicsPolicyManagment extends React.Component<any, any> {
    state = {
        searchPolicyNumber: excludeSpecialCharacters(localStorage.getItem("policyNumber") ?? ""),
        showResults: false,
        selectedSearchOption: 'Policy',
        tab: 1,
        showSubmitPromptModal: false,
        userComments: "",
        removeVehicle: false,
        showNewDashcamPolicyView: true,
        showIncidentsList: false
    }

    searchOptions = [
        'Policy',
        'DriverId/CustomerKey',
        'VehicleId',
    ];

    driveEasyProgramTypes = [
        'DriveEasyPro',
        'DriveEasyProDashcam',
        'DriveEasyProOBD2',
    ]

    componentDidMount = () => {
        const { searchPolicyNumber } = this.state;

        if (localStorage.getItem("policyNumber")) {
            localStorage.removeItem("policyNumber");
        }

        window.scrollTo(0, 0);
        this.disableAddPolicyButton(true);
        if (searchPolicyNumber) {
            this.searchPolicyWithoutCproa();
        }
    }

    onPolicyNumberChanged(e: any): void {
        this.setState({
            searchPolicyNumber: excludeSpecialCharacters(e.target.value),
            found: false,
            show: false,
        });
        this.disableAddPolicyButton(true);
    }

    onsearchOptionChanged(e: any): void {
        this.setState({
            searchPolicyNumber: '',
            found: false,
            show: false,
        });
        this.disableAddPolicyButton(true);
    }

    disableAddPolicyButton = (btnState: boolean) => {
        const btnAddPolicy = document.getElementById('btnAddPolicy') as HTMLInputElement;
        btnAddPolicy.disabled = btnState;
    }

    searchPolicy(): void {
        const { resetPolicy, searchPolicy } = this.props;
        const { searchPolicyNumber, selectedSearchOption } = this.state;
        if (searchPolicyNumber.trim() != "" && selectedSearchOption != "Please Select") {
            if (selectedSearchOption === "Policy") {
                this.disableAddPolicyButton(false);
            }
            resetPolicy();
            searchPolicy(searchPolicyNumber.trim(), selectedSearchOption === "DriverId/CustomerKey" ? "DriverId" : selectedSearchOption);
            this.setState({ showResults: true })
        }
    }

    searchPolicyWithoutCproa(): void {
        const { resetPolicy, searchPolicyWithoutCproa } = this.props;
        const { searchPolicyNumber, selectedSearchOption } = this.state;
        if (searchPolicyNumber.trim() != "" && selectedSearchOption != "Please Select") {
            if (selectedSearchOption === "Policy") {
                this.disableAddPolicyButton(false);
            }
            resetPolicy();
            searchPolicyWithoutCproa(searchPolicyNumber.trim(), selectedSearchOption === "DriverId/CustomerKey" ? "DriverId" : selectedSearchOption);
            this.setState({ showResults: true })
        }
    }

    handleEnter = (e: any): void => {
        if (e.key === "Enter") {
            this.searchPolicy()
        }
    };

    onSaveDriversChanges = (drivers: Driver[], userComments: string): void => {
        const { updateDriverWithPolicy, policy, resetError } = this.props;
        resetError();
        updateDriverWithPolicy({
            policyNumber: policy.policyNumber,
            drivers,
            userComments
        });
    }

    onSaveVehicleChanges = (vehicles: Vehicle[], userComments: string, removeVehicle: boolean): void => {
        const { updateVehicles, removeVehicles, resetError} = this.props;
        resetError();
        if (removeVehicle) {
            removeVehicles(vehicles, userComments);
        }
        else {
            updateVehicles(vehicles, userComments);
        }
    }

    onSubmitPolicyUnenrollment = (userComments: string): void => {
        const { updatePolicyEnrollment, policy, resetError } = this.props;
        resetError();
        updatePolicyEnrollment(policy.policyNumber, userComments);
    }

    onSubmitPolicyReEnrollment = (enrollPolicyRequest: EnrollPolicyRequest): void => {
        const { enrollPolicy, resetError } = this.props;
        resetError();
        enrollPolicy(enrollPolicyRequest);
    }

    updatePolicyInformation = (updatePolicyInfoRequest: UpdatePolicyInfoRequest): void => {
        const { updatePolicyInformation, resetError } = this.props;
        resetError();
        updatePolicyInformation(updatePolicyInfoRequest);
    }

    replaceMobileNumbers = (replaceMobileNumbersRequest: ReplaceMobileNumbersRequest, policy: PolicyInfo): void => {
        const { replaceMobileNumbers, resetError } = this.props;
        resetError();
        replaceMobileNumbers(replaceMobileNumbersRequest, policy);
    }

    orderTagReplacement = (tagReplacementRequest : TagReplacementRequest) : void => {
        const { tagReplacement, resetError } = this.props;
        resetError();
        tagReplacement(tagReplacementRequest);
    }

    onShowSubmitPromptModal = (): void => {
        const searchOption = document.getElementsByName('state')[0] as HTMLInputElement;
        const searchBy = document.getElementById('searchPolicyInput') as HTMLInputElement;
        if (searchBy.value.trim() != "" && searchOption.value === "Policy") {
            this.setState({
                showSubmitPromptModal: true
            });
        }
    }

    onSubmitAddPolicy = (): void => {
        const searchBy = document.getElementById('searchPolicyInput') as HTMLInputElement;
        const addPolicyInfoRequest: AddPolicyInfoRequest = {
            policyNumber: searchBy.value.trim(),
            userComments: this.state.userComments,
        };

        const { addPolicy, resetError } = this.props;
        this.setState({showSubmitPromptModal: false});
        resetError();
        addPolicy(addPolicyInfoRequest);
        this.setState({ showResults: true });
    }

    updateCommentsAddPolicy = (commentValue: string): void => {
        this.setState({ userComments: commentValue }, () => this.onSubmitAddPolicy());
    }

    showResults = (): ReactElement => {
        const { policy, error, loggedInUser } = this.props;
        const { showNewDashcamPolicyView, showIncidentsList } = this.state;

        if (policy) {
            this.disableAddPolicyButton(true);
            if(policy.programType && policy.programType[0] === this.driveEasyProgramTypes[2]) {
                return (<Redirect to="/dashboard" />);
            }
            if(policy.programType == null || policy.programType[0] === this.driveEasyProgramTypes[0] || !showNewDashcamPolicyView){
                if (loggedInUser.userRole === UserRole.Admin && !showNewDashcamPolicyView){
                    return (
                        <TelematicsDetailTabs
                            onTabChange={(tab) => this.setState({ tab })}
                            initialTab={this.state.tab}
                            policy={policy}
                        >
                            <PolicyInformation
                                policy={policy}
                                error={error}
                                updatePolicyInformation={this.updatePolicyInformation.bind(this)}
                                onSubmitPolicyUnenrollment={this.onSubmitPolicyUnenrollment}
                                onSubmitPolicyReEnrollment={this.onSubmitPolicyReEnrollment.bind(this)} />
                            <DriverManagement
                                headers={['First Name', 'Last Name', 'License Num.', 'State', 'Mobile Number', 'Effective Date', 'DC Driver Status', 'Enrolled']}
                                onSaveDriverChanges={this.onSaveDriversChanges}
                                error={error}
                                drivers={policy.drivers} />
                            <VehicleManagement
                                headers={['VIN', 'Make', 'Model', 'Effective Date', 'Year', 'Enrolled']}
                                onSaveVehicleChanges={this.onSaveVehicleChanges}
                                error={error}
                                vehicles={policy.vehicles} />
                            <CmtTagReplacement
                                error={error}
                                policy={policy}
                                drivers={policy.drivers} 
                                tagReplacement={this.orderTagReplacement.bind(this)}
                                updatePolicyInformation={this.updatePolicyInformation.bind(this)}/>
                        </TelematicsDetailTabs>
                    );
                }
                else {
                    return (
                        <TelematicsDetailTabs
                            onTabChange={(tab) => this.setState({ tab })}
                            initialTab={this.state.tab}
                            policy={policy}
                        >
                            <DashcamPolicyInformation
                                policy={policy}
                                error={error}
                                updatePolicyInformation={this.updatePolicyInformation.bind(this)}
                                onSubmitPolicyUnenrollment={this.onSubmitPolicyUnenrollment}
                                onSubmitPolicyReEnrollment={this.onSubmitPolicyReEnrollment.bind(this)} />
                            <CmtDriverManagement
                                headers={['First Name', 'Last Name', 'Mobile Number', 'App Setup?', 'Last Heartbeat', 'Missing Heartbeat Reason', 'Enrolled']}
                                error={error}
                                policy={policy}
                                drivers={policy.drivers}
                                replaceMobileNumbers={this.replaceMobileNumbers.bind(this)}/>
                            <CmtVehicleManagement
                                headers={['Enrollment Effective Date', 'Last App Trip Date', 'Last Tag-Only Trip Date', 'Total Tag-Only Trips', 'Tag Linked Date', 'Tag Mac Address']}
                                vehicles={policy.vehicles} />
                            <CmtTagReplacement
                                error={error}
                                policy={policy}
                                drivers={policy.drivers} 
                                tagReplacement={this.orderTagReplacement.bind(this)}
                                updatePolicyInformation={this.updatePolicyInformation.bind(this)}/>
                        </TelematicsDetailTabs>
                    );
                }
            } 
            else {
                if(policy.programType[0] === this.driveEasyProgramTypes[1] && loggedInUser.userRole === UserRole.Admin){
                return (
                    <div>
                        <div className="container">
                            <div className='row'>
                            <div className='col-lg-12'>
                                <DashcamDetailsTabs
                                    onTabChange={(tab:any) => this.setState({ tab })}
                                    initialTab={this.state.tab}
                                    policy={policy}
                                    loggedInUser={loggedInUser}
                                    >
                                    <DashcamPolicyInformation
                                        policy={policy}
                                        error={error}
                                        updatePolicyInformation={this.updatePolicyInformation.bind(this)}
                                        onSubmitPolicyUnenrollment={this.onSubmitPolicyUnenrollment}
                                        onSubmitPolicyReEnrollment={this.onSubmitPolicyReEnrollment.bind(this)} />
                                    <DashcamVehicleManagement
                                        headers={['Thumbnail', 'Event Type', "Link"]}
                                        vehicles={policy.vehicles} />
                                    <DashcamPartsReplacement
                                        error={error}
                                        policy={policy}
                                        drivers={policy.drivers}
                                        updatePolicyInformation={this.updatePolicyInformation.bind(this)}/>
                                </DashcamDetailsTabs>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else{
                return (
                    <div>
                        <div className="container">
                            <div className='row'>
                            <div className='col-lg-12'>
                                <DashcamDetailsTabs
                                    onTabChange={(tab: any) => this.setState({ tab })}
                                    initialTab={this.state.tab}
                                    policy={policy}
                                    loggedInUser={loggedInUser}
                                    >
                                    <DashcamPolicyInformation
                                        policy={policy}
                                        error={error}
                                        updatePolicyInformation={this.updatePolicyInformation.bind(this)}
                                        onSubmitPolicyUnenrollment={this.onSubmitPolicyUnenrollment}
                                        onSubmitPolicyReEnrollment={this.onSubmitPolicyReEnrollment.bind(this)} />
                                    <DashcamVehicleManagement
                                        headers={['Thumbnail', 'Event Type', "Link"]}
                                        vehicles={policy.vehicles} />
                                </DashcamDetailsTabs>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            }
        }
        else {
            if (error?.includes("The policy is cancelled.")) {
                this.disableAddPolicyButton(true);
                return (<MessageAlert title={"Policy Cancelled"} message={"Policy is cancelled"} />);
            }
            else {
                return (<MessageAlert title={"Not Found"} message={"Policy not found"} />);
            }
        }
    }

    render() {
        const { searchPolicyNumber, showResults, selectedSearchOption, showSubmitPromptModal } = this.state;
        const { isLoading, policy, loggedInUser } = this.props;
        return (            
            <Fragment>
                {showSubmitPromptModal &&
                    <PromptModal
                        title='Add Policy'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateCommentsAddPolicy.bind(this)}
                        no={() => this.setState({
                            showSubmitPromptModal: false,
                            cancelledChanges: true,
                            revertedChanges: false,
                            submitted: false,
                            formMessageColor: "red",
                            formMessage: "Changes have not been saved."
                            })}
                    />
                }
                <div className="container margin-top">
                    <div className="row" onKeyPress={this.handleEnter}>
                        <div className="col-sm-3">
                            <Dropdown
                                defaultValue={selectedSearchOption}
                                data={this.searchOptions}
                                onChange={(e) => {
                                    this.setState({ selectedSearchOption: e.target.value });
                                    this.onsearchOptionChanged(e)
                                    }
                                }
                            />
                        </div>
                        <TelematicsPolicySearch
                            policyNumber={searchPolicyNumber}
                            onChangePolicyNumber={this.onPolicyNumberChanged.bind(this)}
                            onSearch={this.searchPolicy.bind(this)} />
                        <div className="col-sm-2">
                            <Button
                                id= "btnAddPolicy"
                                btnStyle={ButtonStyles.RightGreenButton}
                                onClick={this.onShowSubmitPromptModal.bind(this)}
                                title="Add Policy"
                            />
                            {loggedInUser.userRole === UserRole.Admin &&
                            policy != null &&
                            <Button
                                title='Toggle New View'
                                btnStyle={ButtonStyles.TertiaryButton}
                                onClick={() => this.setState((prevState: { showNewDashcamPolicyView: any; }) => ({showNewDashcamPolicyView: !prevState.showNewDashcamPolicyView}))}
                            />
                            }
                        </div>
                    </div>
                    <div className="page-header; col-sm-14">
                        {showResults && !isLoading && this.showResults()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    error: state.drivers.error,
    policy: state.drivers.policy,
    isLoading: state.drivers.isLoading,
    loggedInUser: state.loggedInUser
});

export default connect(
    mapStateToProps,
    PolicyStore.actionCreators,
)(TelematicsPolicyManagment as any);
