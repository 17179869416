import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import * as JobStore from '../../store/JobStore';
import Button, { ButtonStyles } from '../common/Button';
import MessageAlert from '../common/MessageAlert';
import ButtonSwitch from '../common/ButtonSwitch';
import { PromptModal } from '../common/PromptModal';
import { getCurrentEnvironment } from '../../helpers/textHelpers';

export class Jobs extends PureComponent<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            submitRan: false,
            driverEnrollmentIncompleteStarted: false,
            vehicleDataNotReceivedStarted: false,
            driverHeartbeatNotReceivedStarted: false,
            tagOnlyTripsStarted: false,
            vehicleEnrollmentIncompleteStarted: false,
            vendorDriverSummaryStarted: false,
            vendorTagTripSummaryStarted: false,
            driverEnrollmentIncompleteIsChecked: false,
            vehicleDataNotRecievedIsChecked: false,
            driverHeartbeatNotReceivedStartedIsChecked: false,
            tagOnlyTripsStartedIsChecked: false,
            vehicleEnrollmentIncompleteStartedIsChecked: false,
            vendorDriverSummaryIsChecked: false,
            vendorTagTripSummaryIsChecked: false,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: ""
        }
    }

    driverEnrollmentIncomplete = () => {
        const { driverEnrollmentIncomplete } = this.props;
        if (!this.state.driverEnrollmentIncompleteStarted) {
            this.setState({ driverEnrollmentIncompleteStarted: true });
            driverEnrollmentIncomplete();
        }
    }

    vehicleDataNotReceived = () => {
        const { vehicleDataNotReceived } = this.props;
        if (!this.state.vehicleDataNotReceivedStarted) {
            this.setState({ vehicleDataNotReceivedStarted: true });
            vehicleDataNotReceived();
        }
    }

    driverHeartbeatNotReceived = () => {
        const { driverHeartbeatNotReceived } = this.props;
        if (!this.state.driverHeartbeatNotReceivedStarted) {
            this.setState({ driverHeartbeatNotReceivedStarted: true });
            driverHeartbeatNotReceived();
        }
    }

    tagOnlyTrips = () => {
        const { tagOnlyTrips } = this.props;
        if (!this.state.tagOnlyTripsStarted) {
            this.setState({ tagOnlyTripsStarted: true });
            tagOnlyTrips();
        }
    }

    vehicleEnrollmentIncomplete = () => {
        const { vehicleEnrollmentIncomplete } = this.props;
        if (!this.state.vehicleEnrollmentIncompleteStarted) {
            this.setState({ vehicleEnrollmentIncompleteStarted: true });
            vehicleEnrollmentIncomplete();
        }
    }

    vendorDriverSummary = () => {
        const { vendorDriverSummary } = this.props;
        if (!this.state.vendorDriverSummaryStarted) {
            this.setState({ vendorDriverSummaryStarted: true });
            vendorDriverSummary();
        }
    }

    vendorTagTripSummary = () => {
        const { vendorTagTripSummary } = this.props;
        if (!this.state.vendorTagTripSummaryStarted) {
            this.setState({ vendorTagTripSummary: true });
            vendorTagTripSummary();
        }
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue });
    }

    preSubmit = () => {
        const { resetError } = this.props;
        resetError();
        this.setState({ showSubmitPromptModal: true })
    }

    onSubmit = () => {
        const {
            driverEnrollmentIncompleteIsChecked, vehicleDataNotRecievedIsChecked, driverHeartbeatNotReceivedStartedIsChecked,
            tagOnlyTripsStartedIsChecked, vehicleEnrollmentIncompleteStartedIsChecked, vendorDriverSummaryIsChecked, vendorTagTripSummaryIsChecked } = this.state;
        const { driverEnrollmentIncomplete, vehicleDataNotReceived, driverHeartbeatNotReceived, tagOnlyTrips, vehicleEnrollmentIncomplete, vendorDriverSummary, vendorTagTripSummary } = this.props;

        if (driverEnrollmentIncompleteIsChecked) {
            driverEnrollmentIncomplete();
        }
        if (vehicleDataNotRecievedIsChecked) {
            vehicleDataNotReceived();
        }
        if (driverHeartbeatNotReceivedStartedIsChecked) {
            driverHeartbeatNotReceived();
        }
        if (tagOnlyTripsStartedIsChecked) {
            tagOnlyTrips();
        }
        if (vehicleEnrollmentIncompleteStartedIsChecked) {
            vehicleEnrollmentIncomplete();
        }
        if (vendorDriverSummaryIsChecked) {
            vendorDriverSummary();
        }
        if (vendorTagTripSummaryIsChecked) {
            vendorTagTripSummary();
        }

        this.setState({
            submitRan: true,
            driverEnrollmentIncompleteIsChecked: false,
            vehicleDataNotRecievedIsChecked: false,
            driverHeartbeatNotReceivedStartedIsChecked: false,
            tagOnlyTripsStartedIsChecked: false,
            vehicleEnrollmentIncompleteStartedIsChecked: false,
            vendorDriverSummaryIsChecked: false,
            vendorTagTripSummaryIsChecked: false,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        })
    }

    jobsError = () => {
        const { submitRan, formMessage } = this.state;
        const { error } = this.props;
        if (submitRan && error) {

            if (formMessage !== "Changes have not been committed due to a technical error.") {
                this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
            }

            return (
                <MessageAlert
                    title={"Technical Error"}
                    message={error}
                />
            )
        }
        else {
            return (
                <MessageAlert
                    title={"Caution"}
                    message={"Batch jobs process a lot of data and may take a few minutes when being used. Thank you for your patience."}
                />
            )
        }
    }

    renderResetButton = (): ReactElement | null => {
        if (this.state.driverEnrollmentIncompleteIsChecked ||
            this.state.vehicleDataNotRecievedIsChecked ||
            this.state.driverHeartbeatNotReceivedStartedIsChecked ||
            this.state.tagOnlyTripsStartedIsChecked ||
            this.state.vehicleEnrollmentIncompleteStartedIsChecked ||
            this.state.vendorDriverSummaryIsChecked ||
            this.state.vendorTagTripSummaryIsChecked) {
            return (
                <Button title="Reset"
                    btnStyle={ButtonStyles.RightBlueButton}
                    onClick={() => this.setState({
                        revertedChanges: true,
                        cancelledChanges: false,
                        submitRan: false,
                        formMessageColor: "red",
                        formMessage: "Changes have been removed and not applied.",
                        driverEnrollmentIncompleteIsChecked: false,
                        vehicleDataNotRecievedIsChecked: false,
                        driverHeartbeatNotReceivedStartedIsChecked: false,
                        tagOnlyTripsStartedIsChecked: false,
                        vehicleEnrollmentIncompleteStartedIsChecked: false,
                        vendorDriverSummaryIsChecked: false,
                        vendorTagTripSummaryIsChecked: false
                    })} />
            )
        }
        return null;
    };

    render(): ReactElement {
        const {
            submitRan,
            driverEnrollmentIncompleteIsChecked,
            vehicleDataNotRecievedIsChecked,
            driverHeartbeatNotReceivedStartedIsChecked,
            tagOnlyTripsStartedIsChecked,
            vehicleEnrollmentIncompleteStartedIsChecked,
            vendorDriverSummaryIsChecked,
            vendorTagTripSummaryIsChecked,
            showSubmitPromptModal,
            revertedChanges,
            cancelledChanges,
            formMessageColor,
            formMessage
        } = this.state;
        const { error } = this.props;
        return (
            <div>
                <section className="container margin-top">
                    {this.jobsError()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Update Policy Information'
                            infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                            yes={this.onSubmit}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                revertedChanges: false,
                                submitRan: false,
                                formMessageColor: "red",
                                formMessage: "Changes have not been saved."
                            })}
                        />
                    }
                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Driver Enrollment Incomplete</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={driverEnrollmentIncompleteIsChecked}
                                        onClick={() => this.setState({ driverEnrollmentIncompleteIsChecked: !driverEnrollmentIncompleteIsChecked})}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Driver Enrollment Incomplete</p>
                                <p> Observes telematic data to determine if there are any drivers that have failed to enroll properly, after set periods of time.
                                    While the specific numbers can change, examples would be "After 2 days, after 3 days, after 5 days, after 15 days.</p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Vehicle Data Not Recieved</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={vehicleDataNotRecievedIsChecked}
                                        onClick={() => this.setState({ vehicleDataNotRecievedIsChecked: !vehicleDataNotRecievedIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Vehicle Data Not Received</p>
                                <p> This job checks for vehicles that have not reported any telematics data for the certain number of days.
                                    Like the other jobs, standard examples are 2 days, 3 days, 5 days, and 15 days, though there may be more. </p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Driver Heartbeat Not Received</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={driverHeartbeatNotReceivedStartedIsChecked}
                                        onClick={() => this.setState({ driverHeartbeatNotReceivedStartedIsChecked: !driverHeartbeatNotReceivedStartedIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Driver Heartbeat Missing</p>
                                <p> This job checks for drivers that have not had a heart beat in a certain period of days, potentially meaning they haven't driven or they have forgotten to turn their phones on.
                                    Like the other jobs, standard examples are 2 days, 3 days, 5 days, and 15 days, though there may be more. </p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Tag Only Trips</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={tagOnlyTripsStartedIsChecked}
                                        onClick={() => this.setState({ tagOnlyTripsStartedIsChecked: !tagOnlyTripsStartedIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Tag Only Trips</p>
                                <p> This job checks for any reported trips that did not have a driver associated with them in a certain amount of days.
                                    This could be due to any number of issues: unenrolled drivers using the vehicle, Driver not having the application running on their phone, A technicall failure from our vendor, or more!
                                    Like the other jobs, standard examples are 2 days, 3 days, 5 days, and 15 days, though there may be more.</p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Vehicle Enrollment Incomplete</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={vehicleEnrollmentIncompleteStartedIsChecked}
                                        onClick={() => this.setState({ vehicleEnrollmentIncompleteStartedIsChecked: !vehicleEnrollmentIncompleteStartedIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Vehicle Enrollment Incomplete</p>
                                <p>This job checks for vehicles that have not been fully enrolled after a certain number of days.
                                    Like the other jobs, standard examples are 2 days, 3 days, 5 days, and 15 days, though there may be more.</p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Vendor Driver Summary</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={vendorDriverSummaryIsChecked}
                                        onClick={() => this.setState({ vendorDriverSummaryIsChecked: !vendorDriverSummaryIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Vendor Driver Summary</p>
                                <p>This job reads and processes CMT files from Azure and updates the vendor_cmt_driver_summary container in the database.</p>
                            </div>
                        </div>
                    </div>

                    <div className="cards-container content-card">
                        <div className="card section--denim">
                            <div className="card-header bg-gradient">
                                <div className="card-header-info">
                                    <div className="card-header-title">
                                        <h3>Vendor Tag Trip Summary</h3>
                                    </div>
                                </div>
                                <div className="actions">
                                    <ButtonSwitch
                                        isChecked={vendorTagTripSummaryIsChecked}
                                        onClick={() => this.setState({ vendorTagTripSummaryIsChecked: !vendorTagTripSummaryIsChecked })}
                                    />
                                </div>
                            </div>
                            <div className="card-content">
                                <p>Vendor Tag Trip Summary</p>
                                <p>This job reads and processes CMT files from Azure and updates the vendor_cmt_tag_trip_summary container in the database.</p>
                            </div>
                        </div>
                    </div>
                    {(revertedChanges || cancelledChanges || submitRan) &&
                        <p className={formMessageColor}>{formMessage}</p>
                    }
                    <Button
                        btnStyle={ButtonStyles.RightGreenButton}
                        onClick={this.preSubmit}
                        title="Submit"
                        disabled={submitRan && !error}
                    />
                    {this.renderResetButton()}
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    error: state.jobs.error
})

export default connect(mapStateToProps, JobStore.actionCreators)(Jobs);