import React, { Fragment } from 'react';

interface AccessBannerProps {
    title: string;
    details: string
}

export default ({ title, details }: AccessBannerProps) => (
    <Fragment>
        <section className="bg-gradient section--denim">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-header page-header--without-subheader page-header-bottom-border">
                            <div className="page-header-left">
                                <h2><span id="bannerTitle">{title}</span></h2>
                                <h4><span id="bannerTitle">{details}</span></h4>
                            </div>
                            <div className="page-header-right">
                                <span className="geico-icon geico-icon-informational icon-profile pull-right"> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
);
