import React, { Component } from 'react';

export interface StateDropdownProps {
	value: string;
	id: string;
	errorMessage?: string;
	onChange: (event: any) => void;
	disabled?: boolean;
}

const licenseStateAbbreviations = [
	'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',
	'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
	'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NJ', 'NM', 'NY',
	'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN',
	'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];


export class StateDropdown extends Component<StateDropdownProps> {

    setErrorClass() {
        if(this.props.errorMessage) {
            return 'form-field--error';
        }
        return '';
    }

	renderErrorMessage() {
        if(this.props.errorMessage){
            return (
                <span id='formMessage' className='form-message' style={{ display: 'block' }}>{this.props.errorMessage}</span>
            );
        }
	}
	
	render() {
		const { onChange, value, id } = this.props;
		return (
			<div className='row'>
				<div className='col-sm-12'>
					<div className={'form-field ' + this.setErrorClass()}>
						<label htmlFor='demo-select-1' className='text'>State</label>
						<div className='select-box'>
							<select id={id} name='state' defaultValue={value} onChange={onChange} disabled={this.props.disabled}>
								<option>State</option>
								{licenseStateAbbreviations.map((st) => (
									<option key={st}>{st}</option>))}
							</select>
							{this.renderErrorMessage()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
