import { setCustomerDashboardPanel } from "../../helpers/navigationHelpers";

export class AllVehiclesButton{
    map: any;
    container: any;

    onAdd(map: any){
        this.map = map;
        this.container = document.createElement('button');
        this.container.className = 'all-vehicles-button';
        this.container.textContent = 'View All Vehicles';
        this.container.addEventListener('contextmenu', (e: any) => e.preventDefault());
        this.container.addEventListener('click', () =>  setCustomerDashboardPanel("vehicles"));
        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}