import React, { PureComponent, ReactElement, Fragment, ChangeEvent } from 'react';
import { Driver } from '../../models/Driver';
import Input, { InputSize } from '../common/Input';
import * as PolicyStore from '../../store/PolicyStore';
import { formatPhoneNumber, excludeSpecialCharacters, getCurrentEnvironment } from '../../helpers/textHelpers';
import Button, { ButtonStyles } from '../common/Button';
import { PromptModal } from '../common/PromptModal';
import { PolicyInfo } from '../../models/PolicyInfo';
import { ReplaceMobileNumbersRequest, UserMobileNumbersRequest } from '../../models/ReplaceMobileNumbersRequest';
import { connect } from 'react-redux';
import { ReplaceMobileNumbersResponse, UserMobileNumbersUpdateStatus } from '../../models/ReplaceMobileNumbersResponse';

export interface CmtDriverManagementProps {
    error: string;
    headers?: string[];
    policy: PolicyInfo;
    drivers: Driver[];
    replaceMobileNumbers: (replaceMobileNumbersRequest: ReplaceMobileNumbersRequest, policy: PolicyInfo) => void;
}

interface CmtDriverTableState {
    showErrorModal: boolean;
    userComments: string;
    selectedDriver: Driver;
    selectedMobile: string;
    drivers: Driver[];
    modifiedDrivers: { driverId: string, mobileNumber: string }[];
    submitted: boolean,
    revertedChanges: boolean,
    formMessage: string,
    formMessageColor: string,
    removeAndUpdateDrivers: string[];
    showSubmitPromptModal: boolean;
    showReplacePromptModal: boolean;
    updateMobileNumberResponse?: UserMobileNumbersUpdateStatus[];
}

export default class CmtDriversTable extends PureComponent<
    CmtDriverManagementProps, CmtDriverTableState> {
    constructor(props: CmtDriverManagementProps) {
        super(props);
        this.state = {
            showErrorModal: false,
            drivers: this.props.drivers || [],
            selectedDriver: {} as Driver,
            selectedMobile: '',
            userComments: '',
            modifiedDrivers: [],
            submitted: false,
            revertedChanges: false,
            formMessage: "",
            formMessageColor: "",
            removeAndUpdateDrivers: [],
            showSubmitPromptModal: false,
            showReplacePromptModal: false,
            updateMobileNumberResponse: {} as UserMobileNumbersUpdateStatus[]
        };
    }

    componentDidMount() {
        const searchOption = document.getElementsByName('state')[0] as HTMLInputElement;
        const searchBy = document.getElementById('searchPolicyInput') as HTMLInputElement;
        const driverRow = document.getElementById(searchBy.value) as HTMLInputElement;
        if (searchOption.value === "DriverId/CustomerKey") {
            driverRow.style.fontWeight = '1000';
            driverRow.style.backgroundImage = 'linear-gradient(to right, rgba(255,255,0,0), rgba(255,255,0,1))';
        }
    }

    componentDidUpdate () {
        const { error } = this.props;
        const { updateMobileNumberResponse } = this.state;
        if (error && !error.includes('There was an issue') && !updateMobileNumberResponse?.length) {
            this.setState({
                showReplacePromptModal: true,
                updateMobileNumberResponse: error as unknown as UserMobileNumbersUpdateStatus[],
                formMessage: "Mobile numbers have been updated successfully",
                formMessageColor: "green",
                submitted: true,
            })
        }
        else if (error && !error.includes('There was an issue') && updateMobileNumberResponse?.length){
            this.setState({
                formMessage: "",
                formMessageColor: "",
                submitted: true,
            })
        }
        else if (!error && !updateMobileNumberResponse?.length && this.state.submitted == true) {
            this.setState({
                showReplacePromptModal: false,
                updateMobileNumberResponse: error as unknown as UserMobileNumbersUpdateStatus[],
                formMessage: "Mobile numbers have been updated successfully",
                formMessageColor: "green",
                submitted: true,
            })
        }
    } 

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { selectedDriver, modifiedDrivers } = this.state
        const originalMobileNumber = selectedDriver.mobileNumber
        selectedDriver.mobileNumber = formatPhoneNumber(e.target.value, '')
        if (!modifiedDrivers.find(driver => driver.driverId === selectedDriver.id)) {
            modifiedDrivers.push({ driverId: selectedDriver.id || '', mobileNumber: originalMobileNumber })
        }

        this.setState({
            selectedMobile: selectedDriver.mobileNumber,
            selectedDriver: selectedDriver,
        })
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue }, () => this.submitChanges());
    }

    updateCommentsForDelete = (commentValue: string): void => {
        this.setState({ userComments: commentValue }, () => this.submitDeleteAndReplace());
    }

    submitChanges = (): void => {
        const { policy, replaceMobileNumbers, error } = this.props;
        const { drivers, modifiedDrivers } = this.state;

        let driversList: UserMobileNumbersRequest[] = [];
        modifiedDrivers.forEach(modifiedDriver => {
            let user: UserMobileNumbersRequest = {
                customerKey: modifiedDriver.driverId,
                mobileNumber: drivers.filter(driver => driver.id === modifiedDriver.driverId)[0].mobileNumber
            }
            driversList.push(user)
        })
        let request: ReplaceMobileNumbersRequest = {
            policyNumber: policy.policyNumber,
            users : driversList,
            replaceMobileNumbers: false
        }

        replaceMobileNumbers(request, policy);

        this.setState({
            showSubmitPromptModal: false,
            modifiedDrivers: [],
            submitted: true,
            selectedDriver: {} as Driver,
        });
    }

    submitDeleteAndReplace = (): void => {
        const { policy, replaceMobileNumbers } = this.props;
        const { drivers, updateMobileNumberResponse } = this.state;

        let driversList: UserMobileNumbersRequest[] = [];
        (updateMobileNumberResponse || []).forEach(responseDriver => {
            let user: UserMobileNumbersRequest = {
                customerKey: responseDriver.customerKey,
                mobileNumber: drivers.filter(driver => driver.id === responseDriver.customerKey)[0].mobileNumber
            }
            driversList.push(user)
        })
        let request: ReplaceMobileNumbersRequest = {
            policyNumber: policy.policyNumber,
            users : driversList,
            replaceMobileNumbers: true
        }

        replaceMobileNumbers(request, policy);

        this.setState({
            showReplacePromptModal: false,
            formMessage: "Mobile numbers have been updated successfully",
            formMessageColor: "green",
            submitted: true,
            selectedDriver: {} as Driver,
        });
    }

    modifyDriverRecord = (driver: Driver) => {
        const { selectedDriver } = this.state;
        if (selectedDriver.id === driver.id) {
            this.setState({
                selectedDriver: {} as Driver,
            });
        }
        else {
            this.setState({
                selectedDriver: driver,
            });
        }
    }

    renderDriverRow = (driver: Driver) => {
        const { headers } = this.props;
        const { selectedDriver } = this.state;

        return (
            <>
                <td data-title={headers?.[0]}>
                    {driver.firstName}
                </td>
                <td data-title={headers?.[1]}>
                    {driver.lastName}
                </td>
                {(selectedDriver.id === driver.id) &&
                    <td data-title={headers?.[2]}>
                        <Input
                            id="mobileNumber"
                            value={formatPhoneNumber(driver.mobileNumber, '')}
                            name="PhoneNumberTextBox"
                            size={13}
                            maxLength={14}
                            onChange={this.onChange}
                        />
                    </td>
                }
                {!(selectedDriver.id === driver.id) &&
                    <td data-title={headers?.[2]}>
                        {formatPhoneNumber(driver.mobileNumber, '')}
                    </td>
                }
                <td data-title={headers?.[3]}>
                    {driver.startRecordingDate == null ? 'No' : 'Yes'}
                </td>
                <td data-title={headers?.[4]}>
                    {driver.lastHeardDate}
                </td>
                <td data-title={headers?.[5]}>
                    {driver.causeMissedHeartbeat ?? 'N/A'}
                </td>
                <td data-title={headers?.[6]}>
                    {driver.isEnrolled ? 'Yes' : 'No'}
                </td>
                <th className="col--edit-control">
                    <div><span className="geico-icon geico-icon--small geico-icon--actionable icon-edit" onClick={() => this.modifyDriverRecord(driver)}></span></div>
                </th>
                </>);
    }

    reset = (): void => {
        const { modifiedDrivers } = this.state;

        modifiedDrivers.forEach(modifiedDriver => {
            let driver = this.state.drivers.find(driver => driver.id === modifiedDriver.driverId)
            if (driver) {
                driver.mobileNumber = modifiedDriver.mobileNumber
            }
        })

        this.setState({
            selectedDriver: {} as Driver,
            selectedMobile: '',
            modifiedDrivers: [],
            revertedChanges: true,
            formMessageColor: "red",
            formMessage: "Changes have not been saved."
        })
    }

    renderSubmitButton = (): ReactElement | null => {
        const { modifiedDrivers } = this.state;
        if (modifiedDrivers.length > 0) {
            return <Button title="Submit" btnStyle={ButtonStyles.RightGreenButton} onClick={() => this.setState({showSubmitPromptModal: true})} />;
        }
        return null;
    };

    renderResetButton = (): ReactElement | null => {
        const { modifiedDrivers } = this.state;

        if (modifiedDrivers.length > 0) {{
            return (
                <Button title="Reset"
                    btnStyle={ButtonStyles.RightBlueButton}
                    onClick={() => this.reset()}/>
            )}}
        return null;
    };

    closeModal = (): void => {
        this.setState({ showSubmitPromptModal: false, showReplacePromptModal: false })
    }

    render(): ReactElement {
        const { headers, policy, error } = this.props;
        const { drivers, showSubmitPromptModal, showReplacePromptModal, formMessage, formMessageColor, submitted, revertedChanges } = this.state;

        return (
            <Fragment>
                <div>
                {showSubmitPromptModal &&
                    <PromptModal
                        title='Update Driver Information'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateComments}
                        no={() => this.setState({
                            showSubmitPromptModal: false,
                        })}
                    />
                }
                {showReplacePromptModal &&
                    <PromptModal
                        title='Delete and Replace Mobile Numbers'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateCommentsForDelete}
                        no={() => this.setState({
                            showReplacePromptModal: false,
                        })}
                    />
                }
                <div id='management-table' className='data-table'>
                    <table className="table">
                        <thead>
                            <tr className="management-table-header">
                                {
                                    headers && headers.map((header) => (
                                        <th key={header}>
                                            {header}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                drivers && drivers.map((driver) => (
                                    <tr>
                                        {this.renderDriverRow(driver)}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {(revertedChanges || submitted) &&
                    <p className={formMessageColor}>{formMessage}</p>
                }
                <section className="col-lg-12">
                    <div className="col-lg-6">
                    </div>
                    <div className="col-lg-3 mt-5">
                        {this.renderResetButton()}
                    </div>
                    <div className="col-lg-3 mt-5">
                        {this.renderSubmitButton()}
                    </div>
                </section>
                </div>
            </Fragment>
        );
    }
}