import React, { ReactElement, ChangeEvent, PureComponent } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import * as DeleteCmtDataStore from '../../store/DeleteCmtDataStore';
import { connect } from 'react-redux';
import { excludeSpecialCharacters, getCurrentEnvironment } from '../../helpers/textHelpers';
import InfoModal from '../common/InfoModal';
import { PromptModal } from '../common/PromptModal';

export class DeleteCmtData extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            changesSubmitted: false,
            cancelledChanges: false,
            removalDate: "",
            showSubmitPromptModal: false,
            formMessage: "",
            formMessageColor: "",
            userComments: '',
            successfulRun: false
        };
    }

    submitChanges = () => {
        const { removalDate } = this.state;
        const { sendDeleteRequest } = this.props; 
        sendDeleteRequest(removalDate);

        this.setState({
            changesSubmitted: true,
            showSubmitPromptModal: false,
            successfulRun: false
        });
    }

    updateRemovalDate = (removalDate: string) => {
        this.setState({ removalDate: removalDate })
    }

    displayResponse = () => {
        const { changesSubmitted } = this.state;
        const { error, returnMessage } = this.props;

        if (error && changesSubmitted) {
            this.setState({
                formMessageColor: "red",
                formMessage: error
            })
            return (
                <InfoModal
                    title='Technical Error'
                    infoText={error}
                    onClose={this.closeModal}
                />
            )
        }

        if (returnMessage && changesSubmitted) {
            this.setState({
                successfulRun: true,
                formMessageColor: "green",
                formMessage: returnMessage
            })
        }
    }

    closeModal = (): void => {
       this.setState({
            changesSubmitted: false
        })
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue });
    }

    render(): ReactElement {
        const { error } = this.props;
        const { showSubmitPromptModal, formMessageColor, formMessage, cancelledChanges, successfulRun, returnMessage, changesSubmitted } = this.state;
        return (
            <>
                <div className="container margin-top">
                    {this.displayResponse()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Delete CMT Data'
                            infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                            yes={this.submitChanges}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                changesSubmitted: false,
                                returnMessage: "",
                                successfulRun: false,
                                error: null,
                                formMessageColor: "red",
                                formMessage: "Job has not been run."
                            })}
                        />
                    }
                    <div className="col-sm-5">
                        <input
                            id="deleteCmtDataInput"
                            className=""
                            style={{ width: '92%' }}
                            type="text"
                            placeholder="Removal Date (mm/dd/yyyy)"
                            onChange={x => this.updateRemovalDate(x.target.value) }
                        />
                    </div>
                    <div className="col-sm-2">
                        <Button
                            btnStyle={ButtonStyles.RightBlueButton}
                            onClick={() => this.setState({ showSubmitPromptModal: true })}
                            title="Submit"
                        />
                    </div> 
                    {(error || cancelledChanges || successfulRun) &&
                        <p className={formMessageColor}>{formMessage}</p>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    returnMessage: state.dcds.returnMessage,
    error: state.dcds.error
})

export default connect(mapStateToProps, DeleteCmtDataStore.actionCreators)(DeleteCmtData);