import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import Policy, { Driver } from '../models/Driver';
import {
    FOUND_POLICY,
    UPDATE_DRIVER,
    API_ERROR,
    API_NO_POLICY_FOUND,
    IS_LOADING,
    FINISHED_LOADING,
    ActionWithPayload,
    KnownAction,
    UPDATE_POLICY_INFORMATION,
    RESET_ERROR,
    MOBILE_NUMBER_UPDATE_SUCCESS,
    MOBILE_NUMBER_UPDATE_FAILURE,
    SEND_REPLACEMENT,
    LAST_KNOWN_LOCATION_SUCCESS
} from './Actions';
import TelematicsApi from '../api/telematicsApi';
import { Vehicle } from '../models/Vehicle';
import { PolicyInfo, UpdatePolicyInfoRequest } from '../models/PolicyInfo';
import { EnrollPolicyRequest } from '../models/EnrollPolicyRequest';
import { AddPolicyInfoRequest } from '../models/AddPolicyInfoRequest';
import { DashcamData } from '../models/DashcamData';
import { DashcamShipment, DashcamShipmentOrderRequest } from '../models/DashcamShipment';
import { VendorInformation } from '../models/VendorInformation';
import { TagData, TagDataVehicle } from '../models/TagData';
import { ReplaceMobileNumbersRequest } from '../models/ReplaceMobileNumbersRequest';
import { ReplaceMobileNumbersResponse } from '../models/ReplaceMobileNumbersResponse';
import { TagReplacementRequest } from '../models/TagReplacementRequest';
import { Clips } from '../models/Clip';
import * as LastKnownLocation from '../models/LastKnownLocationResponse';
import * as DashboardStore from './DashboardStore';

export interface PolicyState {
    foundPolicy: any;
    show: boolean;
    found: boolean;
    loading: boolean
}

export interface DriverState {
    driver: any;
}

export const actionCreators = {
    enrollPolicy:
        (enrollPolicyRequest: EnrollPolicyRequest): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
            dispatch({
                type: IS_LOADING,
            });
            TelematicsApi.enrollPolicy(enrollPolicyRequest)
                .then(() => {
                    dispatch(actionCreators.searchPolicy(enrollPolicyRequest.policyNumber, 'Policy'));
                })
                .catch(() => {
                    dispatch({
                        type: API_ERROR,
                        payload: "There was an issue enrolling a policy. Please try again later."
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                });
        },
    addPolicy:
        (addPolicyInfoRequest: AddPolicyInfoRequest): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
            dispatch({
                type: IS_LOADING,
            });
            TelematicsApi.addPolicyInfoFromCproa(addPolicyInfoRequest)
                .then((res: Response) => {
                    if (res.status === 206) {
                        dispatch({
                            type: API_ERROR,
                            payload: "There was an issue adding a policy. The policy is cancelled."
                        });
                        dispatch({
                            type: FINISHED_LOADING,
                        });
                    }
                    else {
                        dispatch(actionCreators.searchPolicy(addPolicyInfoRequest.policyNumber, 'Policy'));
                    }
                })
                .catch(() => {
                    dispatch({
                        type: API_ERROR,
                        payload: "There was an issue adding a policy. Please try again later."
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                });
        },
    updateDrivers: (drivers: Driver[]): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        let policyNumber = getState().drivers.policy.policyNumber;
        drivers.forEach((driver) => {
            driver.telematicsVueRequest = true;
        })
        actionCreators.updateDriverWithPolicy({
            policyNumber,
            drivers,
            userId: " ",
            userComments: ""
        })
    },
    updateDriverWithPolicy: (policy: Policy): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        policy.drivers?.forEach((driver: any) => {
            if (driver.mobileNumber?.length == 15) {
                driver.mobileNumber = driver.mobileNumber.substring(1);
            }
        })

        dispatch({
            type: IS_LOADING,
        });
        TelematicsApi.upsertDriver(policy)
            .then(() => {
                dispatch(actionCreators.searchPolicy(policy.policyNumber as string, 'Policy'));
            })
            .catch(err => {
                dispatch({
                    type: API_ERROR,
                    payload: "There was an issue updating drivers. Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    updateVehicles: (vehicles: Vehicle[], userComments?: string): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING
        })
        vehicles.forEach((vehicle) => {
            if (vehicle.year.length === 5) {
                vehicle.year = vehicle.year.substring(1);
            }

            vehicle.telematicsVueRequest = true;
        })
        let policyNumber = getState().drivers.policy.policyNumber;
        TelematicsApi.upsertVehicle({
            policyNumber,
            vehicles,
            userComments
        })
            .then(() => {
                dispatch(actionCreators.searchPolicy(policyNumber, 'Policy'));
            })
            .catch(() => {
                dispatch({
                    type: API_ERROR,
                    payload: "There was an issue updating vehicles. Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING,
                });
            });
    },
    removeVehicles: (vehicles: Vehicle[], userComments?: string): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING
        })
        vehicles.forEach((vehicle) => {
            vehicle.telematicsVueRequest = true;
            if (vehicle.year.length === 5) {
                vehicle.year = vehicle.year.substring(1);
            }
        })
        let policyNumber = getState().drivers.policy.policyNumber;
        TelematicsApi.removeVehicle({
            policyNumber,
            vehicles,
            userComments
        })
            .then(() => {
                dispatch(actionCreators.searchPolicy(policyNumber, 'Policy'));
            })
            .catch(() => {
                dispatch({
                    type: API_ERROR,
                    payload: "There was an issue removing vehicles. Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING,
                });
            });
    },
    updatePolicyEnrollment:
        (policyNumber: string, userComments: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
            dispatch({
                type: IS_LOADING,
            });
            TelematicsApi.upsertPolicyEnrollment({ policyNumber, userComments })
                .then(() => {
                    dispatch(actionCreators.searchPolicy(policyNumber, 'Policy'));
                })
                .catch(() => {
                    dispatch({
                        type: API_ERROR,
                        payload: "There was an issue updating the enrollment for policy number: " + policyNumber + ". Please try again later."
                    });
                    dispatch({
                        type: FINISHED_LOADING,
                    });
                });
        },
    resetPolicy: ():
        AppThunkAction<ActionWithPayload> => (dispatch, getState): void => {
            dispatch({
                type: FOUND_POLICY,
                payload: null
            });
        },
    resetError: ():
        AppThunkAction<ActionWithPayload> => (dispatch, getState): void => {
            dispatch({
                type: RESET_ERROR,
                payload: null
            });
        },
    searchPolicy: (policyNumber: string, searchOption: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.getPolicyByPolicyNumber(policyNumber, searchOption)
            .then(result => {
                if (result.programType !== null && result.programType[0] === "DriveEasyProDashcam") {
                    dispatch(actionCreators.dashcamSearchPolicy(result));
                }
                else if (result.programType !== null && result.programType[0] === "DriveEasyProOBD2") {
                    dispatch(DashboardStore.actionCreators.getLastKnownLocationByPolicyNumber(result))
                    dispatch(DashboardStore.actionCreators.getObdShipmentStatus(policyNumber));
                }
                else {
                    dispatch(actionCreators.getVendorInformation(result));
                }
            })
            .catch(() => {
                dispatch({
                    type: API_NO_POLICY_FOUND,
                    payload: "There was an issue finding policy number: " + policyNumber + ". Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    dashcamSearchPolicy: (policy: PolicyInfo): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.getDashcamTelematicsData(policy.policyNumber)
            .then((result: DashcamData) => {
                if (result.cameras) {
                    result.cameras.forEach((cam) => {
                        TelematicsApi.getClips(cam.cameraId || '')
                            .then((clips: Clips) => {
                            policy.vehicles?.forEach((veh) => {
                                if (veh.id === cam.vehicleId) {
                                    veh.cameraId = cam.cameraId;
                                    veh.vehicleAdapter = cam.vehicleAdapter;
                                    veh.cameraRegistrationDate = cam.cameraRegistrationDate;
                                    veh.clips = clips
                                }
                            })
                        })
                    })
                }
                TelematicsApi.requestDashcamShippingData(policy.policyNumber)
                    .then((shippingResult: DashcamShipment[]) => {
                        shippingResult?.forEach((shipment) => {
                            shipment.vehicles?.forEach((shipmentVehicle) => {
                                policy.vehicles?.forEach((policyVehicle) => {
                                    if (policyVehicle.id === shipmentVehicle.vehicleId) {
                                        policyVehicle.shippingStatus = shipment.shippingStatus;
                                    }
                                })
                            })
                        })
                    })
                dispatch(actionCreators.getVendorInformation(policy));
            })
            .catch(() => {
                dispatch({
                    type: API_NO_POLICY_FOUND,
                    payload: "There was an issue finding policy number: " + policy.policyNumber + ". Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    searchPolicyWithoutCproa: (policyNumber: string, searchOption: string): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.getPolicyWithoutCproaByPolicyNumber(policyNumber, searchOption)
            .then(result => {
                if (result.programType !== null && result.programType[0] === "DriveEasyProDashcam") {
                    dispatch(actionCreators.dashcamSearchPolicy(result));
                }
                else if (result.programType !== null && result.programType[0] === "DriveEasyProOBD2") {
                    dispatch(DashboardStore.actionCreators.getLastKnownLocationByPolicyNumber(result));
                    dispatch(DashboardStore.actionCreators.getObdShipmentStatus(policyNumber));
                }
                else {
                    dispatch(actionCreators.getVendorInformation(result));
                }
            })
            .catch(() => {
                dispatch({
                    type: API_NO_POLICY_FOUND,
                    payload: "There was an issue finding policy number: " + policyNumber + ". Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    updatePolicyInformation: (policy: UpdatePolicyInfoRequest): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.updatePolicyInformation(policy)
            .then(() => {
                dispatch(actionCreators.searchPolicy(policy.policyNumber, 'Policy'));
                dispatch({
                    type: FINISHED_LOADING,
                });
            })
            .catch(() => {
                dispatch({
                    type: API_ERROR,
                    payload: "There was an issue updating the policy's information. Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    getVendorInformation: (policy: PolicyInfo): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        TelematicsApi.getVendorInformation(policy.policyNumber)
            .then((vendorInformation: VendorInformation) => {
                vendorInformation.users?.forEach((user) => {
                    policy.drivers?.forEach((driver) => {
                        if (driver.id === user.customerKey) {
                            driver.startRecordingDate = user.startRecordingDate;
                            driver.causeMissedHeartbeat = user.causeMissedHeartbeat;
                            driver.lastHeardDate = user.lastHeardDate;
                        }
                    })
                })
                vendorInformation.vehicles?.forEach((vehicle) => {
                    policy.vehicles?.forEach((veh) => {
                        if (veh.id === vehicle.id) {
                            veh.tripInfo = vehicle.tripInfo;
                            veh.vehicleAdapter = vehicle.vehicleAdapter;
                            veh.cameraRegistrationDate = vehicle.cameraRegistrationDate;
                            veh.tagLinkDate = vehicle.tagLinkDate;
                            veh.tagMacAddress = vehicle.tagMacAddress;
                        }
                    })
                })
                dispatch(actionCreators.getVehicleTagData(policy));
            })
            .catch(() => {
                dispatch({
                    type: FOUND_POLICY,
                    payload: policy
                })
                dispatch({
                    type: FINISHED_LOADING,
                });
            });
    },
    getVehicleTagData: (policy: PolicyInfo): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.getVehicleTagData(policy.policyNumber)
            .then((tagData: TagDataVehicle) => {
                tagData?.vehicles?.forEach((tagVehicle) => {
                    policy.vehicles?.forEach((vehicle) => {
                        if (tagVehicle.vehicleId === vehicle.id) {
                            vehicle.tagData = tagVehicle;
                        }
                    })
                })
                dispatch({
                    type: FOUND_POLICY,
                    payload: policy
                })
                dispatch({
                    type: FINISHED_LOADING,
                });
            })
            .catch(() => {
                dispatch({
                    type: FOUND_POLICY,
                    payload: policy
                })
                dispatch({
                    type: FINISHED_LOADING,
                });
            });
    },
    replaceMobileNumbers: (replaceMobileNumbersRequest: ReplaceMobileNumbersRequest, policy: PolicyInfo): AppThunkAction<ActionWithPayload> => (dispatch: any, getState: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.replaceMobileNumbers(replaceMobileNumbersRequest)
            .then((response: ReplaceMobileNumbersResponse) => {
                if (!replaceMobileNumbersRequest.replaceMobileNumbers) {
                    response.users = response.users.filter(user => user.result.includes("Update Failed"));
                    if(response.users.length > 0) {
                        dispatch({
                            type: MOBILE_NUMBER_UPDATE_FAILURE,
                            payload: response.users
                        })
                    }
                    else {
                        dispatch({
                            type: MOBILE_NUMBER_UPDATE_SUCCESS,
                            payload: policy
                        })
                    }
                }
                else {
                    dispatch({
                        type: MOBILE_NUMBER_UPDATE_SUCCESS,
                        payload: policy
                    })
                }
                dispatch({
                    type: FINISHED_LOADING,
                });
            })
            .catch(() => {
                dispatch({
                    type: API_ERROR,
                    payload: "There was an issue updating the policy's information. Please try again later."
                });
                dispatch({
                    type: FINISHED_LOADING
                });
            });
    },
    tagReplacement : (tagReplacementRequest : TagReplacementRequest) => (dispatch: any) => {
        dispatch({
            type: IS_LOADING,
            payload: true,
        });
        TelematicsApi.tagReplacement(tagReplacementRequest)
        .then(() => {
            dispatch({
                type: SEND_REPLACEMENT,
            });
            dispatch({
                type: FINISHED_LOADING,
            });
        })
        .catch(() => {
            dispatch({
                type: API_ERROR,
                payload: "There was an issue ordering a tag replacement. Please try again later."
            });
            dispatch({
                type: FINISHED_LOADING
            });
        });
    }
};

const unloadedState: any = {
    policy: null,
    isLoading: false,
    error: null
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case MOBILE_NUMBER_UPDATE_SUCCESS:
            case UPDATE_POLICY_INFORMATION:
            case FOUND_POLICY:
                return {
                    ...state,
                    policy: action.payload,
                    error: null
                };
            case API_NO_POLICY_FOUND:
            case RESET_ERROR:
            case SEND_REPLACEMENT:
            case UPDATE_DRIVER:
                return {
                    ...state,
                    error: null
                };
            case MOBILE_NUMBER_UPDATE_FAILURE:
            case API_ERROR:
                return {
                    ...state,
                    error: action.payload,
                };
            default:
                return state;
        }
    };
