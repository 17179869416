import React, { ReactElement, ChangeEvent, PureComponent } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import * as BatchJobSettingsStore from '../../store/BatchJobSettingsStore';
import { connect } from 'react-redux';
import { excludeSpecialCharacters, getCurrentEnvironment } from '../../helpers/textHelpers';
import InfoModal from '../common/InfoModal';
import { PromptModal } from '../common/PromptModal';

const headers = ['Batch Job', 'IsEnabled']

export class BatchJobSettings extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            batchJobSettingsChanged: [],
            isDirty: false,
            changesSubmitted: false,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: '',
        };
    }

    componentDidMount() {
        this.props.getBatchJobs();
    }

    componentDidUpdate() {
        const { batchJobSettingsChanged } = this.state;
        const { batchJobs } = this.props;
        if (batchJobSettingsChanged.length == 0 && batchJobs) {
            this.setState({
                changesSubmitted: false,
                batchJobSettingsChanged: batchJobs.sort(this.settingSorter),
            })
        }
    }

    reset = () => {
        const { batchJobs } = this.props;
        if (batchJobs) {
            this.setState({
                batchJobSettingsChanged: batchJobs.sort(this.settingSorter),
                isDirty: false,
                changesSubmitted: false,
                revertedChanges: true,
                cancelledChanges: false,
                formMessageColor: "red",
                formMessage: "Changes have been removed and not applied.",
            })
        }
    }

    onChange = (batchJob: string, e: ChangeEvent<HTMLSelectElement>) => {
        const { batchJobSettingsChanged } = this.state;
        const changedSetting = batchJobSettingsChanged.filter((x: any) => x.jobName == batchJob)[0];
        const filteredSettings = batchJobSettingsChanged.filter((x: any) => x.jobName != batchJob);

        const newSettings = [...filteredSettings, {
            ...changedSetting,
            jobEnabled: excludeSpecialCharacters(e.target.value),
            isDirty: true
        }];

        newSettings.sort(this.settingSorter);

        this.setState({
            batchJobSettingsChanged: newSettings,
            isDirty: true,
            changesSubmitted: false
        });
    }

    settingSorter(first: any, second: any) {
        if (first.jobName > second.jobName) {
            return 1;
        }
        return -1;
    }

    isDirtyStyleObject(state: any): React.CSSProperties | undefined {
        if (state.isDirty) {
            return {
                fontWeight: state.isDirty ? "bold" : "normal",
                fontSize: "16px"
            }
        }
    }

    submitChanges = () => {
        const { batchJobSettingsChanged } = this.state;
        const { updateBatchJobs } = this.props; 

        const filterBatchJobs = batchJobSettingsChanged.filter((x: any) => x.isDirty);
        const cleanBatchJobs = batchJobSettingsChanged.filter((x: any) => !x.isDirty);

        var updatedBatchJobs = filterBatchJobs.map((x: any) => ({ ...x, isDirty: false }));
        const newBatchJobs = [...cleanBatchJobs, ...updatedBatchJobs];
        newBatchJobs.sort(this.settingSorter);

        updateBatchJobs(filterBatchJobs);

        this.setState({
            batchJobSettingsChanged: newBatchJobs,
            isDirty: false,
            changesSubmitted: true,
            showSubmitPromptModal: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    displayError = () => {
        const { changesSubmitted, formMessage, revertedChanges } = this.state;
        const { error } = this.props;

        if (error && formMessage !== "Changes have not been committed due to a technical error." && !revertedChanges && !changesSubmitted) {
            this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
        }

        if (error && changesSubmitted) {
            return (
                <InfoModal
                    title='Technical Error'
                    infoText={error}
                    onClose={this.closeErrorModal}
                />
            )
        }
    }

    closeErrorModal = (): void => {
        const { batchJobs } = this.props;
        if (batchJobs) {
            this.setState({
                batchJobSettingsChanged: batchJobs.sort(this.settingSorter),
                isDirty: false,
                changesSubmitted: false
            })
        }
    }

    updateComments = (commentValue: string): void => {
        this.setState({ userComments: commentValue });
    }

    render(): ReactElement {
        const { error } = this.props;
        const { batchJobSettingsChanged, isDirty, changesSubmitted, showSubmitPromptModal, cancelledChanges, revertedChanges, formMessageColor, formMessage } = this.state;
        return (
            <>
                <div className="container margin-top">
                    {this.displayError()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Update Batch Job Settings'
                            infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                            yes={this.submitChanges}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                revertedChanges: false,
                                submitted: false,
                                formMessageColor: "red",
                                formMessage: "Changes have not been saved."
                            })}
                        />
                    }
                    <table className="table table--non-sortable table--editable-non-sortable">
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th key={header} style={{ textAlign: 'left' }}>{header}</th>
                                ))}
                                <th>
                                    <Button title="Reset" onClick={this.reset} btnStyle={ButtonStyles.BlueCenteredTableButton} />
                                </th>
                                <th>
                                    {isDirty && !changesSubmitted &&
                                        <Button title="Submit Changes" onClick={() => this.setState({ showSubmitPromptModal: true })} btnStyle={ButtonStyles.BlueCenteredTableButton} />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {batchJobSettingsChanged && batchJobSettingsChanged.map((batchJob: any) => (
                                <tr key={batchJob.jobName}>
                                    <td style={this.isDirtyStyleObject(batchJob)} data-title={batchJob.jobName}>{batchJob.jobName}</td>
                                    <td data-title='Enabled'>
                                        <select
                                            id={batchJob.jobName}
                                            value={batchJob.jobEnabled}
                                            onChange={(e) => this.onChange(batchJob.jobName, e)}
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </select>
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(revertedChanges || cancelledChanges || changesSubmitted || error) &&
                        <p className={formMessageColor}>{formMessage}</p>
                    }
                    {isDirty && !changesSubmitted &&
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button title="Submit Changes" onClick={() => this.setState({ showSubmitPromptModal: true })} btnStyle={ButtonStyles.RightBlueButton} />
                        </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    batchJobs: state.bjss.batchJobs,
    error: state.bjss.error
})

export default connect(mapStateToProps, BatchJobSettingsStore.actionCreators)(BatchJobSettings);