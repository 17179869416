import React, { PureComponent, ReactElement } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import * as BulkPolicyUploadStore from '../../store/BulkPolicyUploadStore';
import { connect } from 'react-redux';
import MessageAlert from '../common/MessageAlert';
import {getCurrentEnvironment } from '../../helpers/textHelpers';
import TextAreaInput from '../common/TextAreaInput';
import { PromptModal } from '../common/PromptModal';

export class BulkPolicyUpload extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showSubmitPromptModal: false,
            policyStatus: "",
            userComments: "",
            disabled: true
        };
    }

    componentDidMount(): void {
        this.disableAddPolicyBtn(true);
    }

    reset = () => {
        const { resetProps } = this.props;

        this.setState({
            showSubmitPromptModal: false,
            Error:  ""
        })
        resetProps();
    }

    resetTextArea = () => {
        window.location.reload();
    }

    addPolicy = (userComments: string) => {
        this.reset();
        const { policyUpload } = this.props;
        const newPolicyNumbers = this.slicePolicies();

        policyUpload(newPolicyNumbers, userComments);
        this.disableAddPolicyBtn(true);
    }

    checkPolicies = () => {
        const policies = this.slicePolicies();
        const regex = /^\d{10}$/;

        if (policies.length !== 0 && policies.every((value: string) => regex.test(value))) {
            this.disableAddPolicyBtn(false);
        }
        else {
            this.disableAddPolicyBtn(true);
        }
    }

    disableAddPolicyBtn = (disabled: boolean) => {
        this.setState({ disabled: disabled });
    }

    slicePolicies = () => {
        const comp = this.getTextAreaElement().value.trim();
        return comp
                .replace(/[^\d]/g, '')
                .match(/.{1,10}/g)
                ?.slice(0, 20) ?? [];
    }

    addPolicyErrorMessage = () => {
        const { error } = this.props;
        if (error) {
            window.scrollTo(0, 0);
            return (<MessageAlert
                title={"Technical Error"}
                message={error} />)
        }
    }

    addPolicySuccessMessage = () => {
        const { response } = this.props;
        let status = "<p>";
        if (response) {
            for (var i = 0; i < response.length; i++) {
                let policyColor = response[i].policyStatus.toLowerCase().includes('success') ? 'green' : response[i].policyStatus.toLowerCase().includes('exist') ? 'blue' : 'red';
                status += "<b>Policy:</b> " + response[i].policyNumber;
                status += " <b>Status:</b><span style='color: " + policyColor + "'> ";
                status += response[i].policyStatus + "</span><br/>";
            }
        }
        status += '</p>';
        this.setState({ policyStatus: status });
    }

    getTextAreaElement = () => {
        return document.getElementById('policyTextArea') as HTMLInputElement;
    }

    render(): ReactElement {
        const {
            showSubmitPromptModal,
            policyStatus,
            disabled
        } = this.state;
        return (
            <div>
                <div className="container margin-top" onKeyUpCapture={this.checkPolicies}>
                    {this.addPolicyErrorMessage()}
                    {this.addPolicySuccessMessage()}
                    {showSubmitPromptModal &&
                        <PromptModal
                            title='Bulk Policy Add'
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.addPolicy.bind(this)}
                            no={() => this.setState({
                                showSubmitPromptModal: false,
                                cancelledChanges: true,
                                revertedChanges: false,
                                submitted: false,
                                formMessageColor: "red",
                                formMessage: "Changes have not been saved."
                            })}
                        />
                    }
                    <p><i className="ml-4">Please enter policy numbers for bulk insert. Max 20 policies at a time.</i></p>
                    <div className="col-sm-5">
                        <TextAreaInput/>
                    </div>
                    <div className="col-sm-6">
                        <Button title="CLEAR"
                            btnStyle={ButtonStyles.RightBlueButton}
                            onClick={this.resetTextArea}
                        />
                        <Button
                            id="btnAddPolicy"
                            btnStyle={ButtonStyles.RightGreenButton}
                            onClick={() => this.setState({ showSubmitPromptModal: true })}
                            disabled={disabled}
                            title="ADD POLICIES"
                        />
                    </div>
                    <div className="col-sm-7" dangerouslySetInnerHTML={{ __html: policyStatus }}></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    loggedInUser: state.loggedInUser,
    error: state.bulkPolicyUpload.error,
    response: state.bulkPolicyUpload.bulkPolicyUploadResponse
})

export default connect(mapStateToProps, BulkPolicyUploadStore.actionCreators)(BulkPolicyUpload);