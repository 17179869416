import React, { Component, ReactElement } from 'react';
import Button, { ButtonStyles } from './Button';

interface InfoModalProps {
    title?: string;
    infoText?: string;
    onClose(): void;
}

export default class InfoModal extends Component<InfoModalProps> {

    onCancel = (): void => {
        const { onClose } = this.props;
        onClose();
    }

    render(): ReactElement {
        const { title, infoText } = this.props;
        return (
            <>
                <div id="infoModal" className="modal modal--show" style={{ display: 'block' }}>
                    <div className="modal-container modal--dialog-box">
                        <span tabIndex={0} className="tab-filler"></span>
                        <h2 className="modal-headline">{title}</h2>
                        <button className="btn-close icon-close" aria-label="Close" type="button" onClick={this.onCancel} />
                        <div className="modal-content">
                            <p>{infoText}</p>
                            <div className="modal--call-to-action-bar">
                                <Button btnStyle={ButtonStyles.RightGreenButton} onClick={this.onCancel} title="Continue" />
                            </div>
                        </div>
                        <span tabIndex={0} className="tab-filler"></span>
                    </div>
                </div>
            </>
        );
    }
}