import React from 'react';
import { connect } from 'react-redux';
import * as GDKTab from '@gdk/tabs'
import * as PolicyStore from '../../store/PolicyStore';
import { Driver } from '../../models/Driver';
import { Vehicle } from '../../models/Vehicle';
import { PolicyInfo } from '../../models/PolicyInfo';
import { LoggedInUser, UserRole } from '../../models/LoggedInUser';

export interface PolicySearchResult {
    policyInfo: PolicyInfo;
    drivers: Array<Driver>;
    vehicles: Array<Vehicle>;
}

export interface DashcamDetailsTabsProps {
    policy: PolicyInfo;
    onTabChange: (tab: number) => void;
    initialTab: number;
    loggedInUser: LoggedInUser;
}


export class DashcamDetailsTabs extends React.Component<DashcamDetailsTabsProps>{

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchOption = document.getElementsByName('state')[0] as HTMLInputElement;
        let initialTabNum: number = 1;

        switch (searchOption.value) {
            case "DriverId/CustomerKey":
                initialTabNum = 2;
                break;
            case "VehicleId":
                initialTabNum = 3;
                break;
        }

        const commercialTabs = new GDKTab.GdkTabs({
            content: '#commercialTelematicsTabs',
            initialTabOpen: initialTabNum,
            tabClicked: function () {

            },
        });

        const { policy, loggedInUser } = this.props

        const comp = document.getElementById('divPolicyStatus');
        if (comp) {
            if (policy.currentPolicyStatus?.includes('$') === true) {
                comp.innerText = comp.innerText.replace('$','');
                comp.style.color = 'green';
            }
            else {
                comp.style.color = 'red';
            }
        }

    }

    renderTabs = () => {
        const { loggedInUser } = this.props;
        return(
        <div
            className="tabs-container"
        >
            <a
                id="tab-PolicyInfo"
                className="tab"
                href="/#"
                role="tab"
                aria-controls="panelPolicyInfo"
                onClick={() => this.props.onTabChange(1)}
            >
                <span>Policy Information</span>
            </a>
            <a
                id="tab-Vehicle"
                className="tab"
                href="/#"
                role="tab"
                aria-controls="panelVehicle"
                onClick={() => this.props.onTabChange(2)}
            >
                <span>Vehicles</span>
            </a>
            {loggedInUser.userRole === UserRole.Admin && 
            <a
                id="tab-DashcamPartsReplacement"
                className="tab"
                href="/#"
                role="tab"
                aria-controls="panelDashcamPartsReplacement"
                onClick={() => this.props.onTabChange(4)}
            >
                <span>Dashcam Part(s) Replacement</span>
            </a> 
            }
            <div id="divPolicyNumber" className="text-align-center-highlight">{this.renderPolicyNumber()}</div>
            <div id="divPolicyStatus" className="text-align-right">{this.renderPolicyStatus()}</div>
        </div>
        );
    };

    renderPanels = () => {
        return React.Children.map(this.props.children, child => {
            return (
                <div
                    id="panelPolicyInfo"
                    className="panel"
                    role="tabpanel"
                    aria-labelledby="tab-PolicyInfo"
                >
                    <div
                        className="panel-content"
                    >
                        {child}
                    </div>
                </div>
            )
        });
    }

    renderPolicyNumber = () => {
        const { policy } = this.props
        return (
            <span> {"Policy No. " + policy.policyNumber} </span>
        );
    }

    renderPolicyStatus = () => {
        const { policy } = this.props        
        return (
            <span> {policy.currentPolicyStatus} </span>
        );
    }

    render() {
        return (
            <div
                className="container"
            >
                <div className="row">
                    <div
                        className="col-sm-14"
                    >
                        <div
                            id="commercialTelematicsTabs"
                            className="tabs"
                        >
                            {/* Tab Headers */}
                            {this.renderTabs()}
                            {/* Tab Content Panels */}
                            {this.renderPanels()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    loggedInUser: state.loggedInUser
});

export default connect(
    mapStateToProps,
    PolicyStore.actionCreators,
)(DashcamDetailsTabs as any);