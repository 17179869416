import { Account } from "msal"
import { IdToken } from "msal/lib-commonjs/IdToken"
import { StringDict } from "msal/lib-commonjs/MsalTypes"
import { getCookie } from "typescript-cookie"

export class LoggedInUser {
    isAuthenticated: boolean
    account: any
    userRole: UserRole
    idToken: any
    hasError: boolean
    errorMessage: any

    constructor(isDemo: boolean) {
        let strDict: StringDict;
        this.isAuthenticated = isDemo;
        this.account = isDemo ?
            {
                accountIdentifier: "",
                homeAccountIdentifier: "",
                userName: "DemoUser",
                name: "DemoUser",
                idToken: "",
                idTokenClaims: "",
                sid: "",
                environment: ""
            }
            : null;
        this.userRole = isDemo ? UserRole.Admin : UserRole.None;
        this.idToken = null;
        this.hasError = false;
        this.errorMessage = null;
    }

    static CreateLoggedInUser(): LoggedInUser {
        let loggedInUser = new LoggedInUser(false);
        const getEssidCookie = (): boolean | null => {
        const value = localStorage.getItem("returnUrl");
        if(value === null ||value === '' ||value?.includes("clientId"))        
        {
            return false;
        }
        else 
        {
            return true;
        }
    };
        let cookieValue = getEssidCookie() ?? "";
        if (cookieValue != "") {
            loggedInUser.isAuthenticated = true;
            loggedInUser.idToken = new IdToken("Customer");
            loggedInUser.idToken.roles = ["Customer"];
            loggedInUser.userRole = UserRole.Customer;            
        }
        
        return loggedInUser;
    }

    static GetUserRole(account: any): UserRole {
        let roles = account?.idToken?.roles;
        if (roles) {
            if (roles.indexOf('Admin') >= 0) {
                return UserRole.Admin;
            }
            if (roles.indexOf('ServiceUser') >= 0) {
                return UserRole.ServiceUser;
            }
            if (roles.indexOf('Agent') >= 0) {
                return UserRole.Agent;
            }
            if (roles.indexOf('Customer') >= 0) {
                return UserRole.Customer;
            }
        }
        return UserRole.None;
    }

    static GetRoleName(user: LoggedInUser): string {
        if (user) {
            switch (user.userRole) {
                case UserRole.Admin:
                    {
                        return "Admin"
                    }
                case UserRole.Agent:
                    {
                        return "Agent"
                    }
                case UserRole.ServiceUser:
                    {
                        return "Service User"
                    }
                case UserRole.Customer:
                    {
                        return "Customer"
                    }
                default:
                    {
                        return "No Access"
                    }
            }
        }
        return "No Access";
    }

    GetRole(): UserRole {
        if (this.account && this.account.roles) {
            if (this.account.roles.indexOf('Admin') >= 0) {
                return UserRole.Admin;
            }
            if (this.account.roles.indexOf('ServiceUser') >= 0) {
                return UserRole.ServiceUser;
            }
            if (this.account.roles.indexOf('Agent') >= 0) {
                return UserRole.Admin;
            }
            if (this.account.roles.indexOf('Customer') >= 0) {
                return UserRole.Customer;
            }
        }
        return UserRole.None;
    }
}

export enum UserRole {
    Admin,
    Agent,
    None,
    ServiceUser,
    Customer
}