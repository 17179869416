import React, { PureComponent, ReactElement } from 'react';
import { getCurrentEnvironment } from '../../helpers/textHelpers';
import { Vehicle } from '../../models/Vehicle';
import Button, { ButtonStyles } from '../common/Button';
import InfoModal from '../common/InfoModal';
import { PromptModal } from '../common/PromptModal';
import VehicleModalPopup, { PopUpMode } from './VehiclePopupModal';

interface VehicleManagementProps {
    error: string;
    vehicles: Array<Vehicle>;
    headers: Array<string>;
    onSaveVehicleChanges: (vehicles: Vehicle[], userComments: string, removeVehicle: boolean) => void;
}

interface VehicleManagementState {
    showVehiclePopup: boolean;
    showErrorModal: boolean;
    selectedVehicle: Vehicle;
    vehicles: Vehicle[];
    popUpMode: PopUpMode;
    vehiclesToSave: Vehicle[];
    showSubmitPromptModal: boolean;
    submitted: boolean;
    revertedChanges: boolean;
    cancelledChanges: boolean;
    formMessage: string;
    formMessageColor: string;
    userComments: string;
    removeVehicle: boolean;
}

export default class VehicleManagement
    extends PureComponent<VehicleManagementProps, VehicleManagementState> {
    constructor(props: VehicleManagementProps) {
        super(props);
        this.state = {
            showVehiclePopup: false,
            showErrorModal: false,
            popUpMode: PopUpMode.Add,
            vehicles: this.props.vehicles,
            selectedVehicle: {} as Vehicle,
            vehiclesToSave: [],
            showSubmitPromptModal: false,
            submitted: false,
            revertedChanges: false,
            cancelledChanges: false,
            formMessage: "",
            formMessageColor: "",
            userComments: "",
            removeVehicle: false
        };
    }

    componentDidMount() {
        const searchOption = document.getElementsByName('state')[0] as HTMLInputElement;
        const searchBy = document.getElementById('searchPolicyInput') as HTMLInputElement;
        const vehicleRow = document.getElementById(searchBy.value) as HTMLInputElement;
        if (searchOption.value === "VehicleId") {
            vehicleRow.style.fontWeight = '1000';
            vehicleRow.style.backgroundImage = 'linear-gradient(to right, rgba(255,255,0,0), rgba(255,255,0,1))';
        }
    }

    componentDidUpdate = (prevProps: any) => {
        const { vehicles } = this.props
        if (prevProps.vehicles !== vehicles) {
            this.setState({
                vehicles: vehicles,
            });
        }
    }

    addVehicleManually = (): void => {
        this.setState({
            showVehiclePopup: true,
            popUpMode: PopUpMode.AddManually,
            selectedVehicle: {} as Vehicle,
        });
    };

    addVehicle = (vehicle: Vehicle): void => {
        this.setState({
            showVehiclePopup: true,
            popUpMode: PopUpMode.Add,
            selectedVehicle: vehicle,
        });
    };

    editVehicle = (vehicle: Vehicle): void => {
        this.setState({
            showVehiclePopup: true,
            popUpMode: PopUpMode.Update,
            selectedVehicle: vehicle,
        });
    };

    removeVehicles = (vehicle: Vehicle): void => {
        let changedVehiclesToSave = this.getVehiclesListWithVehicleChanges(vehicle);
        this.setState({
            removeVehicle: true,
            popUpMode: PopUpMode.Remove,
            selectedVehicle: vehicle,
            vehiclesToSave: changedVehiclesToSave,
            showSubmitPromptModal: true,
        });
    };

    OnVehiclePopupClose = (): void => {
        this.setState({
            showVehiclePopup: false,
            selectedVehicle: {} as Vehicle,
        });
    }

    onSaveVehicle = (vehicle: Vehicle): void => {
        let vehicles = this.updateCurrentVehicleInVehicleList(vehicle);
        let changedVehiclesToSave = this.getVehiclesListWithVehicleChanges(vehicle);
        this.setState({
            vehiclesToSave: changedVehiclesToSave,
            vehicles,
        });
        this.OnVehiclePopupClose();
    }

    updateCurrentVehicleInVehicleList(vehicle: Vehicle) {
        const { vehicles } = this.state;
        if (vehicle.id == null || vehicle.id == "")
            return vehicles;

        let modifiedVehicleIndex = 0;
        let nonModifiedVehicles = vehicles.filter((v, i) => {
            if (v.id == vehicle.id) {
                modifiedVehicleIndex = i;
            }
            return v.id != vehicle.id;
        });
        nonModifiedVehicles.splice(modifiedVehicleIndex, 0, vehicle);
        return nonModifiedVehicles;
    }

    getVehiclesListWithVehicleChanges(vehicle: Vehicle) {
        const { vehiclesToSave } = this.state;
        const newVehicleList = vehiclesToSave.slice();

        if (vehicle.id == null || vehicle.id == '') {
            return newVehicleList.concat(vehicle);
        }

        let indexOfOldData = 0;
        const oldVehicleData = newVehicleList.find((v, i) => {
            indexOfOldData = i;
            return v.id == vehicle.id;
        });
        if (oldVehicleData) {
            newVehicleList[indexOfOldData] = vehicle;
        } else {
            newVehicleList.push(vehicle); // should be error?
        }
        return newVehicleList;
    }

    updateCurrentVehicle = (vehicle: Vehicle): void => {
        const { vehicles } = this.state;
        let modifiedVehicleIndex = 0;
        var nonModifiedVehicles = vehicles.filter((v, i) => {
            if (v.id == vehicle.id) {
                modifiedVehicleIndex = i;
            }
            return v.id != vehicle.id;
        });
        nonModifiedVehicles.splice(modifiedVehicleIndex, 0, vehicle);
        this.setState({
            vehicles: nonModifiedVehicles,
        });
    }

    onSaveVehicleChanges = (): void => {
        const { onSaveVehicleChanges } = this.props;
        const { vehiclesToSave } = this.state;
        onSaveVehicleChanges(vehiclesToSave, this.state.userComments, this.state.removeVehicle);
        this.setState({
            vehiclesToSave: [],
            showErrorModal: true,
            showSubmitPromptModal: false,
            submitted: true,
            revertedChanges: false,
            cancelledChanges: false,
            formMessageColor: "green",
            formMessage: "Changes have been committed and saved."
        });
    }

    updateCommentsSaveVehicleChanges = (commentValue: string): void => {
        this.setState({ userComments: commentValue }, () => this.onSaveVehicleChanges());
    }

    renderPopup = (): ReactElement | null => {
        const { popUpMode, selectedVehicle, showVehiclePopup } = this.state;
        return (
            showVehiclePopup
                ? (
                    <VehicleModalPopup
                        vehicle={selectedVehicle}
                        onClose={this.OnVehiclePopupClose}
                        onSave={this.onSaveVehicle}
                        mode={popUpMode}
                    />
                )
                : null
        );
    }

    renderVehicleRow = (vehicle: Vehicle): ReactElement => {
        return (
            <tr className="management-table-data" id={vehicle.id}>
                <td className="management-table-data" data-title={vehicle.vin}>{"****" + vehicle.vin?.slice(-6)}</td>
                <td className="management-table-data" data-title={vehicle.make}>{vehicle.make}</td>
                <td className="management-table-data" data-title={vehicle.model}>{vehicle.model}</td>
                <td className="management-table-data" data-title={vehicle.enrollmentEffectiveDate}>{vehicle.enrollmentEffectiveDate}</td>
                <td data-title={vehicle.year}>{vehicle.year.length === 5 ? vehicle.year.substring(1) : vehicle.year}</td>
                <td data-title={vehicle.isEnrolled}>{vehicle.isEnrolled ? 'Yes' : 'No'}</td>
                <td data-title="Edit">
                    {
                        this.isMissingVehicle(vehicle)
                    }
                </td>
            </tr>);
    }

    renderAddedVehicles = (): ReactElement | null => {
        const { vehiclesToSave } = this.state;
        const hasNewVehicles = vehiclesToSave.filter(t => t.id == '' || t.id == null).length > 0;
        if (vehiclesToSave.length > 0 && hasNewVehicles)
            return (
                <div>
                    <h3>Added</h3>
                    <table className="table table--non-sortable table--editable-non-sortable">
                        <tbody>
                            {
                                vehiclesToSave.map((vehicle) => {
                                    if (!vehicle.id)
                                        return this.renderVehicleRow(vehicle)
                                })
                            }
                        </tbody>
                    </table>
                </div>
            );
        else {
            return null;
        }
    }

    renderResetButton = (): ReactElement | null => {
        if (this.state.vehiclesToSave.length > 0) {
            return (
                <Button title="Reset"
                    btnStyle={ButtonStyles.RightBlueButton}
                    onClick={() => this.setState({
                        revertedChanges: true,
                        cancelledChanges: false,
                        submitted: false,
                        formMessageColor: "red",
                        formMessage: "Changes have been removed and not applied.",
                        vehiclesToSave: [],
                        vehicles: this.props.vehicles
                    })} />
            )
        }
        return null;
    };

    renderSaveChangesButton() {
        if (this.state.vehiclesToSave.length > 0)
            return <Button btnStyle={ButtonStyles.RightGreenButton} onClick={() => this.setState({ showSubmitPromptModal: true })} title="Save Vehicle Changes" key="Add" />
    }

    private isMissingVehicle(vehicle: Vehicle): React.ReactNode {
        switch (vehicle.year.charAt(0)) {
            case 'H':
                return <Button
                    btnStyle={ButtonStyles.GreenTableButton}
                    onClick={() => this.addVehicle(vehicle)}
                    title="Add Vehicle" />;
            case 'Y':
                return <Button
                    btnStyle={ButtonStyles.RedTableButton}
                    onClick={() => this.removeVehicles(vehicle)}
                    title="Remove" />;
            case 'X': return;
            default: return <Button
                btnStyle={ButtonStyles.BlueTableButton}
                onClick={() => this.editVehicle(vehicle)}
                title="Edit" />;
        }
    }

    render(): ReactElement {
        const { headers, error } = this.props;
        const { vehicles, showErrorModal, showSubmitPromptModal, formMessage } = this.state;

        const closeErrorModal = (): void => {
            if (formMessage !== "Changes have not been committed due to a technical error.") {
                this.setState({ formMessageColor: "red", formMessage: "Changes have not been committed due to a technical error." })
            }

            this.setState({ showErrorModal: false, vehicles: this.props.vehicles })
        }

        return (
            <div>
                {this.renderPopup()}
                {showErrorModal && error &&
                    <InfoModal
                        title='Technical Error'
                        infoText={error}
                        onClose={closeErrorModal}
                    />
                }
                {showSubmitPromptModal &&
                    <PromptModal
                    title={PopUpMode.Remove ? 'Remove Vehicle' : 'Update Vehicles'}
                        infoText={'You are about to submit this change directly to ' + getCurrentEnvironment() + ', are you sure you wish to continue?'}
                        yes={this.updateCommentsSaveVehicleChanges}
                        no={() => this.setState({
                            showSubmitPromptModal: false,
                            cancelledChanges: true,
                            revertedChanges: false,
                            submitted: false,
                            formMessageColor: "red",
                            formMessage: "Changes have not been saved."
                        })}
                    />
                }
                <table className="table table--non-sortable table--editable-non-sortable">
                    <thead>
                        <tr className="management-table-header">
                            {headers && headers.map(header => (
                                <th key={header} className="management-table-header">{header}</th>
                            ))}
                            <td style={{ borderTop: 0 }}>
                                <Button
                                    btnStyle={ButtonStyles.GreenTableButton}
                                    onClick={this.addVehicleManually}
                                    title="Add Vehicle Manually"
                                    key="AddManually"
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vehicles ?
                                vehicles.map((vehicle) => (
                                    this.renderVehicleRow(vehicle)
                                )) : null
                        }
                    </tbody>
                </table>
                {this.renderAddedVehicles()}
                {(this.state.revertedChanges || this.state.cancelledChanges || this.state.submitted) &&
                    <p className={this.state.formMessageColor}>{this.state.formMessage}</p>
                }
                {this.renderSaveChangesButton()}
                {this.renderResetButton()}
            </div>
        );
    }
}
