import React, { useState } from 'react';

const TextAreaInput = () => {
    const [policyNumbers, setPolicyNumbers] = useState<string[]>(Array(20).fill(''));
    const [height, setHeight] = useState('auto');
    const [rowCount, setRowCount] = useState(6);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        const newVal = value;

        const newPolicyNumbers = value
            .replace(/[^\d]/g, '')
            .match(/.{1,10}/g)
            ?.slice(0, 20) ?? [];

        if (newVal.replace(/[^\d]/g, '').length % 10 == 0 && newVal.replace(/[^\d]/g, '').length < 200) {
            newPolicyNumbers[newPolicyNumbers.length] = '';
        }

        setPolicyNumbers(newPolicyNumbers);

        let newRows = 6;
        if (newPolicyNumbers.length == 20) {
            newRows = 20;
        }
        else if (newPolicyNumbers.length > 4) {
            newRows = newPolicyNumbers.length + 1;
        }

        setRowCount(newRows);
        setHeight('auto');
    };
    
    return (
        <div>
            <h2>Policy Numbers</h2>
            <textarea
                autoFocus
                id="policyTextArea"
                rows={rowCount}
                cols={10}
                style={{height}}
                value={policyNumbers.join('\n')}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default TextAreaInput;