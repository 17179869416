import React, { Component, ReactElement } from 'react';
import {GdkFooter} from '@gdk/footer';

export default class PrimaryFooterNavigation extends Component {

    componentDidMount(): void {
        var footer = new GdkFooter({
            content: "#primary-footer"
         });
    }

    render(): ReactElement {
        return (
            <footer id="primary-footer">
                <div className="footer-logo icon-geico"></div>

                <div className="footer-links">
                    <ul className="footer-list">
                        <li><a href="https://media.geico.com/legal/privacy_policy.htm" target="_blank"><u>Privacy</u></a></li>
                        <li><a href="https://www.geico.com/privacy/" target="_blank"><u>Personal Data Request &amp; Notice</u></a></li>
                        <li><a href="https://www.geico.com/legal/" target="_blank"><u>Legal &amp; Security</u></a></li>
                    </ul>
                </div>

                <div id="footer-copyright">GEICO © 1996-<span id="footerDate"></span></div>
            </footer>
        );
    }
}