import { Action } from "redux";

export const SEARCH_POLICY: string = 'SEARCH_POlICY';
export const FOUND_POLICY: string = 'FOUND_POLICY';
export const NOT_FOUND_POLICY: string = 'NOT_FOUND_POLICY';
export const IS_LOADING: string = 'IS_LOADING';
export const FINISHED_LOADING: string = 'FINISHED_LOADING';
export const BLOB_UPLOAD_SUCCESS: string = 'BLOB_UPLOAD_SUCCESS';
export const BULK_POLICY_UPLOAD_SUCCESS: string = 'BULK_POLICY_UPLOAD_SUCCESS';
export const RESET_PROPS: string = 'RESET_PROPS';
export const RESET_ERROR: string = 'RESET_ERROR';
export const UPDATE_DRIVER: string = 'UPDATE_DRIVER';
export const DOWNLOAD_CSV: string = 'DOWNLOAD_CSV';
export const UPDATE_POLICY_INFORMATION: string = 'UPDATE_POLICY_INFORMATION';
export const API_ERROR: string = 'API_ERROR';
export const BATCH_JOB: string = 'BATCH_JOB';
export const API_NO_POLICY_FOUND = 'API_NO_POLICY_FOUND';
export const UCMSTATES_RETRIEVAL_SUCCESS = 'UCMSTATES_RETRIEVAL_SUCCESS';
export const UCMSTATES_RETRIEVAL_START = 'UCMSTATES_RETRIEVAL_START';
export const UCMSTATES_UPDATE_VOLUNTARY_STATES = ' UCMSTATES_UPDATE_VOLUNTARY_STATES';
export const UCMSTATES_GET_STATE_HISTORY = ' UCMSTATES_GET_STATE_HISTORY';
export const BATCHJOBSETTINGS_RETRIEVAL_SUCCESS = 'BATCHJOBSETTINGS_RETRIEVAL_SUCCESS';
export const BATCHJOBSETTINGS_UPDATE_SUCCESS = 'BATCHJOBSETTINGS_UPDATE_SUCCESS';
export const DELETECMTDATA_SUCCESS = 'DELETECMTDATA_SUCCESS';
export const DELETECMTDATA_FAILURE = 'DELETECMTDATA_FAILURE';
export const MSAL_LOGIN = 'MSAL_LOGIN';
export const MSAL_LOGIN_SILENT = 'MSAL_LOGIN_SILENT';
export const MSAL_ACQUIRE_TOKEN = 'MSAL_ACQUIRE_TOKEN';
export const MSAL_TOKEN_ERROR = 'MSAL_TOKEN_ERROR';
export const MSAL_LOGIN_REDIRECT = 'MSAL_LOGIN_REDIRECT';
export const MSAL_LOGIN_SUCCESS = 'MSAL_LOGIN_SUCCESS';
export const MSAL_LOGIN_FAILURE = 'MSAL_LOGIN_FAILURE';
export const MSAL_LOGOUT = 'MSAL_LOGOUT';
export const MSAL_ERROR = 'MSAL_ERROR';
export const MOBILE_NUMBER_UPDATE_SUCCESS = 'MOBILE_NUMBER_UPDATE_SUCCESS';
export const MOBILE_NUMBER_UPDATE_FAILURE = 'MOBILE_NUMBER_UPDATE_FAILURE';
export const SEND_REPLACEMENT = 'SEND_REPLACEMENT';
export const LAST_KNOWN_LOCATION_SUCCESS = 'LAST_KNOWN_LOCATION_SUCCESS';
export const GENERATE_MAP_POPUPS = 'GENERATE_MAP_POPUPS';
export const UPDATE_SELECTED_VEHICLE = "UPDATE_SELECTED_VEHICLE";
export const SHIPMENT_STATUS_FOUND = "SHIPMENT_STATUS_FOUND";
export const OBD_REPLACEMENT_SUCCESS = "OBD_REPLACEMENT_SUCCESS";
export const SHIPMENT_STATUS_NOT_FOUND = "SHIPMENT_STATUS_NOT_FOUND";
export const OBD_REPLACEMENT_FAILURE = "OBD_REPLACEMENT_FAILURE";
export const CLEAR_ERROR = "CLEAR_ERROR";

export interface ActionWithPayload extends Action<any> {
    type: string;
    payload: any;
}

export type KnownAction = ActionWithPayload;