import '@gdk/accordion/dist/styles/gdk-accordion.css';
import React, { PureComponent, ReactElement } from 'react';
import { Vehicle } from '../../models/Vehicle';
import { GdkAccordion } from '@gdk/accordion';

interface CmtVehicleManagementProps {
    vehicles: Array<Vehicle>;
    headers: Array<string>;
}

interface CmtVehicleManagementState {
    vehicles: Vehicle[];
}

export default class CmtVehicleManagement extends PureComponent<CmtVehicleManagementProps, CmtVehicleManagementState> {
    constructor(props: CmtVehicleManagementProps) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles,
        };
    }

    formatDate = (date: string): string => {
        return new Date(date).toLocaleString().split(',', 1)[0];
    }

    renderAccordionContent = (vehicle: Vehicle): ReactElement => {
        return (
            <tr>
                <td data-title={vehicle.enrollmentEffectiveDate}>{vehicle.enrollmentEffectiveDate}</td>
                <td data-title={vehicle.tripInfo?.lastAppTripDate}>{vehicle.tripInfo?.lastAppTripDate == null ? 'N/A' : this.formatDate(vehicle.tripInfo?.lastAppTripDate)}</td>
                <td data-title={vehicle.tripInfo?.lastTagOnlyTripDate}>{vehicle.tripInfo?.lastTagOnlyTripDate == null ? 'N/A' : this.formatDate(vehicle.tripInfo?.lastTagOnlyTripDate)}</td>
                <td data-title={vehicle.tripInfo?.totalTagOnlyTrips}>{vehicle.tripInfo?.totalTagOnlyTrips == null ? '0' : vehicle.tripInfo?.totalTagOnlyTrips}</td>
                <td data-title={vehicle.tagLinkDate}>{(vehicle.tagLinkDate == '' || vehicle.tagLinkDate == null) ? 'N/A' : vehicle.tagLinkDate}</td>
                <td data-title={vehicle.tagMacAddress}>{(vehicle.tagMacAddress == '' || vehicle.tagMacAddress == null) ? 'N/A' : vehicle.tagMacAddress}</td>
            </tr>);
    }

    renderAccordion = (vehicle: Vehicle): ReactElement => {
        const { headers } = this.props;

        const ymm = `${vehicle.year.length === 5 ? vehicle.year.substring(1) : vehicle.year} ${vehicle.make} ${vehicle.model}`;
        const successOrAlert = vehicle.isEnrolled ? 'chips chips-success' : 'chips chips-alert';
        const enrolled = vehicle.isEnrolled ? 'Enrolled' : 'Not Enrolled';
        return (
            <ul id={"accordion" + vehicle.id} className="accordion">
                <li>
                    <div tabIndex={0} className="accordion-headline">
                        <div className="accordion-headline-content-wrapper">
                            <div className="accordion-left-content-wrapper">
                                <div className="heading h4">{ymm}</div>
                                <p><b>VIN: </b> {"****" + vehicle.vin?.slice(-6)}</p>
                                <div className={successOrAlert}>{enrolled}</div>
                            </div>
                            <div className="accordion-right-content-wrapper">
                                <span><b>Shipping status: </b> {vehicle.tagData?.shippingStatus}</span>
                                <p><b>Delivery date: </b> {vehicle.tagData?.deliveryDate == null ? '' : this.formatDate(vehicle.tagData?.deliveryDate)}</p>
                                <p><b>Shipping ID: </b> {vehicle.tagData?.amazonId}</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-content-container" >
                        <div className="accordion-content">
                            <div className='data-table'>
                                <table className="table">
                                    <thead>
                                        <tr className="management-table-header">
                                            {
                                                headers && headers.map((header) => (
                                                    <th key={header} className="header">
                                                        <a>{header}</a>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.renderAccordionContent(vehicle)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        );
    }

    componentDidMount = () => {
        const { vehicles } = this.state;

        vehicles.forEach((vehicle) => {
            var accordion = new GdkAccordion({
                content: '#accordion' + vehicle.id
            });
        });
    }

    render(): ReactElement {
        const { vehicles } = this.state;

        return (
            <div>
                {
                    vehicles ?
                        vehicles.map((vehicle) => (
                            this.renderAccordion(vehicle)
                        )) : null
                }
            </div>
        );
    }
}
